import React from "react";
import { Td, Tr } from "react-super-responsive-table";
import QuillModal from "../../../../QuillModal";
import { Button, ButtonGroup, Grid } from "@mui/material";
import BlueButton from "../../../../assets/forms/BlueButton";
import { Assignment as AssignmentIcon } from "@mui/icons-material";
import FileUploadModal from "../../../../FileUploadModal";
import useTranslations from "../../../../../Context/actions/useTranslations";
import { formatAmericanDate } from "../../../../../utils/driversPayload";

const DriverReviewed = ({
  task,
  formData,
  index,
  handleUpload,
  setFiles,
  isOpen,
  setIsOpen,
  setViewIssues,
  insuredInfo,
}) => {

  const { t, timezone } = useTranslations();

  return (
    <>
      <Tr key={"otherLossRun" + index}>
        <Td valign="top">
          <p className="my-1">
            {formData.underwritingTasks?.[task.description]?.completed
              ? t("plm.tasks.driver-reviewed.complete")
              : t("plm.tasks.driver-reviewed.incomplete")}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">
            {task.cabURL ? (
              <a
                className="text-blue"
                target="_blank"
                rel="noreferrer"
                href={task.cabURL + insuredInfo.dot}
              >
                {task.description}
              </a>
            ) : (
              <>{task.description}</>
            )}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">{formatAmericanDate(task.dueDate, timezone)}</p>
        </Td>
        <Td valign="top">
          {task.type === "note" && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <QuillModal
                        handleUpload={handleUpload}
                        isOpen={isOpen[task.description] || false}
                        setIsOpen={(state) =>
                          setIsOpen((prev) => ({
                            ...prev,
                            [task.description]: state,
                          }))
                        }
                        label={task.description}
                        setFile={(files) =>
                          setFiles((prev) => ({
                            ...prev,
                            [task.description]: files,
                          }))
                        }
                        taskLocation={
                          formData.underwritingTasks?.[task.description]
                            ?.note || ""
                        }
                        hideButtonLabel={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <BlueButton
                        text={t("plm.tasks.driver-reviewed.view-issues")}
                        onClickFunction={() => setViewIssues(true)}
                        icon={<AssignmentIcon />}
                        className={"w-full"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {formData?.underwritingTasks?.[task.description]?.completed && (
                  <Grid item xs={12} md={12}>
                    <ButtonGroup fullWidth disableElevation variant="contained">
                      <Button
                        style={{
                          backgroundColor: "green",
                          color: "white",
                          flexGrow: 1,
                          padding: "4px 8px",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("plm.tasks.driver-reviewed.complete")}
                      </Button>
                    </ButtonGroup>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default DriverReviewed;
