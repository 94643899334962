import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import ApiService from "../../../services/southern-api/apiService";
import useTranslations from "../../../Context/actions/useTranslations";

export default function CommodityValueInput({
  label,
  property,
  formData,
  setFormData,
  policyID,
  defaultValue,
  min,
  disabled,
}) {

  const { t } = useTranslations();

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();
  const apiService = new ApiService();
  
  const handleInputChange = (event) => {
    let value = Math.max(min, Number(event.target.value));
    const updatedValues = { ...formData.standardCommodityValues, [property]: value };

    if (Boolean(moduleValue)) {
      setFormData({
        ...formData,
        standardCommodityValues: updatedValues,
      })
      apiService.renewalProcessUpdatePolicyField({
        policyID,
        standardCommodityValues: updatedValues,
      })
    } else {
      updateDoc(doc(db, "policy-applications", policyID), {
        standardCommodityValues: updatedValues,
      });
    }
  };

  return (
    <>
      {formData?.standardCommodityPercentages?.[property] > 0 && (
        <div className="flex flex-col space-y-2">
          <label className="text-xs font-semibold">{label} {t("plm.commodities.custom.value")}:</label>
          <input
            name={label}
            type="number"
            className="p-2 border rounded focus:outline-none focus:border-blue-500"
            value={formData?.standardCommodityValues?.[property] || defaultValue}
            onChange={handleInputChange}
            min={min}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
}
