import humanDate from "./humanDate";

const calculateTotal = (
  rating,
  hasNonOwned,
  hasMCP65,
  adjustmentPercent,
  fleetCredit,
  isEndorsement,
  endoData,
  formData
) => {
  // Calculate the adjustments and premiums
  let nonOwnedAdjustment = hasNonOwned ? rating * 0.05 : 0;
  // TODO: MCP 65 adjustment was commented out for now, revisit later.
  let mcp65Adjustment = 0; // hasMCP65 ? rating * 0.05 : 0; commented out for now
  let originalPremium = rating + nonOwnedAdjustment + mcp65Adjustment;
  let underwriterAdjustments = originalPremium * adjustmentPercent;
  let adjustedPremium = originalPremium + underwriterAdjustments;
  let fleetCreditAmount = adjustedPremium * fleetCredit;
  let totalPremiumAfterFleetCredit = adjustedPremium - fleetCreditAmount;

  return totalPremiumAfterFleetCredit;
};

export default calculateTotal;
