import { numberWithCommas } from "../../../../../utils/helpers";

const priceToHandle = (price) => {
  if (price) {
    const amount = numberWithCommas(price.toFixed(2));
    if (amount === NaN || !amount || amount === "NaN") {
      return "Not calculated yet";
    }
    return `$${amount}`;
  } else {
    return "Not calculated yet";
  }
};

export default priceToHandle;
