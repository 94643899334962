import React, { useState } from "react";
import Popup from "../../../generals/Popup";
import {
  useMediaQuery,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { auth, db } from "../../../../firebase";
import { addDoc, collection } from "firebase/firestore";

export default function AddDriverTrainingModal({
  open,
  setOpen,
  onClose,
  driver,
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  // State for dropdown and toggle
  const [trainingProvider, setTrainingProvider] = useState("");
  const [courseComplete, setCourseComplete] = useState(false);
  const [completionDate, setCompletionDate] = useState("");
  // Example automatic field data
  const getDriverData = (driver) => {
    return {
      studentName: driver.driverFirst + " " + driver.driverLast,
      driversLicenseNumber: driver.licenseNumber,
      assignedByUser: auth.currentUser.uid,
      assignedbyUserName: auth.currentUser.displayName,
      assignedOnDate: new Date(),
    };
  };

  const driverData = getDriverData(driver);

  const resetForm = () => {
    setTrainingProvider("");
    setCourseComplete(false);
    setCompletionDate("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, "driverTraining"), {
        ...driverData,
        trainingProvider,
        courseComplete,
        completionDate,
        });
    console.log("Document written with ID: ", docRef.id);
    resetForm();
    onClose();
  }

  return (
    <div>
      <Popup
        isOpen={open}
        onRequestClose={onClose}
        maxWidth={isMobileScreen ? "100%" : "400px"}
        zIndex={1000}
        contentLabel={"Add Driver Training"}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Driver Training
          </Typography>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Training Provider</InputLabel>
            <Select
              value={trainingProvider}
              onChange={(e) => setTrainingProvider(e.target.value)}
              label="Training Provider"
            >
              <MenuItem value="JJ Keller">JJ Keller</MenuItem>
              <MenuItem value="Safethor">Safethor</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Course Category"
            placeholder="Enter course category"
            variant="outlined"
            required
          />

          <TextField
            label="Course Title"
            placeholder="Enter course title"
            variant="outlined"
            required
          />

          <FormControlLabel
            control={
              <Switch
                checked={courseComplete}
                onChange={(e) => setCourseComplete(e.target.checked)}
                color="primary"
              />
            }
            label="Course Complete"
          />

          {courseComplete && (
            <TextField
              label="Completion Date"
              type="date"
              value={completionDate}
              onChange={(e) => setCompletionDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              required
            />
          )}

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Popup>
    </div>
  );
}
