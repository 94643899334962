import React, { useState } from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import TableWithFormulas from "../../DriverScoring/Tables/TableWithFormulas";
import useLossControlFormulas from "../../utils/useLossControlFormulas";
import UploadExcelFile from "../UploadExcelFile";
import DriverFitness from "../FullViolationsList/DriverFitness";
import DriverProfile from "../FullViolationsList/DriverProfile";
import DataMatrix from "../../DriverScoring/Tables/DataMatrix";
import TableSelection from "../TableSelection";
import Tables from "../../DriverScoring/Tables";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchType from "../Search/SearchType";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Search from "../Search";
import Crashes from "../Crashes";
import fetchViolations from "../utils/fetchViolations";
import Inspections from "../Inspections";
import EditSelection from "../EditSelection";

export default function DriverScoring({ userInfo }) {
  const [crashes, setCrashes] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [test, setTest] = useState(false);

  const [driverModalIsOpen, setDriverModalIsOpen] = useState(false);

  const openDriverModal = () => {
    setDriverModalIsOpen(true);
  };

  const closeDriverModal = () => {
    setDriverModalIsOpen(false);
  };

  // // Aggregate information for each driver
  const [aggregatedDriverInfo, setAggregatedDriverInfo] = useState([]);

  const { driverProfileScalingSwitch, driverProfileScalingSortWay } =
    useLossControlFormulas();
  const [selectedOption, setSelectedOption] = useState("fitness");
  const [editSelection, setEditSelection] = useState("Search");
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("");
  const [selectedInsured, setSelectedInsured] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const hasCrashesOrInspections =
    aggregatedDriverInfo[0]?.crashes?.length > 0 ||
    aggregatedDriverInfo[0]?.inspections?.length > 0;
  const hasCrashes = aggregatedDriverInfo[0]?.crashes?.length > 0;
  const hasInspections = aggregatedDriverInfo[0]?.inspections?.length > 0;

  return (
    <div>
      <>
        {test && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={null}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h6">Please Wait...</Typography>
          </Backdrop>
        )}
        <>
          <p className="mb-4 text-3xl font-bold">Driver Scoring</p>
          <EditSelection
            disabledCrashes={!hasCrashes}
            disabledInspections={!hasInspections}
            searchType={searchType}
            editSelection={editSelection}
            setEditSelection={setEditSelection}
            disabled={!(aggregatedDriverInfo.length > 0)}
          />
          {editSelection === "Search" && (
            <>
              <p className="my-4">Please select a search type to begin.</p>
              <SearchType
                selectedOption={searchType}
                setSelectedOption={setSearchType}
              />
              {searchType !== "" && (
                <>
                  <form
                    onSubmit={(e) =>
                      fetchViolations(
                        e,
                        searchType,
                        searchValue,
                        selectedInsured,
                        selectedPolicy,
                        setAggregatedDriverInfo,
                        setDataReady,
                        setCrashes,
                        setInspections,
                        setLoading
                      )
                    }
                  >
                    <Search
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      searchType={searchType}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      selectedInsured={selectedInsured}
                      setSelectedInsured={setSelectedInsured}
                      fetchViolations={fetchViolations}
                      selectedPolicy={selectedPolicy}
                      setSelectedPolicy={setSelectedPolicy}
                      loading={loading}
                    />
                    <ApprovalButtons
                      onApprove={null}
                      handleAsSubmit={true}
                      onDecline={(e) => {
                        setSearchType("");
                        setSearchValue("");
                        setSelectedOption("");
                        setAggregatedDriverInfo([]);
                      }}
                      titleAccept="Search"
                      titleDecline="Cancel"
                      disabledAccept={false}
                    />
                  </form>
                  {aggregatedDriverInfo.length > 0 ||
                  (searchType === "license" && hasCrashesOrInspections) ? (
                    <>
                      <p className="my-4">
                        Results found. Select a table below to view the results.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="my-4">
                        No results found. Start a new search.
                      </p>
                    </>
                  )}
                </>
              )}
              {(aggregatedDriverInfo.length > 0 ||
                (searchType === "license" && hasCrashesOrInspections)) &&
                !loading && (
                  <TableSelection
                    disabledCrashes={!hasCrashes}
                    disabledInspections={!hasInspections}
                    searchType={searchType}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    disabled={!(aggregatedDriverInfo.length > 0)}
                  />
                )}
              {/* TODO: Fix this upload excel file to new fixed data or a way for alex to add violations */}
              {/* {selectedOption === "upload" && <UploadExcelFile />} */}
              {searchType === "license" &&
                hasCrashesOrInspections &&
                selectedOption === "crashes" &&
                !loading && (
                  <Crashes crashes={aggregatedDriverInfo[0].crashes} />
                )}
              {searchType === "license" &&
                hasCrashesOrInspections &&
                selectedOption === "inspections" &&
                !loading && (
                  <Inspections
                    inspections={aggregatedDriverInfo[0].inspections}
                  />
                )}
              {aggregatedDriverInfo.length > 0 &&
                selectedOption === "fitness" &&
                !loading && (
                  <div className="my-4">
                    <DriverFitness
                      userInfo={userInfo}
                      setDriverModalIsOpen={setDriverModalIsOpen}
                      openDriverModal={openDriverModal}
                      driverModalIsOpen={driverModalIsOpen}
                      closeDriverModal={closeDriverModal}
                      dataReady={dataReady}
                      aggregatedDriverInfo={aggregatedDriverInfo}
                    />
                  </div>
                )}
              {aggregatedDriverInfo.length > 0 &&
                selectedOption === "profile" &&
                !loading && (
                  <div className="my-4">
                    <DriverProfile
                      userInfo={userInfo}
                      setDriverModalIsOpen={setDriverModalIsOpen}
                      openDriverModal={openDriverModal}
                      driverModalIsOpen={driverModalIsOpen}
                      closeDriverModal={closeDriverModal}
                      dataReady={dataReady}
                      aggregatedDriverInfo={aggregatedDriverInfo}
                    />
                  </div>
                )}
            </>
          )}
          {editSelection === "scaling" && (
            <TableWithFormulas
              mainCollection="tables"
              tableName="driverProfileScaling"
              getDisplayValue={driverProfileScalingSwitch}
              customOrder={driverProfileScalingSortWay}
            />
          )}
          {}
          {/* Need to redo full violations list according to new data
           */}
          {/* <FullViolationsList /> */}

          {editSelection === "matrices" && (
            <>
              <DataMatrix tableName={"frequencyMatrix"} />
              <DataMatrix tableName={"severityMatrix"} />
              <DataMatrix tableName={"combinedMatrix"} />
            </>
          )}
          {editSelection === "tables" && <Tables />}
        </>
        {showButton && (
          <Fab
            color="primary"
            size="large"
            aria-label="scroll back to top"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            onClick={scrollToTop}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        )}
      </>
    </div>
  );
}
