import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import useTranslations from "../../../../../Context/actions/useTranslations";

export default function Body({
  editMode,
  editedContent,
  editedSubject,
  setEditedContent,
  setEditedSubject,
  handleExpand,
  expandedNotes,
  note,
  setExpandedNotes,
}) {

  const { t } = useTranslations()

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        style={{
          padding: "12px",
          marginBottom: "2px",
        }}
      >
        <Typography
          variant="body1"
          color="text.primary"
          style={{ marginBottom: "10px" }}
        >
          {editMode[note.id] ? (
            <input
              type="text"
              className="w-full p-2 border"
              value={editedSubject[note.id] || note.subject}
              onChange={(e) =>
                setEditedSubject((prevSubject) => ({
                  ...prevSubject,
                  [note.id]: e.target.value,
                }))
              }
            />
          ) : (
            <p className="text-lg"> {note.subject}</p>
          )}
        </Typography>
        {editMode[note.id] ? (
          <ReactQuill
            theme="snow"
            value={editedContent[note.id] || note.note}
            onChange={(value) =>
              setEditedContent((prevContent) => ({
                ...prevContent,
                [note.id]: value,
              }))
            }
          />
        ) : (
          <>
            {note.note?.length > 0 && (
              <Button onClick={() => handleExpand(note.id, setExpandedNotes)}>
                {expandedNotes[note.id] ? t('plm.notes.show-less') : t('plm.notes.show-more')}
              </Button>
            )}
            <Collapse in={expandedNotes[note.id]} timeout="auto" unmountOnExit>
              <Typography
                variant="body1"
                color="text.primary"
                dangerouslySetInnerHTML={{
                  __html: note.note,
                }}
              />
            </Collapse>
          </>
        )}
      </Paper>
    </Grid>
  );
}
