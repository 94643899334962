import React from "react";
import Checkbox from "./Checkbox";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { numberWithCommas } from "../../../utils/helpers";
import { Button } from "@mui/material";
import useTranslations from "../../../Context/actions/useTranslations";

export default function AdditionalCoverage({
  formData,
  setFormData,
  dropdownOptions,
  policyID,
  disabledRule,
  isAdminOrUnderwriter,
  handleOpenModal,
}) {

  const { t } = useTranslations()

  return (
    <section>
      <h1 className="text-lg mt-3">{t("plm.mtc.coverage.title")}</h1>
      <article>
        <Checkbox
          label={t("plm.mtc.coverage.excess")}
          field={"hasXSMTC"}
          policyID={policyID}
          setFormData={setFormData}
          formData={formData}
          disabled={formData.documentType !== "Application"}
        />
      </article>
      <article>
        <Checkbox
          label={t("plm.mtc.coverage.reefer-breakdown")}
          field={"hasReefer"}
          policyID={policyID}
          setFormData={setFormData}
          formData={formData}
          disabled={formData.documentType !== "Application"}
        />
        {formData?.mtcDetails?.hasReefer && (
          <>
            <label
              htmlFor={`reeferDeductible-select`}
              className="block text-sm font-medium text-gray-700"
            >
              {t("plm.mtc.coverage.reefer-deductible")}
            </label>
            <select
              disabled={disabledRule}
              id={`reeferDeductible-select`}
              onChange={async (e) => {
                const mtcDetails = {
                  ...(formData.mtcDetails || {}),
                  reeferDeductible: e.target.value,
                };
                const policyRef = doc(db, "policy-applications", policyID);
                await updateDoc(policyRef, {
                  mtcDetails,
                });
              }}
              value={formData?.mtcDetails?.reeferDeductible}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
            >
              <option value="">{t("plm.mtc.coverage.choose-deductible")}</option>
              {dropdownOptions?.reeferDeductibles
                ?.slice()
                .sort((a, b) => a - b)
                .map((limit) => (
                  <option key={limit} value={limit}>
                    ${numberWithCommas(limit)}
                  </option>
                ))}
            </select>
            {isAdminOrUnderwriter && (
              <Button
                sx={{ fontSize: "10px" }}
                onClick={() =>
                  handleOpenModal("reeferDeductibles", "mtcDropdowns")
                }
              >
                {t("plm.mtc.coverage.edit-options")}
              </Button>
            )}
          </>
        )}
      </article>
    </section>
  );
}
