const getDailyPremium = (
  endoData,
  totalPremiumAfterFleetCredit,
  leapDays,
  truck,
  effectiveDate,
  addedDate,
  endoEffectiveDate
) => {
  console.log("truck: ", truck);
  console.log("effective date: ", effectiveDate);
    console.log("added date: ", addedDate);
    console.log("endo effective date: ", endoEffectiveDate);
    console.log("total premium after fleet credit: ", totalPremiumAfterFleetCredit);
    console.log("leap days: ", leapDays);

  let isAddPowerUnitsEndorsement = false;
  let isRemovePowerUnitsEndorsement = false;
  if (endoData.type === "Add Power Units") {
    console.log('is add power units endorsement');
    isAddPowerUnitsEndorsement = true;
  } else if (endoData.type === "Remove Power Units") {
    isRemovePowerUnitsEndorsement = true;
  }
  function resetTimeToMidnight(date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }
  const resetAddedDate = resetTimeToMidnight(addedDate);
  const resetEndoEffectiveDate = resetTimeToMidnight(endoEffectiveDate);
  const resetEffectiveDate = resetTimeToMidnight(effectiveDate);
  const daysBetweenEndoEffectiveAndAdded = Math.floor(
    (resetEndoEffectiveDate - resetAddedDate) / (1000 * 60 * 60 * 24)
  );
  let dailyPremium;
  if (isAddPowerUnitsEndorsement) {
    // If its an add power unit endo, prorate based on 365 days
    const rawDailyPremium = totalPremiumAfterFleetCredit / (365 + leapDays);
    console.log('raw daily premium: ', rawDailyPremium);
    dailyPremium = parseFloat(rawDailyPremium.toFixed(2));
    return dailyPremium;
  } else if (isRemovePowerUnitsEndorsement) {
    // If its a remove power unit endo, you must deduct the number of
    // days the truck was not on the policy from the 365.
    console.log(
      "days between effective and added: ",
      daysBetweenEndoEffectiveAndAdded
    );
    const rawDailyPremium =
      totalPremiumAfterFleetCredit /
      (365 - daysBetweenEndoEffectiveAndAdded + leapDays);
    dailyPremium = parseFloat(rawDailyPremium.toFixed(2));
    return dailyPremium;
  }
};

export default getDailyPremium;
