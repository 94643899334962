const getLeapDays = (effectiveDate, endoEffectiveDate) => {
    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      };
    let leapDays = 0;
    for (
      let year = effectiveDate.getFullYear();
      year <= endoEffectiveDate.getFullYear();
      year++
    ) {
      if (isLeapYear(year)) {
        const leapDay = new Date(year, 1, 29); // February 29
        if (leapDay >= effectiveDate && leapDay <= endoEffectiveDate) {
          leapDays++;
        }
      }
    }
    return leapDays;
}

export default getLeapDays;