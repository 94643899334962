import React from "react";
import { Tbody, Td, Thead, Tr, Table } from "react-super-responsive-table";
import { Col, Row } from "react-bootstrap";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../../firebase";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import QuoteSelector from "./QuoteSelector";
import RedButton from "../../../assets/forms/RedButton";
import { useMediaQuery } from "@mui/material";
import BlueButton from "../../../assets/forms/BlueButton";
import AgentColumns from "../QuotesTable/AgentColumns";
import UnderwriterColumns from "../QuotesTable/UnderwriterColumns";
import AgentHeaders from "../QuotesTable/AgentHeaders";
import UnderwriterHeaders from "../QuotesTable/UnderwriterHeaders";
import { confirmDeletePromptGlobal } from "../../../../layout/ConfirmDeleteProvider";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function QuotesTable({
  formData,
  policyID,
  setFormData,
  userInfo,
  getQuoteHistory,
  quoteHistory,
  openModal,
  clearQuoteList,
  applyAdjustments,
  createNewQuote,  
  taxSchedule,
  adjustmentFormData,
  setAdjustmentFormData,
  truckListData,
  setShowQuoteSuccess,
  setLoading,
  insuredInfo
}) {

  const { t } = useTranslations()

  const deleteThisQuote = async (thisID) => {
    // TODO: Make function delete file too
    let verify = await confirmDeletePromptGlobal(t("plm.quotes.quotes-table.about-to-delete"));
    if (verify === "DELETE") {
      await deleteDoc(
        doc(db, "policy-applications", policyID, "quotes", thisID)
      );
      getQuoteHistory();
    }
  };
  const role = userInfo.role;
  const userIsUnderwriter = role === "Underwriter" || role === "Admin";
  const selectedQuote = formData.selectedQuote;
  const isQuoteRequest = formData.documentType === "Quote Request";
  const userIsAgent = role === "Agent";

  return (
    <Row className="mb-3">
      <Col sm={12}>
        {/* Quote List */}
        <article className="mt-2">
          <section>
            {/* TODO: Refactor table to fit new standards */}
            <Table>
              <Thead>
                <Tr>
                  <Td className="bg-[#072a48] text-white w-[10px] rounded-tl-md">
                    {t("plm.quotes.quotes-table.apply")}
                  </Td>
                  <Td className="bg-[#072a48] min-w-[200px] text-white">
                    {t("plm.quotes.quotes-table.description")}
                  </Td>
                  {userIsAgent && <AgentHeaders />}
                  {userIsUnderwriter && <UnderwriterHeaders />}
                  <Td className="bg-[#072a48] text-white rounded-tr-md"></Td>
                </Tr>
              </Thead>
              <Tbody>
                {quoteHistory.length === 0 && (
                  <Tr>
                    <Td colspan={7} className="text-center">
                      {userIsUnderwriter ? (
                        <p>
                          {t("plm.quotes.quotes-table.no-existing-underwriter")}
                        </p>
                      ) : (
                        <p>
                          {t("plm.quotes.quotes-table.no-existing-normal")}
                        </p>
                      )}
                    </Td>
                  </Tr>
                )}
                {quoteHistory.map((quote) => {
                  return (
                    <Tr key={quote.id}>
                      <Td
                        className={`${
                          selectedQuote === quote.id && "bg-green-200"
                        }`}
                      >
                        <QuoteSelector
                          quoteID={quote.id}
                          quoteData={quote}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          applyAdjustments={applyAdjustments}
                        />
                      </Td>
                      <Td
                        className={`${
                          selectedQuote === quote.id && "bg-green-200"
                        }`}
                      >
                        <button
                          onClick={(e) => {
                            console.log('Adjustment Form Data in QuotesTable: ', quote.adjustmentFormData);

                            createNewQuote(e, true, quote.adjustmentFormData)
                          }}
                          target="_blank"
                          rel="noreferrer"
                          className="hover:text-sky-900 text-[#072a48]"
                        >
                          {quote?.adjustmentFormData?.description}
                        </button>
                      </Td>
                      {userIsAgent && (
                        <AgentColumns
                          quote={quote}
                          selectedQuote={selectedQuote}
                        />
                      )}
                      {userIsUnderwriter && (
                        <UnderwriterColumns
                          quote={quote}
                          selectedQuote={selectedQuote}
                        />
                      )}
                      <Td
                        className={`${
                          selectedQuote === quote.id && "bg-green-200"
                        }`}
                      >
                        <button
                          title={t("plm.quotes.quotes-table.print")}
                          onClick={(e) => {
                            createNewQuote(
                              e,
                              true,
                              quote.adjustmentFormData
                            )
                          }}
                          className="d-inline mx-1 text-[#072a48]"
                        >
                          <PrintIcon fontSize={"large"} />
                        </button>

                        {isQuoteRequest && userIsUnderwriter && (
                          <>
                            <button
                              title={t("plm.quotes.quotes-table.delete")}
                              onClick={(e) => deleteThisQuote(quote.id)}
                              className="d-inline mx-1 text-[#8B0000]"
                            >
                              <DeleteIcon fontSize={"large"} />
                            </button>
                          </>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </section>
        </article>
      </Col>
    </Row>
  );
}
