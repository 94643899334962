import React from "react";
import { Td } from "react-super-responsive-table";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function UnderwriterHeaders() {

  const { t } = useTranslations();

  return (
    <>
      <Td className="bg-[#072a48] text-white text-center">
        {t("plm.quotes.quotes-table.manual-adj")}
      </Td>
      <Td className="bg-[#072a48] text-white text-center">
        {t("plm.quotes.quotes-table.driver-adj")}
      </Td>
      <Td className="bg-[#072a48] text-white text-center">
        {t("plm.quotes.quotes-table.loss-ratio-adj")}
      </Td>
      <Td className="bg-[#072a48] text-white text-center">
        {t("plm.quotes.quotes-table.safety-adj")}
      </Td>
    </>
  );
}
