import React from "react";
import { handleDynamicQuestionnaireChange } from "../../../utils/helpers";
import useTranslations from "../../../Context/actions/useTranslations";

export default function AddLocations(props) {
  const { formData, setFormData, setFormSaved, policyID } = props;

  const { t } = useTranslations()

  const handleAddLocation = () => {
    setFormData({
      ...formData,
      questionnaire: {
        ...formData?.questionnaire,
        insuredInfo: {
          ...formData?.questionnaire?.insuredInfo,
          locationFields: [
            ...(Array.isArray(formData?.questionnaire?.insuredInfo?.locationFields)
              ? formData?.questionnaire?.insuredInfo?.locationFields
              : []),
            {
              address: "",
              city: "",
              state: "",
              zip: "",
              numberOfEmployees: 0,
            },
          ],
        }
      }
    });
  };

  const handleSubtractLocation = async (e, i) => {
    e.preventDefault();
    setFormSaved(false);
    const values = [...formData?.questionnaire?.insuredInfo?.locationFields];
    values.splice(i, 1);
    let fieldsPayload = values;
      if (values.length === 0) {
        fieldsPayload = [
          {
            address: "",
            city: "",
            state: "",
            zip: "",
            numberOfEmployees: 0,
          },
        ];
      }
      await handleDynamicQuestionnaireChange(
        policyID,
        "questionnaire.insuredInfo.locationFields",
        fieldsPayload,
        formData,
        setFormData,
        setFormSaved
      );
  };

  const handleChange = async (index, fieldName, value) => {
    let updatedFields = [];
    if (formData?.questionnaire?.insuredInfo?.locationFields) {
      updatedFields = [...formData?.questionnaire?.insuredInfo?.locationFields];
    }
    updatedFields[index] = {
      ...updatedFields[index],
      [fieldName]: value,
    };
    await handleDynamicQuestionnaireChange(
      policyID,
      "questionnaire.insuredInfo.locationFields",
      updatedFields,
      formData,
      setFormData,
      setFormSaved
    );
  };

  return (
    <div>
      {(formData?.questionnaire?.insuredInfo?.locationFields ?? []).map((field, index) => (
        <div key={`location${index}`} className="my-3">
          <h2 className="text-[#072a48] font-semibold mb-2">
            {t("plm.questionnaire.general-information.item1")} {index + 1}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.general-information.item2")}:
              </label>
              <input
                name="address"
                
                onChange={(e) =>
                  handleChange(index, "address", e.target.value)
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="text"
                value={field.address}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">{t("plm.questionnaire.general-information.item3")}:</label>
              <input
                name="city"
                
                onChange={(e) =>
                  handleChange(index, "city", e.target.value)
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="text"
                value={field.city}
              />
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.general-information.item4")}:
              </label>
              <input
                name="state"
                
                onChange={(e) =>
                  handleChange(index, "state", e.target.value)
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="text"
                value={field.state}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">{t("plm.questionnaire.general-information.item5")}:</label>
              <input
                name="zip"
                
                onChange={(e) =>
                  handleChange(index, "zip", e.target.value)
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="text"
                value={field.zip}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.general-information.item6")}:
              </label>
              <input
                name="numberOfEmployees"
                
                onChange={(e) =>
                  handleChange(
                    index,
                    "numberOfEmployees",
                    parseInt(e.target.value) || 0
                  )
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="number"
                value={field.numberOfEmployees}
              />
            </div>
          </div>
          {formData.documentType === "Application" && (
            <button
              className="mt-2 text-[#8B0000] hover:text-red-700"
              onClick={(e) => handleSubtractLocation(e, index)}
            >
              {t("plm.questionnaire.general-information.item7")} {index + 1}
            </button>
          )}
        </div>
      ))}
      {formData.documentType === "Application" && (
        <button
          className="text-blue-500 hover:text-blue-700 mb-3"
          onClick={handleAddLocation}
        >
          {t("plm.questionnaire.general-information.item8")}
        </button>
      )}
    </div>
  );
}