import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import PowerUnitDetailFlags from "../../../PolicyLifecycleManager/RedFlags/PowerUnitDetailFlags";
import priceToHandle from "../utils/addPowerUnit/priceToHandle";

export default function PowerUnitsMap({ paramPolicyID, endorsementData }) {
  return (
    <div>
      {endorsementData &&
        endorsementData.map((truck, truckIndex) => {
          const policyDetailToShow = truck?.policiesDetails.find(
            (detail) => detail.id === paramPolicyID
          );
          return (
            <div
              key={truckIndex}
              className="mx-auto p-6 bg-white shadow-md rounded-lg mb-2"
            >
              <Container className="p-0">
                <Row>
                  <Col sm={12} md={6}>
                    <PowerUnitDetailFlags
                      powerUnit={truck}
                      policyID={paramPolicyID}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">VIN:</span> {truck.truckVIN}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Weight:</span>{" "}
                    {truck.truckWeight}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Year:</span>{" "}
                    {truck.truckYear}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Make:</span>{" "}
                    {policyDetailToShow?.truckMake}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Model:</span>{" "}
                    {policyDetailToShow?.truckModel}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Class:</span>{" "}
                    {policyDetailToShow?.truckClass}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Rating:</span>{" "}
                    {priceToHandle(policyDetailToShow?.adjustedRate)}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">SL Tax:</span>{" "}
                    {priceToHandle(policyDetailToShow?.taxes?.surplusLinesTax)}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Stamp Fee:</span>{" "}
                    {priceToHandle(policyDetailToShow?.taxes?.stampFee)}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Processing Fee:</span>{" "}
                    {priceToHandle(policyDetailToShow?.taxes?.processingFee)}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Total:</span>{" "}
                    {priceToHandle(
                      policyDetailToShow?.taxes?.processingFee +
                        policyDetailToShow?.taxes?.stampFee +
                        policyDetailToShow?.taxes?.surplusLinesTax +
                        policyDetailToShow?.adjustedRate
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          );
        })}
    </div>
  );
}
