import {
  addDoc,
  collection,
  doc,
  getDoc,
  runTransaction,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../../../../firebase";
import { formatDateToYYYYMMDD } from "../../../../Finances/Tables/utils";
import endoStatusChangeNotification from "../../../../utils/notifications/endoStatusChangeNotification";
import updateSystemLog from "../../../../utils/updateSystemLog";
import calculatePremiums from "../../../../utils/calculatePremiums";

const approveAUEndorsement = async (
  e,
  requestExceptionData,
  endorsement,
  paramPolicyID,
  paramRefID,
  details,
  subcollection,
  subcollectionName,
  identifier,
  deletionStatus,
  insuredInfo,
  setSuccess,
  apiService,
  data,
  policyID,
  endoID,
  setOpenInCancellationModal
) => {
  e.preventDefault();

  const policyRef = doc(db, "policy-applications", paramPolicyID);
  const policyDoc = await getDoc(policyRef);
  const policyData = {
    ...policyDoc.data(),
    id: policyDoc.id,
  };

  const { agencyID, insuredID } = endorsement;
  try {
    const endorsementRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await runTransaction(db, async (transaction) => {
      await transaction.get(endorsementRef);
      const issuedDate = formatDateToYYYYMMDD(new Date());
      const payload = {
        documentType: "Endorsement",
        status: "Approved",
        wasARequestException: false,
        issuedDate,
      };
      if (requestExceptionData) {
        payload["wasARequestException"] = true;
        payload["requestException"] = requestExceptionData;
      }
      await transaction.update(endorsementRef, payload);
      endoStatusChangeNotification(
        endorsement.type,
        endorsement.endorsementNumber,
        "Approved",
        paramRefID,
        paramPolicyID,
        endorsement.agencyID
      );
      const promises = details.map(async (item) => {
        const subCollectionRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          subcollection,
          item.id
        );
        const dataToUpdate = {};
        if (
          typeof endorsement?.deletionReason !== "undefined" &&
          typeof endorsement?.deletionReason === "string"
        ) {
          dataToUpdate["deletionReason"] = endorsement.deletionReason;
        }
        if (
          typeof endorsement?.proofOfPowerDeletion !== "undefined" &&
          Array.isArray(endorsement?.proofOfPowerDeletion)
        ) {
          dataToUpdate["proofOfPowerDeletion"] =
            endorsement.proofOfPowerDeletion;
        }

        const renewalApplicationID = policyData?.renewalApplicationID;

        const policiesDetails = item.policiesDetails.map((policyDetail) => {
          if (
            policyDetail.id === paramPolicyID ||
            policyDetail.id === renewalApplicationID
          ) {
            const resultingPolicyDetail = {
              ...policyDetail,
              status: deletionStatus,
              removedDate: endorsement.endoEffectiveDate,
            };

            if (subcollection === "power-units") {
              resultingPolicyDetail["adjustedRate"] = 0;
              resultingPolicyDetail["fleetCredit"] = 0;
              resultingPolicyDetail["rating"] = 0;
            }
            return resultingPolicyDetail;
          }
          return policyDetail;
        });

        const payloadResult = {
          ...item,
          policiesDetails,
        };

        delete payloadResult["iconLeft"];
        delete payloadResult["iconRight"];
        delete payloadResult["tableInfo"];

        if (typeof endorsement?.blacklistExpirationDate !== "undefined") {
          payloadResult["blacklistExpirationDate"] =
            endorsement.blacklistExpirationDate;
        }

        await setDoc(subCollectionRef, payloadResult, { merge: true });

        updateSystemLog(
          auth,
          paramPolicyID,
          `Remove ${subcollectionName} Endorsement (` +
            payloadResult?.[`${identifier}`] +
            ") Approved For " +
            insuredInfo.company +
            " by " +
            auth.currentUser.displayName,
          `Remove ${subcollectionName} Endo Approved`,
          payloadResult,
          true
        );

        if (renewalApplicationID) {
          updateSystemLog(
            auth,
            renewalApplicationID`Remove ${subcollectionName} Endorsement (` +
              payloadResult?.[`${identifier}`] +
              ") Approved For " +
              insuredInfo.company +
              " by " +
              auth.currentUser.displayName,
            `Remove ${subcollectionName} Endo Approved`,
            payloadResult,
            true
          );
        }

        if (deletionStatus === "Excluded") {
          await apiService.createCancelCancellationProcessThroughExclusionDeclination(
            {
              policyID,
              endoID,
            }
          );
        }

        const dataItem = data.find((info) => info.id === item.id);
        if (deletionStatus === "Excluded") {
          await addDoc(collection(db, "drivers-blacklist"), {
            ...dataItem,
            blacklistedDate: new Date(),
          });
        }
      });

      const policyStatus = policyData.status;
      if (policyStatus === "In-Cancellation") {
        setOpenInCancellationModal(true);
        return;
      }

      await Promise.all(promises);
      
      if (subcollection === "power-units") {
        await calculatePremiums(
          policyData.id,
          policyData,
          e,
          "#",
          policyData?.alPremiums?.adjustment,
          true,
          true,
          paramRefID
        );
      }

    });

    setSuccess(true);
  } catch (error) {
    console.error("Error approving endorsement:", error);
    throw new Error("Failed to approve endorsement");
  }
};

export default approveAUEndorsement;
