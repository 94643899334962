import React from "react";
import StandardCommodityName from "./StandardCommodityName";
import CommodityValueSlider from "../CommodityValueSlider";
import CommodityDetails from "./CommodityDetails";
import useTranslations from "../../../../Context/actions/useTranslations";

const StandardCommodity = ({
  label,
  property,
  formData,
  setFormData,
  policyID,
  hasDetails,
  defaultValue,
  min,
  max,
  marks,
  disabledSlider,
}) => {

  const { t } = useTranslations()

  return (
    <div className="flex flex-col space-y-2">
      <StandardCommodityName
        label={label}
        property={property}
        formData={formData}
        setFormData={setFormData}
        disabledSlider={disabledSlider}
        defaultValue={defaultValue}
        policyID={policyID}
      />
      {hasDetails && (
        <CommodityDetails
          parentValue={property}
          formData={formData}
          setFormData={setFormData}
          policyID={policyID}
          field={property + "Explanation"}
          conditional={formData.standardCommodityPercentages?.[property] > 0}
          label={`${t("plm.commodities.custom.specific")} ${label} ${t("plm.commodities.custom.hauled")}`}
        />
      )}
      <CommodityValueSlider
        defaultValue={defaultValue}
        min={min}
        max={max}
        disabled={disabledSlider}
        label={label}
        property={property}
        formData={formData}
        setFormData={setFormData}
        policyID={policyID}
      />
    </div>
  );
};


export default StandardCommodity;
