import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import submitAddTruckEndorsement from "./utils/submitAddTruckEndorsement";
import getTruckData from "../../utils/validation/validateTruck/getTruckData";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import {
  validateIfExists,
  validateIfVinExistsInAnotherPolicy,
} from "../../utils/validateIfBlacklisted";
import MultiUpload from "../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import ApiService from "../../../services/southern-api/apiService";
import { setTimeToNoonOne } from "../../../utils/helpers";
import useTranslations from "../../../Context/actions/useTranslations";

export default function AddTruck(props) {
  const formData = props.formData;
  const insuredInfo = props.insuredInfo;
  const isEndoManager = props.isEndoManager;
  const policyID = props.policyID;
  const truckList = props.truckList;
  const apiService = new ApiService("southern");
  const [truckData, setTruckData] = useState({});

  const { t } = useTranslations();

  const goBack = (e) => {
    e.preventDefault();
    props.setShowAddTruck(false);
    props.setShowTable(true);
  };

  const [endoEffectiveDate, setEndoEffectiveDate] = useState(new Date());
  const [success, setSuccess] = useState(false);
  const [vinDecoded, setVinDecoded] = useState(false);
  const [vinWrongDecoded, setVinWrongDecoded] = useState(false);
  const [truckDocuments, setTruckDocuments] = useState([]);
  const { controlToast } = useGeneralState();

  const validatePowerUnit = async (truckVIN) => {
    if (truckVIN.length !== 17) {
      controlToast(true, t("plm.units.add.alert-2"), "error");
      return false;
    }
    const truckFound = truckList.find((truck) => {
      return truck.truckVIN === truckVIN;
    });
    if (truckFound?.id) {
      controlToast(true, t("plm.units.add.alert-3"), "error");
      return false;
    }
    return true;
  };

  const handleDecodeVIN = async (e, VIN, year) => {
    e.preventDefault();
    const unitIsValid = await validatePowerUnit(VIN);
    if (!unitIsValid) {
      return;
    } else {
      const results = await getTruckData(VIN, year);
      const resultData = results
        ? results
        : {
            ...truckData,
            truckVIN: VIN,
          };
      if (typeof results === "undefined") {
        setVinWrongDecoded(true);
        controlToast(true, t("plm.units.add.alert-4"), "error");
      }
      setTruckData(resultData);
      setVinDecoded(true);
    }
  };

  const breadcrumbs = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t("plm.units.add.breadcrumb.item1") },
    { label: t("plm.units.add.breadcrumb.item2") },
  ];

  const mirrorLogic = async (e) => {
    e.preventDefault();
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" && formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";

    const { agencyID } = formData;

    try {
      if (notARenewalProcess) {
        await submitAddTruckEndorsement(
          e,
          policyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          formData.documentType,
          false,
          truckList
        );
        const isPolicy = formData.documentType === "Policy";
        const message = isPolicy
          ? t("plm.units.add.alert-5")
          : t("plm.units.add.alert-6");
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        await submitAddTruckEndorsement(
          e,
          policyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Application",
          true,
          truckList
        );
        await submitAddTruckEndorsement(
          e,
          formData.renewalPolicyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Policy",
          true,
          truckList
        );
        const message = t("plm.units.add.alert-5");
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        await submitAddTruckEndorsement(
          e,
          formData.renewalApplicationID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Application",
          true,
          truckList
        );
        await submitAddTruckEndorsement(
          e,
          policyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Policy",
          true,
          truckList
        );
        const message = t("plm.units.add.alert-5");
        controlToast(true, message, "success");
      }

      const truckDataPayload = truckData?.[0];
      const existingActiveVinsInDifferentPolicies =
        await validateIfVinExistsInAnotherPolicy(
          truckDataPayload?.truckVIN,
          "power-units",
          "truckVIN",
          policyID
        );

      if (existingActiveVinsInDifferentPolicies?.length > 0) {
        await apiService.sendEmailPoliciesDetails({
          vin: truckDataPayload?.truckVIN,
          policiesDetails: existingActiveVinsInDifferentPolicies,
          agencyID,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setTruckData({
      ...truckData,
      truckDocuments: truckDocuments,
    });
  }, [truckDocuments]);

  return (
    <main>
      <header>
        {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
        <h1
          className={`${
            isEndoManager
              ? "text-2xl mt-2.5 font-bold md:mb-0"
              : "text-3xl mt-2 font-semibold md:mb-0"
          }`}
        >
          {!isEndoManager ? `${t("Add Power Unit")}` : `${t("Add Power Unit")}`}
        </h1>
        <p className="mt-2 mb-3">
          {(formData.documentType === "Application" ||
            formData.documentType === "Policy") &&
            t(
              "Fill out the following form to Add Unit. Click submit once the form is complete."
            )}
          {formData.documentType !== "Application" &&
            formData.documentType !== "Policy" && (
              <>Units may not be edited during the quoting phase.</>
            )}
        </p>
      </header>
      {/* Form */}
      <section className="card mx-auto max-w-[750px] mb-4 shadow-lg">
        <div className="card-body">
          <form onSubmit={mirrorLogic}>
            {formData.documentType === "Policy" ||
            formData.status === "In-Renewal" ? (
              <>
                <label className="">{t("plm.units.add.effective-date")}:</label>
                <DatePicker
                  wrapperClassName="datePicker"
                  required
                  selected={endoEffectiveDate}
                  onChange={(date) => {
                    const newDate = setTimeToNoonOne(date);
                    setEndoEffectiveDate(newDate);
                  }}
                  className="standard-form-input"
                />
              </>
            ) : null}
            <label className="d-block mt-1">{t("plm.units.add.truck-vin")}:</label>
            {truckData?.truckVIN && truckData?.truckVIN.length !== 17 && (
              <p className="text-[#8B0000]">{t("plm.units.add.alert-2")}</p>
            )}
            <input
              required
              disabled={vinDecoded}
              onChange={(e) =>
                setTruckData({ ...truckData, truckVIN: e.target.value })
              }
              value={truckData?.truckVIN}
              type="text"
              id="truckVIN"
              className={`standard-form-input ${
                vinDecoded ? " bg-gray-100" : ""
              }`}
            />
            <label className="mt-1">{t("plm.units.add.truck-year")}:</label>
            {truckData?.truckYear && truckData.truckYear.length !== 4 && (
              <p>{t("plm.units.add.alert-7")}</p>
            )}
            <input
              required
              disabled={vinDecoded}
              onChange={(e) =>
                setTruckData({
                  ...truckData,
                  truckYear: e.target.value,
                })
              }
              value={truckData.truckYear}
              type="number"
              id="truckYear"
              className={`standard-form-input ${
                vinDecoded ? " bg-gray-100" : ""
              }`}
            />
            {vinDecoded || vinWrongDecoded ? (
              <>
                {/* Show this once the VIN and year is decoded */}
                <label htmlFor="truckMake" className="mt-1">
                  {t("plm.units.add.truck-make")}:
                </label>
                <input
                  type="text"
                  required
                  disabled={!vinWrongDecoded}
                  onChange={(e) =>
                    setTruckData({
                      ...truckData,
                      truckMake: e.target.value,
                    })
                  }
                  value={truckData.truckMake}
                  id="truckMake"
                  className="standard-form-input  bg-gray-100"
                />
                <label htmlFor="truckModel" className="mt-1">
                  {t("plm.units.add.truck-model")}:
                </label>
                <input
                  type="text"
                  required
                  disabled={!vinWrongDecoded}
                  onChange={(e) =>
                    setTruckData({
                      ...truckData,
                      truckModel: e.target.value,
                    })
                  }
                  value={truckData.truckModel}
                  id="truckModel"
                  className="standard-form-input  bg-gray-100"
                />
                <label htmlFor="truckClass" className="mt-1">
                  {t("plm.units.add.truck-class")}:
                </label>
                <select
                  disabled={!vinWrongDecoded}
                  required
                  onChange={(e) => {
                    let truckWeight;
                    if (e.target.value === "Class I") {
                      truckWeight = "Up to 10,000 lbs";
                    } else if (e.target.value === "Class II") {
                      truckWeight = "10,001 to 19,500 lbs";
                    } else if (e.target.value === "Class III") {
                      truckWeight = "19,501 to 33,000 lbs";
                    } else if (e.target.value === "Class IV") {
                      truckWeight = "33,001+ lbs";
                    } else {
                      alert(t("plm.units.add.alert-1"));
                    }
                    setTruckData({
                      ...truckData,
                      truckClass: e.target.value,
                      truckWeight,
                    });
                  }}
                  value={truckData.truckClass}
                  id="truckClass"
                  className="standard-form-input bg-gray-100"
                >
                  <option value=""></option>
                  <option value="Class I">
                    {t("plm.units.add.class-1")}
                  </option>
                  <option value="Class II">
                    {t("plm.units.add.class-2")}
                  </option>
                  <option value="Class III">
                    {t("plm.units.add.class-3")}
                  </option>
                  <option value="Class IV">
                    {t("plm.units.add.class-4")}
                  </option>
                </select>
                <label htmlFor="truckRadius" className="mt-1">
                  {t("plm.units.add.operational-radius")}:
                </label>
                <select
                  required
                  onChange={(e) =>
                    setTruckData({
                      ...truckData,
                      truckRadius: e.target.value,
                    })
                  }
                  value={truckData.truckRadius}
                  id="truckRadius"
                  className="standard-form-input"
                >
                  <option value=""></option>
                  <option value="0-50">0-50</option>
                  <option value="0-100">0-100</option>
                  <option value="0-250">0-250</option>
                  <option value="0-500">0-500</option>
                  <option value="0-800">0-800</option>
                  <option value="0-1000">0-1000</option>
                  <option value="0-1200">0-1200</option>
                  <option value="0-1500">0-1500</option>
                </select>
                <label htmlFor="truckACV" className="mt-1">
                  {t("plm.units.add.actual-cash-value")} (USD):
                </label>
                <input
                  required
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/[^0-9.]/g, "");
                    const parts = inputValue.split(".");
                    if (parts.length === 2 && parts[1] === "") {
                      inputValue += "00";
                    }
                    if (parts.length > 2) {
                      inputValue = parts[0] + "." + parts.slice(1).join("");
                    }
                    if (parts[1] && parts[1].length > 2) {
                      parts[1] = parts[1].slice(0, 2);
                      inputValue = parts.join(".");
                    }
                    setTruckData({ ...truckData, truckACV: parseFloat(inputValue) });
                  }}
                  value={truckData.truckACV}
                  type="text"
                  id="truckACV"
                  className="standard-form-input"
                />
                <div>
                  <MultiUpload
                    label={t("plm.units.add.a-document")}
                    property="truckDocuments"
                    formData={truckDocuments}
                    setFormData={setTruckDocuments}
                    storageFile={`files/power-units/${truckData.truckVIN}`}
                    multiple={false}
                    changeNormal={true}
                    showPastingOption={true}
                    fileInButton={true}
                    showInAPopup={true}
                  />
                </div>
              </>
            ) : null}
            {vinDecoded ? (
              <ApprovalButtons
                onApprove={null}
                handleAsSubmit={true}
                onDecline={(e) => {
                  goBack(e);
                }}
                titleAccept={t("plm.units.add.submit")}
                titleDecline={t("plm.units.add.go-back")}
              />
            ) : (
              <ApprovalButtons
                onApprove={(e) =>
                  handleDecodeVIN(e, truckData.truckVIN, truckData.truckYear)
                }
                disabledAccept={
                  truckData.truckVIN?.length !== 17 ||
                  truckData.truckYear?.length !== 4
                }
                handleAsSubmit={false}
                onDecline={(e) => {
                  goBack(e);
                }}
                titleAccept={t("plm.units.add.submit")}
                titleDecline={t("plm.units.add.go-back")}
              />
            )}
          </form>
        </div>
      </section>
    </main>
  );
}
