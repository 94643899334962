const cleanTruckListFunction = (truckList) => {
    let cleanTruckList;
    if (Array.isArray(truckList)) {
      cleanTruckList = truckList.map((truck) => {
        const cleanedEntries = Object.entries(truck).filter(
          ([key, value]) => value !== undefined
        );
        return Object.fromEntries(cleanedEntries);
      });
    } else {
      cleanTruckList = truckList;
    }
    return cleanTruckList;
  };

export default cleanTruckListFunction;