import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import endoStatusChangeNotification from "../../../utils/notifications/endoStatusChangeNotification";

const requestExceptionModeSetting = async (
  e,
  data,
  paramPolicyID,
  paramRefID,
  endorsement,
  setRequestingExceptionMode
) => {
  e.preventDefault();
  const endoRef = doc(
    db,
    "policy-applications",
    paramPolicyID,
    "endorsements",
    paramRefID
  );
  await updateDoc(endoRef, {
    documentType: "Exception Request",
    requestException: data,
  });
  endoStatusChangeNotification(
    endorsement.type,
    endorsement.endorsementNumber,
    "Declined",
    paramRefID,
    paramPolicyID,
    endorsement.agencyID
  );
  setRequestingExceptionMode(false);
};

export default requestExceptionModeSetting;
