import React from "react";
import Checkbox from "./Checkbox";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { numberWithCommas } from "../../../utils/helpers";
import { debounce } from "lodash";
import useTranslations from "../../../Context/actions/useTranslations";

export default function NonStandardClauses({
  formData,
  setFormData,
  dropdownOptions,
  policyID,
  disabledRule,
}) {

  const { t } = useTranslations()

  return (
    <>
      <h1 className="text-lg">{t("plm.mtc.non-standard.title")}</h1>
      <Checkbox
        disabled={disabledRule}
        label={t("plm.mtc.non-standard.attached")}
        field={"trailerInterchangeTarget"}
        policyID={policyID}
        setFormData={setFormData}
        formData={formData}
        defaultToTrue={true}
      />
      {formData?.apdDetails?.trailerInterchangeTarget && (
        <div className="mb-3">
          <label htmlFor={`trailerInterchangeTarget-select`} className="ml-2">
            {t("plm.mtc.non-standard.target")}:
          </label>
          <select
            disabled={disabledRule}
            id={`trailerInterchangeTarget-select`}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
            value={formData?.apdDetails?.trailerInterchangeLimit}
            onChange={async (e) => {
              const apdDetails = {
                ...(formData.apdDetails || {}),
                trailerInterchangeLimit: e.target.value,
              };
              const policyRef = doc(db, "policy-applications", policyID);
              await updateDoc(policyRef, {
                apdDetails,
              });
            }}
          >
            <option value="">{t("plm.mtc.non-standard.choose-one")}</option>
            {dropdownOptions?.trailerInterchangeLimits
              ?.slice()
              .sort((a, b) => a - b)
              .map((limit) => (
                <option key={limit} value={limit}>
                  ${numberWithCommas(limit)}
                </option>
              ))}
          </select>

          <label
            htmlFor={`trailerInterchangeTarget-select`}
            className="mt-3 ml-2"
          >
            {t("plm.mtc.non-standard.briefly-describe-exposure")}:
          </label>
          <input
            disabled={disabledRule}
            id={"tiExposure"}
            type="text"
            className="w-full rounded p-2"
            placeholder={t("plm.mtc.non-standard.example-15")}
            onChange={async (e) => {
              const debouncedUpdateField = debounce(async (e) => {
                const apdDetails = {
                  ...(formData.apdDetails || {}),
                  tiExposure: e.target.value,
                };
                const policyRef = doc(db, "policy-applications", policyID);
                await updateDoc(policyRef, {
                  apdDetails,
                });
              }, 300);
              debouncedUpdateField(e);
            }}
          />
        </div>
      )}
    </>
  );
}
