import React from "react";
import ProgressIndicator from "./ProgressIndicator"; 
import uploadFile from "./utils/uploadFile";
import useTranslations from "../../../../Context/actions/useTranslations";

const FileUploadForm = ({
  year,
  policyID,
  formData,
  setFormData,
  setImgUrl,
  progressState,
  setProgressState,
  coverageType,
  parentFolder
}) => {

  const { t } = useTranslations()

  return (
    <>
      <form
        onSubmit={(e) =>
          uploadFile(
            e,
            setProgressState,
            `year${year + "" + coverageType}LossRunsFile`,
            policyID,
            formData,
            setFormData,
            setImgUrl,
            parentFolder
          )
        }
        className="form"
        id={`year${year + "" + coverageType}LossRunsFile`}
      >
        <p className="font-bold mb-1">{t("plm.losses.al-gl.upload")}:</p>
        <input
          type="file"
          className="w-full"
          disabled={formData.documentType !== "Application"}
        />
        <button
          type="submit"
          disabled={formData.documentType !== "Application"}
          className="bg-[#072a48] px-2 py-1 rounded d-block mt-2 mb-1 text-white font-bold w-full"
        >
          {t("plm.losses.al-gl.upload-button")}
        </button>
        {formData[`year${year + "" + coverageType}LossRunsFile`] === ""}
      </form>

      {/* Progress Bar */}
      <ProgressIndicator progressState={progressState} />

      {/* Display a message when the upload is complete */}
      {progressState === 100 && <p>{t("plm.losses.al-gl.upload-complete")}</p>}
    </>
  );
};

export default FileUploadForm;
