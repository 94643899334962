import axios from "axios";
import React, { useEffect, useState } from "react";
import PolicyDetails from "./PolicyDetails";
import { Typography } from "@mui/material";
import updatePolicyField from "../../utils/updatePolicyField";
import useTranslations from "../../../Context/actions/useTranslations";

export default function KPBrokersInfo({
  userInfo,
  activePolicies,
}) {
  const isAdminOrUnderwriter =
    userInfo.role === "Underwriter" || userInfo.role === "Admin";

  const { t } = useTranslations()

  return (
    <main>
      {activePolicies.length > 0 && (
        <>
          <Typography variant="h5" component="h2">
            {t("plm.apd.kp-brokers-info.active-policies")}
          </Typography>
          <p className="mt-2 mb-3">
            {t("plm.apd.kp-brokers-info.following")}
            {isAdminOrUnderwriter ? ` ${t("plm.apd.kp-brokers-info.insured")} ` : ` ${t("plm.apd.kp-brokers-info.your")} `} {t("plm.apd.kp-brokers-info.policy-history")}
          </p>
          {activePolicies.length > 0 &&
            activePolicies.map((policy) => (
              <PolicyDetails data={policy} userInfo={userInfo} />
            ))}
        </>
      )}
    </main>
  );
}
