import React, { useEffect, useMemo, useState } from "react";
import RatePowerUnitEndorsement from "./RatePowerUnitEndorsement";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Form from "../../generals/Forms/Form";
import {
  setPolicyDetailUpdate,
  setPolicyInfo,
} from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import handleGetPowerUnitsById from "./utils/addPowerUnit/handleGetPowerUnitsById";
import approveAddPowerUnitsEndorsement from "./utils/addPowerUnit/approveAddPowerUnitsEndorsement";
import PremiumsTable from "./AddPowerUnits/PremiumsTable";
import PowerUnitsMap from "./AddPowerUnits/PowerUnitsMap";
import requestExceptionModeSetting from "./utils/requestExceptionModeSetting";
import Modals from "./AddPowerUnits/Modals";
import ButtonInstructions from "./AddPowerUnits/ButtonInstructions";

export default function AddPowerUnits({
  endorsement,
  paramPolicyID,
  paramRefID,
  setSuccess,
  requestExceptionInputs,
  insuredInfo,
}) {
  const [unitsRated, setUnitsRated] = useState(false);
  const [endorsementData, setEndorsementData] = useState([]);
  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);
  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [taxSchedule, setTaxSchedule] = useState();
  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  useEffect(() => {
    handleGetPowerUnitsById(endorsement, setEndorsementData);
  }, [endorsement, unitsRated]);

  return (
    <>
      <Modals
        openInCancellationModal={openInCancellationModal}
        setOpenInCancellationModal={setOpenInCancellationModal}
        rejectionModal={rejectionModal}
        setRejectionModal={setRejectionModal}
        endorsement={endorsement}
        isRequestException={isRequestException}
        paramPolicyID={paramPolicyID}
        paramRefID={paramRefID}
        endorsementData={endorsementData}
        setPolicyInfo={setPolicyInfo}
        setPolicyDetailUpdate={setPolicyDetailUpdate}
        insuredInfo={insuredInfo}
        setSuccess={setSuccess}
      />
      <PowerUnitsMap
        endorsementData={endorsementData}
        paramPolicyID={paramPolicyID}
      />
      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      (user.role === "Underwriter" || user.role === "Admin") ? (
        <>
          {!unitsRated ? (
            <RatePowerUnitEndorsement
              truckList={endorsementData}
              policyID={paramPolicyID}
              endoID={paramRefID}
              setSuccess={setUnitsRated}
              endoType={endorsement.type}
            />
          ) : (
            <>
              <PremiumsTable data={endorsement.alPremiums} />
              <ButtonInstructions isRequestException={isRequestException} />
              {isRequestException && (
                <RequestExceptions
                  endorsement={endorsement}
                  color="red"
                  center={true}
                />
              )}
              <ApprovalButtons
                onApprove={(e) =>
                  approveAddPowerUnitsEndorsement(
                    e,
                    null,
                    endorsement,
                    paramPolicyID,
                    paramRefID,
                    setTaxSchedule,
                    insuredInfo,
                    setSuccess,
                    setOpenInCancellationModal,
                    endorsementData,
                  )
                }
                onDecline={() => setRejectionModal(true)}
              />
            </>
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <ApprovalButtons
          dissapearAccept={true}
          titleDecline="REQUEST EXCEPTION"
          onDecline={() => setRequestingExceptionMode(true)}
        />
      ) : null}
    </>
  );
}
