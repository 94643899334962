import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import useTranslations from "../../Context/actions/useTranslations";

const FileUploadIndicator = ({ isUploaded }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslations();

  return (
    <span
      className={`inline-block px-2 py-1 text-white w-[130px] ${
        isUploaded ? "bg-green-500" : "bg-[#8B0000]"
      } rounded-md`}
    >
      {isUploaded ? (
        <>
          {isMobileScreen ? (
            <>{t("plm.documents.uploaded")}</>
          ) : (
            <>
              <svg
                className="w-4 h-4 inline mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              {t("plm.documents.uploaded")}
            </>
          )}
        </>
      ) : (
        <>
          {isMobileScreen ? (
            <>{t("plm.documents.not-uploaded")}</>
          ) : (
            <>
              <svg
                className="w-4 h-4 inline mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
              {t("plm.documents.not-uploaded")}
            </>
          )}
        </>
      )}
    </span>
  );
};

export default FileUploadIndicator;
