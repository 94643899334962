import React, { useState } from 'react';
import { FormControl, TextField } from '@mui/material';

const SearchNameInput = ({ searchValue, setSearchValue, searchType }) => {

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value.toUpperCase());
  };

  return (
    <div className='my-4'>
      <FormControl fullWidth>
        <TextField
          id="search-input"
          label={'Drivers Full Name'}
          variant="outlined"
          value={searchValue}
          onChange={handleSearchChange}
          fullWidth
        />
      </FormControl>
    </div>
  );
};

export default SearchNameInput;
