import React, { useEffect, useMemo, useState } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDocs,
  query,
  where,
  deleteDoc,
  setDoc,
  collectionGroup,
} from "firebase/firestore";
import { db, auth } from "../../../../firebase";
import DatePicker from "react-datepicker";
import axios from "axios";
import CompanyProfile from "./CompanyProfile";
import { Button, Grid, Typography } from "@mui/material";
import {
  Cancel,
  Restore,
  Warning,
  Download as DownloadIcon,
} from "@mui/icons-material";
import GoBack from "../../../generals/GoBack";
import CancellationReasons from "../../../CancelPolicyPanel/CancellationReasons";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import config from "../../../../config/env";
import "react-datepicker/dist/react-datepicker.css";
import BlueButton from "../../../assets/forms/BlueButton";
import CustomActionButtons from "./CustomActionButtons";
import ApiService from "../../../../services/southern-api/apiService";
import LetterFlags from "../../RedFlags/LetterFlags";
import useFormDataState from "../../../../Context/actions/useFormData";
import { cancelPolicyFn } from "../../../CancelPolicyPanel/cancelPolicyFn";
import { calendarEventsEmails } from "../../../../pages/Calendar/utils";
import moment from "moment";
import { setTimeToNoonOne, setTimeToNoonOnePlusOneHour } from "../../../../utils/helpers";

function SendLetterPanel(props) {
  const policyID = props.policyID;
  const userInfo = props.userInfo;
  const insuredInfo = props.insuredInfo;
  const display = props.display;
  const setDisplay = props.setDisplay;
  const setDisplayOthers = props.setDisplayOthers;
  const setDisplayUnderwritingPanel = props.setDisplayUnderwritingPanel;
  const formData = props.formData;
  const initialLetterType = props.initialLetterType;
  const activateListener = props.activateListener;
  const [showCompanySelection, setShowCompanySelection] = useState(false);
  const [selected, setSelected] = useState(props.agencyID);
  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState();

  const aYearFromEffectiveDate = formData
    ? new Date(formData?.effectiveDate.toDate())
    : new Date();
  aYearFromEffectiveDate.setFullYear(aYearFromEffectiveDate.getFullYear() + 1);
  const expirationDate = aYearFromEffectiveDate.toString().slice(4, 15);
  const [policyTerm, setPolicyTerm] = useState(
    formData.effectiveDate.toDate().toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }) +
      " - " +
      aYearFromEffectiveDate.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })
  );
  const [letterPolicyNumber, setLetterPolicyNumber] = useState(
    formData.alPolicyNo
  );
  const [letter2ndPolicyNumber, setLetter2ndPolicyNumber] = useState(
    formData.glPolicyNo || " "
  );
  const [letterPolicyEffectiveDate, setLetterPolicyEffectiveDate] = useState(
    formData.effectiveDate.toDate()
  );
  const [dateOfReinstatement, setDateOfReinstatement] = useState(new Date());
  const [letter, setLetter] = useState(initialLetterType || "");
  const [showLetterEditor, setShowLetterEditor] = useState(
    initialLetterType ? true : false
  );
  const [NOCReasonExplanation, setNOCReasonExplanation] = useState("");

  useEffect(() => {
    if (activateListener) {
      setLetter(initialLetterType);
      setDisplay(true);
      setDisplayUnderwritingPanel(true);
      setShowLetterEditor(true);
    }
  }, [initialLetterType])

  const [proofFiles, setProofFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [pdfWasPrinted, setPdfWasPrinted] = useState(false);

  const [loading, setLoading] = useState(false);
  const [fileURL, setFileURL] = useState("");
  const showPreview = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setShowLetterEditor(false);
    setShowLetterPreview(true);
  };

  const handleChangeLetterType = (e, letterType) => {
    e.preventDefault();
    setLetter(letterType);
    setShowLetterEditor(true);
    setDisplayOthers(false);
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [cancellationReasons, setCancellationReasons] = useState({});

  const parseCancellationReasons = (data) => {
    const isEmpty = Object.keys(data).length === 0;
    if (isEmpty) return;

    const reasonsArray = [];
    const otherReason = data["otherReason"];

    // Add all reasons except "Other" and "Other Reason" to reasonsArray
    Object.keys(data).forEach((key) => {
      if (key !== "other" && key !== "otherReason" && data[key]) {
        reasonsArray.push(
          key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())
        );
      }
    });

    // Add "Other: [my string]" if present
    if (data["other"] && otherReason) {
      reasonsArray.push(`Other: ${otherReason}`);
    } else if (data["other"]) {
      reasonsArray.push("Other");
    }

    let reasons = "";

    if (reasonsArray.length > 1) {
      reasons =
        reasonsArray.slice(0, -1).join(", ") +
        " and " +
        reasonsArray[reasonsArray.length - 1];
    } else {
      reasons = reasonsArray[0];
    }

    console.log(reasons);
    return reasons;
  };

  const { REACT_APP_SOUTHERN_BACKEND } = config;

  const [redFlags, setRedFlags] = useState([])

  const { formDataGL } = useFormDataState()

  const handleSendLetter = async (e) => {
    e.preventDefault();
    const NOCReason = parseCancellationReasons(cancellationReasons);
    try {
      const letterData = {
        proofFiles,
        pdfFiles,
        created: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        policyID,
        insuredID: formData.insuredID,
        agencyID: formData.agencyID,
        letterType: letter,
        recipientID: selectedCompanyInfo?.id,
        recipientCompanyName: selectedCompanyInfo?.company,
        recipientAddress: selectedCompanyInfo?.address,
        recipientCity: selectedCompanyInfo?.city,
        recipientState: selectedCompanyInfo?.state,
        mailingDate: new Date().toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
        recipientZip: selectedCompanyInfo?.zip,
        pdfURL: fileURL,
        policyNumberOne: letterPolicyNumber,
        policyNumberTwo: letter2ndPolicyNumber,
        policyTerm: policyTerm,
        NOCReason: NOCReason ? NOCReason : "",
        expirationDate,
        effectiveDateOfReinstatement: !dateOfReinstatement
          ? ""
          : dateOfReinstatement.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }),
        uneditedDate: !dateOfReinstatement
          ? ""
          : dateOfReinstatement.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }),
        description:
          letter +
          " for " +
          selectedCompanyInfo?.company +
          " on " +
          new Date().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
      };

      if (redFlags.length > 0) {
        letterData["redFlags"] = redFlags;
      }

      // TODO: Make Letter log work off of these collections, I got rid of the letter log function because it was redundant data.
      // Create Entry that triggers zap

      let letterNOCID = "";
      let letterNORID = "";
      if (letter === "NOR") {
        const addRef = await addDoc(collection(db, "NORs"), letterData);
        letterNORID = addRef.id;
      }
      if (letter === "NOC") {
        const addRef = await addDoc(collection(db, "NOCs"), letterData);
        letterNOCID = addRef.id;
      }
      if (letter === "PNONR") {
        await addDoc(collection(db, "PNONRs"), letterData);
      }

      const cancellationData = {
        requestDate: new Date(),
        requestingUserID: auth.currentUser.uid,
        requestingUser: auth.currentUser.displayName,
        cancellationDate: new Date(),
        cancellationReasons,
      };
      const newPolicyDataCancellation = {
        status: "In-Cancellation",
        expirationDate: dateOfReinstatement,
        inCancellation: true,
        cancellationData,
      };
      const newPolicyDataRenewal = {
        status: "In-Force",
        expirationDate: dateOfReinstatement,
        inCancellation: false,
        cancellationData: {
          ...cancellationData,
          renewalDate: new Date(),
          renewingUser: auth.currentUser.displayName,
          renewingUserID: auth.currentUser.uid,
        },
      };
      
      const formDataGLID = formDataGL?.id

      const policyDocRef = doc(db, "policy-applications", policyID);
      if (letter === "NOC") {
        await updateDoc(policyDocRef, newPolicyDataCancellation);
        const handleSendEventToCalendarNOC = async () => {
          try {
            const eventData = {
              "start-date": setTimeToNoonOne(dateOfReinstatement),
              "end-date": setTimeToNoonOnePlusOneHour(dateOfReinstatement),
              "timezone": "America/Mexico_City",
              "summary": "Notice of Cancellation",
              "description": `Notice of Cancellation for ${selectedCompanyInfo?.company}`,
              "users": calendarEventsEmails,
              "nocID": letterNOCID,
              "policyID": policyID,
              "agencyID": formData.agencyID,
              "insuredID": formData.insuredID,
              "status": "Active",
              "authorID": auth.currentUser.uid,
              "automatic": true,
            }
            await addDoc(collection(db, "calendar-events"), eventData);
          } catch (error) {
            console.error('Error sending event to calendar', error)
          }
        }
        await handleSendEventToCalendarNOC()
      }
      if (letter === "NOR") {
        await updateDoc(policyDocRef, newPolicyDataRenewal);
       
        const handleSendEventToCalendarNOR = async () => {
          const calendarEvents = await getDocs(query(collection(db, "calendar-events"), where("policyID", "==", policyID)))
          for (const calendarEvent of calendarEvents.docs) {
            const calendarData = {
              ...calendarEvent.data(),
              id: calendarEvent.id
            }
            await setDoc(
              doc(db, "calendar-events", calendarEvent.id),
              {
                status: "Cancelled",
              },
              { merge: true }
            );
            const endorsementsQuery = query(
              collectionGroup(db, "endorsements"),
              where("nocID", "==", calendarData.nocID),
              where("type", "==", "Cancellation")
            );
            const endorsementsSnapshot = await getDocs(endorsementsQuery);
            const endorsements = endorsementsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            for (const endorsement of endorsements) {
              await setDoc(
                doc(db, "policy-applications", policyID, "endorsements", endorsement.id),
                {
                  status: "Declined",
                  rejectionReasons: ["Notice of Reinstatement was generated"],
                },
                { merge: true }
              );
            }
          }
        }
        await handleSendEventToCalendarNOR()
      }

      if (formDataGLID) {
        const policyGLDocRef = doc(db, "gl-policies", formDataGLID);
        if (letter === "NOC") {
          await setDoc(policyGLDocRef, newPolicyDataCancellation, { merge: true });
        }
        if (letter === "NOR") {
          await setDoc(policyGLDocRef, newPolicyDataRenewal, { merge: true });
        }
      }
      
      if (props?.stoneMarkData) {
        try {
          const stonemarkRef = doc(db, "stonemark", props?.stoneMarkData?.id);
          await updateDoc(stonemarkRef, {
            status: "Deleted",
          });
        } catch (error) {
          console.error("Error updating stonemark", error);
        }
      }

      // Log System Activity to System Log
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          letter +
          " sent via USPS certified mail to " +
          selectedCompanyInfo?.company,
      });

      // Send Email
      const sendEmail = async (e, route) => {
        e.preventDefault();
        // TODO: Get these emails dynamically, make sure all these people get added
        axios
          .post(REACT_APP_SOUTHERN_BACKEND + route, {
            to: "dlicona@liconainsurance.com",
            cc: [
              "kcorona@liconainsurance.com",
              "felicona@liconainsurance.com",
              "colt@southernstarmga.com",
            ],
            created: new Date(),
            recipientID: selectedCompanyInfo?.id,
            recipientCompanyName: selectedCompanyInfo?.company,
            recipientAddress: selectedCompanyInfo?.address,
            recipientCity: selectedCompanyInfo?.city,
            recipientState: selectedCompanyInfo?.state,
            mailingDate: new Date().toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }),
            recipientZip: selectedCompanyInfo?.zip,
            pdfURL: fileURL,
            policyNumberOne: letterPolicyNumber,
            policyNumberTwo: letter2ndPolicyNumber,
            policyTerm: policyTerm,
            NOCReason,
            expirationDate,
            effectiveDateOfReinstatement:
              dateOfReinstatement.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              }),
            uneditedDate: dateOfReinstatement,
            description:
              letter +
              " for " +
              selectedCompanyInfo?.company +
              " on " +
              new Date().toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              }),
          })
          .then(async (response) => {
            // handle success
          })
          .then(() => {})
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      };

      if (letter === "NOC") {
        sendEmail(e, "/send-email/notice-of-cancellation");
      } else if (letter === "NOR")
        sendEmail(e, "/send-email/notice-of-reinstatement");
    } catch (error) {
      alert("There was an error sending the letter. Please try again.");
    } finally {
      setShowSuccess(true);
    }
  };

  const [showLetterPreview, setShowLetterPreview] = useState(false);

  const resetPage = (e) => {
    e.preventDefault();
    setSelected(props.agencyID);
    setLetter("");
    setFileURL("");
    setDateOfReinstatement(new Date());
    setCancellationReasons({});
    setPolicyTerm("");
    setShowSuccess(false);
    setShowLetterEditor(false);
    setShowLetterPreview(false);
    setShowCompanySelection(true);
    setDisplayOthers(true);
    setDisplay(true);
  };

  useEffect(() => {
    // console.log(selectedCompanyInfo);
    // console.log(parseCancellationReasons(cancellationReasons));
    // console.log(cancellationReasons);
  }, [db, loading, letter]);

  const fileProps = useMemo(() => {
    if (letter === "NOC") {
      return {
        label: "Notice of cancellation proof",
        property: "notice-of-cancellation",
        storageFile: `files/filings-proof`,
        fileInButton: true,
      };
    } else if (letter === "NOR") {
      return {
        label: "NOR Proof",
        property: "notice-of-reinstatement",
        storageFile: `files/filings-proof`,
        fileInButton: true,
      };
    } else if (letter === "PNONR") {
      return {
        label: "PNONR Proof",
        property: "provisional-notice-of-non-renewal",
        storageFile: `files/filings-proof`,
        fileInButton: true,
      };
    } else {
      return {
        label: "NOC Proof",
        property: "notice-of-cancellation",
        storageFile: `files/filings-proof`,
        fileInButton: true,
      };
    }
  }, [letter]);

  const formatDate = (date) => {
    if (!date) return ''
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const apiService = new ApiService()
  const handleParseFilings = async () => {
    try {
      const redFlags = []
      if (pdfFiles?.[0]) {
        const parsedFiling = await apiService.parseFilings({
          url: pdfFiles?.[0],
        })
        const policyNo = parsedFiling?.data?.policy_number
        const effectiveDate = parsedFiling?.data?.effectiveDate
        const action = parsedFiling?.data?.action
        if (formData?.alPolicyNo !== policyNo) {
          redFlags.push('AL Policy Number does not matched')
        }
        if (
          letter === 'NOC' &&
          effectiveDate !== formatDate(dateOfReinstatement)
        ) {
          redFlags.push('Effective Date does not matched')
        }
        if (action !== 'ACCEPTED') {
          redFlags.push('Action was not accepted')
        }
        setRedFlags(redFlags)
      }
    } catch (error) {
      console.error('Error parsing filings', error)
    }
  }

  useEffect(() => {
    handleParseFilings()
  }, [pdfFiles, dateOfReinstatement, letter])

  const onSecondaryUploadFn = async (urls, results) => {
    const pdfUrl = results?.[0]?.pdfUrl;
    const filingProofsRef = collection(db, "proof-of-filings");
    const payload = {
      creationDate: new Date(),
      insuredID: formData.insuredID,
      agencyID: formData.agencyID,
      insuredDOT: insuredInfo.dot,
      submittingUser: auth.currentUser.uid,
      submittingUserName: auth.currentUser.displayName,
      url: urls[0],
      filingType: letter,
    };
    if (pdfUrl) {
      payload["pdfUrl"] = pdfUrl;
      setPdfFiles([pdfUrl]);
    }
    await addDoc(filingProofsRef, payload);
  };

  const onSecondaryDeleteBeforeFn = async () => {
    try {
      const deleteDocsByURL = async (urlToDelete) => {
        const querySnapshot = await getDocs(
          query(
            collection(db, "proof-of-filings"),
            where("url", "==", urlToDelete)
          )
        );
        const deletePromises = [];
        querySnapshot.forEach((doc) => {
          deletePromises.push(deleteDoc(doc.ref));
        });
        await Promise.all(deletePromises);
      };
      await deleteDocsByURL(proofFiles[0]);
      setPdfFiles([]);
      setPdfWasPrinted(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <main>
      <LetterFlags redFlags={redFlags} />
      {userInfo.role === "Underwriter" || userInfo.role === "Admin" ? (
        <section>
          {display && (
            <section>
              {letter === "" && <h1 className="text-2xl">Send Letters</h1>}
              <div className="p-1">
                <div className="mt-2 mb-3">
                  {letter === "" && (
                    <p>
                      Select a letter below to automatically send certified mail
                      (USPS) notices to the insured.
                    </p>
                  )}
                </div>
                {!showSuccess ? (
                  <div>
                    <section>
                      {letter !== "" &&
                      typeof initialLetterType === "undefined" ? (
                        <>
                          <GoBack
                            label={"Return to Letter Selection"}
                            handleFn={() => {
                              setLetter("");
                              setShowLetterPreview(false);
                              setShowCompanySelection(false);
                              setDisplayOthers(true);
                              setShowLetterEditor(true);
                              setProofFiles([]);
                              setPdfFiles([]);
                              setPdfWasPrinted(false);
                            }}
                          />
                        </>
                      ) : null}
                      <h1 className="text-2xl text-[#072a48] font-[600] mb-2">
                        {letter !== "" && <>Send </>}
                        {letter === "NOR" && <>Notice of Reinstatement</>}
                        {letter === "NOC" && <>Notice of Cancellation</>}
                        {letter === "PNONR" && (
                          <>Provisional Notice of Non-Renewal</>
                        )}
                      </h1>
                      {letter !== "" && (
                        <>
                          <p className="mb-1">Policies:</p>
                          <p className="mb-1">
                            {formData.alPolicyNo + " (AL)"}
                          </p>
                          {formData.glPolicyNo && (
                            <p className="mb-3">
                              {formData.glPolicyNo + " (GL)"}
                            </p>
                          )}
                        </>
                      )}
                      {!(selected && showLetterPreview) && Boolean(letter) ? (
                        <div>
                          <MultiUpload
                            label={fileProps.label}
                            property={fileProps.property}
                            formData={proofFiles}
                            setFormData={setProofFiles}
                            storageFile={fileProps.storageFile}
                            multiple={false}
                            changeNormal={true}
                            dissapearWhenImgExists={true}
                            showPastingOption={true}
                            fileInButton={true}
                            showInAPopup={true}
                            acceptedFormat={["PDF"]}
                            onSecondaryUploadFn={onSecondaryUploadFn}
                            onSecondaryDeleteBeforeFn={onSecondaryDeleteBeforeFn}
                          />
                        </div>
                      ) : null}

                      {pdfFiles?.[0] &&
                      !(selected && showLetterPreview) &&
                      Boolean(letter) ? (
                        <BlueButton
                          text={`Print ${fileProps.label} PDF`}
                          onClickFunction={(e) => {
                            e.preventDefault();
                            const pdfUrl = pdfFiles[0];
                            window.open(pdfUrl, "_blank");
                            setPdfWasPrinted(true);
                          }}
                          hasContentToTop={true}
                          hasContentToBottom={true}
                          icon={<DownloadIcon />}
                        />
                      ) : null}

                      {!showLetterPreview && (
                        <div>
                          {letter === "" && (
                            <div>
                              <CustomActionButtons
                                setProofFiles={setProofFiles}
                                handleChangeLetterType={handleChangeLetterType}
                              />
                            </div>
                          )}
                          {showLetterEditor && (
                            <section>
                              {!loading ? (
                                <section>
                                  {letter !== "" && (
                                    <CompanyProfile
                                      agencyID={userInfo.agencyID}
                                      insuredID={insuredInfo.id}
                                      setSelected={setSelected}
                                      selectedCompanyInfo={selectedCompanyInfo}
                                      setSelectedCompanyInfo={
                                        setSelectedCompanyInfo
                                      }
                                    />
                                  )}
                                  {letter === "NOR" && (
                                    <form onSubmit={(e) => showPreview(e)}>
                                      <h2 className="text-lg font-[600] mt-3">
                                        Enter Letter Details
                                      </h2>
                                      <p className="my-2">
                                        The following information must be filled
                                        manually. All fields are required.
                                      </p>
                                      <label className="d-block">
                                        Date of Reinstatement
                                      </label>
                                      <DatePicker
                                        wrapperClassName="datePicker"
                                        selected={dateOfReinstatement}
                                        onChange={(date) => {
                                          setDateOfReinstatement(date);
                                          console.log("new date" + date);
                                        }}
                                        className={
                                          "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        }
                                      />
                                      {
                                        <>
                                          
                                            <ApprovalButtons
                                              onApprove={null}
                                              handleAsSubmit={true}
                                              onDecline={() => {
                                                setLetter("");
                                                setShowLetterPreview(false);
                                                setShowCompanySelection(false);
                                                setDisplayOthers(true);
                                                setShowLetterEditor(true);
                                                setProofFiles([]);
                                                setPdfFiles([]);
                                                setPdfWasPrinted(false);
                                              }}
                                              titleAccept="Send Letter"
                                              titleDecline="Cancel"
                                              disabledAccept={
                                                !Boolean(proofFiles?.[0])
                                              }
                                            />
                                          
                                        </>
                                      }
                                    </form>
                                  )}
                                  {letter === "NOC" && (
                                    <form onSubmit={(e) => showPreview(e)}>
                                      <label className="d-block mt-2">
                                        Date of Cancellation
                                      </label>
                                      <DatePicker
                                        wrapperClassName="datePicker"
                                        required
                                        selected={dateOfReinstatement}
                                        onChange={(date) => {
                                          setDateOfReinstatement(date);
                                          console.log("new date" + date);
                                        }}
                                        className={
                                          "mt-1 mb-2 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        }
                                      />
                                      <CancellationReasons
                                        cancellationReasons={
                                          cancellationReasons
                                        }
                                        setCancellationReasons={
                                          setCancellationReasons
                                        }
                                      />
                                      {
                                        <>
                                            <ApprovalButtons
                                              onApprove={null}
                                              handleAsSubmit={true}
                                              onDecline={() => {
                                                setLetter("");
                                                setShowLetterPreview(false);
                                                setShowCompanySelection(false);
                                                setDisplayOthers(true);
                                                setShowLetterEditor(true);
                                                setProofFiles([]);
                                                setPdfFiles([]);
                                                setPdfWasPrinted(false);
                                              }}
                                              titleAccept="Send Letter"
                                              titleDecline="Cancel"
                                              disabledAccept={
                                                !Boolean(proofFiles?.[0])
                                              }
                                            />
                                        </>
                                      }
                                    </form>
                                  )}
                                  {letter === "PNONR" && (
                                    <form onSubmit={(e) => showPreview(e)}>
                                      <h2 className="text-lg font-[600]">
                                        Enter Letter Details
                                      </h2>
                                      <p className="my-2">
                                        No details are necessary for this
                                        letter. Click send letter to continue.
                                      </p>
                                      {
                                        <>
                                            <ApprovalButtons
                                              onApprove={null}
                                              handleAsSubmit={true}
                                              onDecline={() => {
                                                setLetter("");
                                                setShowLetterPreview(false);
                                                setShowCompanySelection(false);
                                                setDisplayOthers(true);
                                                setShowLetterEditor(true);
                                                setProofFiles([]);
                                                setPdfFiles([]);
                                                setPdfWasPrinted(false);
                                              }}
                                              titleAccept="Send Letter"
                                              titleDecline="Cancel"
                                              disabledAccept={
                                                !Boolean(proofFiles?.[0])
                                              }
                                            />
                                        </>
                                      }
                                    </form>
                                  )}
                                </section>
                              ) : (
                                <p className="text-center mx-auto font-bold text-xl">
                                  We are generating your letter, please wait...
                                </p>
                              )}
                            </section>
                          )}
                        </div>
                      )}
                      {selected && showLetterPreview && pdfFiles?.[0] ? (
                        <section>
                          <p className="my-4">
                            You are about to{" "}
                            <span className="font-bold">
                              {letter === "NOR"
                                ? "REINSTATE"
                                : letter === "NOC"
                                ? "CANCEL"
                                : "PROVISIONALLY NON-RENEW"}{" "}
                            </span>
                            the AL/GL policies for{" "}
                            {selectedCompanyInfo?.company}. If you wish to
                            proceed, click Send Letter.
                          </p>
                          <ApprovalButtons
                            handleAsSubmit={false}
                            onApprove={(e) => handleSendLetter(e)}
                            onDecline={() => {
                              setLetter("");
                              setShowLetterPreview(false);
                              setShowCompanySelection(false);
                              setDisplayOthers(true);
                              setShowLetterEditor(true);
                              setProofFiles([]);
                              setPdfFiles([]);
                              setPdfWasPrinted(false);
                            }}
                            titleAccept="Send Letter"
                            titleDecline="Cancel"
                          />
                        </section>
                      ) : null}
                    </section>
                  </div>
                ) : (
                  <div className="card-body">
                    <article className="align-items-center justify-content-center text-center">
                      <h2 className="text-2xl font-[600]">
                        Your letter was successfully sent.
                      </h2>
                      <button
                        onClick={(e) => {
                          resetPage(e);
                          if (props.closeModal) {
                            props.closeModal();
                          }
                        }}
                        className="text-[#072a48] mt-4"
                      >
                        Return To Main Menu
                      </button>
                    </article>
                  </div>
                )}
              </div>
            </section>
          )}
        </section>
      ) : (
        <p>You must be an underwriter or administrator to view this page.</p>
      )}
    </main>
  );
}

export default SendLetterPanel;
