import React, { useEffect, useState } from "react";
import NumberInput from "../../assets/forms/NumberInput";
import YesNo from "./AppointmentYesNo";
import textInputs from "./utils/step1TextInputs";
import numberInputs from "./utils/step1NumberInputs";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import axios from "axios";
import TextInput from "./AppointmentTextInput";
import config from "../../../config/env";

export default function Appointment() {
  const [applicationData, setApplicationData] = useState({});
  const [agencyData, setAgencyData] = useState({});

  const { paramRefID } = useParams();

  const agencyRef = doc(db, "agencies", paramRefID);

  const getAgencyData = async () => {
    const agencyData = await getDoc(agencyRef);
    setAgencyData(agencyData.data());
    setApplicationData(agencyData.data().application);
  };

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const updateAgencyData = async (e) => {
    e.preventDefault();
    await updateDoc(agencyRef, { application: {...applicationData, isSubmitted: true} });
    // await sendEmail();
    alert("Your application has been submitted!");
    navigate("/account")
    setSuccess(true);
  };

  const { REACT_APP_SOUTHERN_BACKEND } = config;

  // TODO: In admin section add a configuration for these notifications. Then make the recipients dynamic.
  const sendEmail = async () => {
    axios
      .post(
        `${REACT_APP_SOUTHERN_BACKEND}/send-email/application-submitted`,
        {
          to: "dlicona@liconainsurance.com",
          cc: [
            "colt@southernstarmga.com",
            "abdiel@southernstarmga.com",
            "amarino@southernstarmga.com",
          ],
          name: auth.currentUser.displayName,
          company: agencyData.name,
          email: agencyData.primaryContactEmail,
          phone: agencyData.phoneNumber,
        }
      )
      .then(function (response) {
        // handle success
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  useEffect(() => {
    getAgencyData();
  }, []);

  return (
    <div className="max-w-[750px] mx-auto my-4">
      <form onSubmit={updateAgencyData}>
        <fieldset disabled={applicationData?.isSubmitted}>
        <h1 className="text-2xl font-bold">Appointment Application</h1>
        {!applicationData?.isSubmitted ? (
        <p className="my-3">
          Please fill out the following information to the best of your ability.
          <span className="d-block mt-1">
            Enter N/A or 0 if the field does not apply to your agency.
          </span>
        </p>)
        :
        (<p className="my-3">
          Your application has been submitted, thank you.
        </p>)
        }
        {textInputs.map((textInput, index) => (
          <TextInput
            key={index}
            label={textInput.label}
            required={textInput.required}
            property={textInput.property}
            setFormData={setApplicationData}
            formData={applicationData}
          />
        ))}

        <YesNo
          label={
            "Does the firm specialize in any specific line or class of business:"
          }
          property={"specializes"}
          setFormData={setApplicationData}
          formData={applicationData}
        />
        {applicationData?.specializes === "Yes" && (
          <TextInput
            label="Please explain:"
            required={true}
            saveForm={() => {}}
            property="specializesExplanation"
            setFormData={setApplicationData}
            formData={applicationData}
            setFormSaved={() => {}}
            doNotDebounce={true}
          />
        )}

        {numberInputs.map((numberInput, index) => (
          <NumberInput
            key={index}
            label={numberInput.label}
            required={numberInput.required}
            saveForm={() => {}}
            property={numberInput.property}
            setFormData={setApplicationData}
            formData={applicationData}
            setFormSaved={() => {}}
          />
        ))}
        {!applicationData?.isSubmitted && (
        <ApprovalButtons
          onApprove={null}
          handleAsSubmit={true}
          disabledDecline={true}
          dissapearDecline={true}
          titleAccept="Submit Application"
          titleDecline=""
          disabledAccept={false}
        />)}</fieldset>
      </form>
    </div>
  );
}
