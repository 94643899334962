import { collection, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import { db } from "../../../../firebase";
import config from "../../../../config/env";
import Modal from "react-modal";
import { Box, Tooltip, Typography } from "@mui/material";
import Popup from "../../../generals/Popup";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function CreateNewQuote({
  modalIsOpen,
  setIsOpen,
  formData,
  setFormData,
  showQuoteSuccess,
  setShowQuoteSuccess,
  loading,
  setLoading,
  createNewQuote,
  adjustmentFormData,
  setAdjustmentFormData,
  truckListData,
  setTruckListData,
}) {
  const { REACT_APP_SOUTHERN_BACKEND } = config;

  const { t } = useTranslations();

  const getTruckListData = async () => {
    const agencyID = formData.agencyID;
    const insuredID = formData.insuredID;
    let truckList = [];
    let totalRating = 0;
    const truckSnapshot = await getDocs(
      collection(db, "agencies", agencyID, "insureds", insuredID, "power-units")
    );
    truckSnapshot.forEach((doc) => {
      truckList.push({ ...doc.data(), id: doc.id });
      totalRating += doc.data().rating;
    });
    setTruckListData([totalRating, truckList]);
    setLoading(false);
  };

  function closeModal() {
    setIsOpen(false);
    setAdjustmentFormData({
      percentage: 0,
      debitOrCredit: "Debit",
      recipientFields: [""],
      renewalCredit: 0,
      renewalCreditDebitOrCredit: "Credit",
      biLiabilityDeductible: "$1,000",
      lossRatioAdjustmentPercentage: 0,
      lossRatioAdjustmentDebitOrCredit: "Debit",
      safetyAdjustmentPercentage: 0,
      safetyAdjustmentDebitOrCredit: "Debit",
    });
  }

  useEffect(() => {
    getTruckListData();
  }, [loading]);

  const adjustmentFields = [
    {
      label: t('plm.quotes.create-new.manual-adjustment'),
      fieldKey: "percentage",
      selectKey: "debitOrCredit",
      options: ["Debit", "Credit"],
    },
    {
      label: t('plm.quotes.create-new.loss-ratio-adjustment'),
      fieldKey: "lossRatioAdjustmentPercentage",
      selectKey: "lossRatioAdjustmentDebitOrCredit",
      options: ["Debit", "Credit"],
    },
    {
      label: t('plm.quotes.create-new.safety-adjustment'),
      fieldKey: "safetyAdjustmentPercentage",
      selectKey: "safetyAdjustmentDebitOrCredit",
      options: ["Debit", "Credit"],
    },
  ];

  return (
    <Popup isOpen={modalIsOpen} onRequestClose={closeModal} maxWidth={"500px"}>
      {loading ? (
        <></>
      ) : (
        <Box>
          {showQuoteSuccess ? (
            <p>{t('plm.quotes.create-new.quote-loading')}</p>
          ) : (
            <div>
              <Typography variant="h6" gutterBottom>
                {t('plm.quotes.create-new.title')}
              </Typography>
              <form
                onSubmit={(e) => {
                  closeModal();
                  createNewQuote(e, false, adjustmentFormData);
                }}
              >
                <label>{t('plm.quotes.create-new.title')}:</label>
                <input
                  required
                  className="standard-form-input"
                  type="text"
                  value={adjustmentFormData.description}
                  onChange={(e) =>
                    setAdjustmentFormData({
                      ...adjustmentFormData,
                      description: e.target.value,
                    })
                  }
                  placeholder={t('plm.quotes.create-new.title')}
                />

                {adjustmentFields.map((field) => (
                  <div key={field.fieldKey} className="my-2 p-2 border-top">
                    <label className="d-block w-full">{field.label}:</label>
                    <div className="flex items-center">
                      <input
                        required
                        className="w-1/2 p-2 m-1 border rounded"
                        type="number"
                        value={adjustmentFormData[field.fieldKey]}
                        onChange={(e) =>
                          setAdjustmentFormData({
                            ...adjustmentFormData,
                            [field.fieldKey]: parseInt(e.target.value),
                          })
                        }
                      />
                      %
                      <select
                        required
                        className="w-1/2 p-2 m-1 border rounded"
                        value={adjustmentFormData[field.selectKey]}
                        onChange={(e) =>
                          setAdjustmentFormData({
                            ...adjustmentFormData,
                            [field.selectKey]: e.target.value,
                          })
                        }
                      >
                        {field.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}

                <Tooltip
                  title={t('plm.quotes.create-new.this-value')}
                  placement="top"
                >
                  <div className="my-2 p-2 border-top">
                    <label className="d-block w-full">{t('plm.quotes.create-new.renewal-credit')}:</label>
                    <div className="flex items-center">
                      <select
                        required
                        className="w-1/2 p-2 m-1 border rounded"
                        value={adjustmentFormData.renewalCredit}
                        onChange={(e) =>
                          setAdjustmentFormData({
                            ...adjustmentFormData,
                            renewalCredit: e.target.value,
                          })
                        }
                      >
                        <option value={0}>0</option>
                        <option value={7.5}>7.5</option>
                      </select>
                      %
                      <select
                        disabled={true}
                        required
                        className="w-1/2 p-2 m-1 border rounded"
                        value={adjustmentFormData.renewalCreditDebitOrCredit}
                        onChange={(e) =>
                          setAdjustmentFormData({
                            ...adjustmentFormData,
                            renewalCreditDebitOrCredit: e.target.value,
                          })
                        }
                      >
                        <option value="Debit">{t('plm.quotes.create-new.debit')}</option>
                        <option value="Credit">{t('plm.quotes.create-new.credit')}</option>
                      </select>
                    </div>
                  </div>
                </Tooltip>

                <button
                  className="bg-[#072a48] hover:bg-[#116582] w-full rounded mt-3 p-2 text-white"
                  type="submit"
                >
                  {t('plm.quotes.create-new.submit')}
                </button>
              </form>
            </div>
          )}
        </Box>
      )}
    </Popup>
  );
}
