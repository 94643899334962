import React from "react";
import numberWithCommas from "../../utils/numberWithCommas";
import { Button } from "@mui/material";

export default function Deductible({
  formData,
  dropdownOptions,
  disabledRule,
  handleDropdownChange,
  handleEditClick,
  isAdminOrUnderwriter,
  t,
}) {
  return (
    <section>
      <label
        htmlFor={`select-target-deductible`}
        className="block text-sm font-medium text-gray-700"
      >
        {t("plm.apd.target-deductible")}
      </label>
      <select
        disabled={disabledRule}
        id={`select-target-deductible`}
        onChange={(e) =>
          handleDropdownChange("targetDeductible", e.target.value)
        }
        value={formData?.apdDetails?.targetDeductible || ""}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
      >
        <option value="">{t("plm.apd.choose-deductible")}</option>
        {dropdownOptions.targetDeductibles
          .slice()
          .sort((a, b) => a - b)
          .map((deductible) => (
            <option key={deductible} value={deductible}>
              ${numberWithCommas(deductible)}
            </option>
          ))}
      </select>
      {isAdminOrUnderwriter && (
        <Button
          sx={{ fontSize: "10px" }}
          onClick={() => handleEditClick("targetDeductibles")}
        >
          {t("plm.apd.edit-options")}
        </Button>
      )}
    </section>
  );
}
