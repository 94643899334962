import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { getLastId } from "../../../../../utils/endorsementDetails";

const handleGetPowerUnitsById = async (endorsement, setEndorsementData) => {
    let powerUnits = [];
    for (const powerUnitId of endorsement.data) {
      const docRef = doc(
        db,
        "agencies",
        endorsement.agencyID,
        "insureds",
        endorsement.insuredID,
        "power-units",
        getLastId(powerUnitId.path)
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        powerUnits.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log("No such document!");
        return null;
      }
    }
    setEndorsementData(powerUnits);
  };

export default handleGetPowerUnitsById;