import { useState, useEffect, useRef } from "react";
import {
  activeFilters,
  manageEndorsementOptions,
} from "../../utils/searchFilters";
import ApiService from "../../services/southern-api/apiService";
import usePaginationsState from "../../Context/actions/usePaginationsState";
import { initialState } from "../../Context/AppContext";

const useEndorsements = (props) => {
  const policyID = props.policyID;
  const insuredInfo = props.insuredInfo;
  const userInfo = props.userInfo;
  const endoEffectiveDate = props.endoEffectiveDate;
  const formData = props.formData;
  const truckData = props.truckData;
  const agencyInfo = props.agencyInfo;

  const apiService = new ApiService('southern')

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const [currentEndoFilters, setCurrentEndoFilters] = useState({
    submitted: true,
  });

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [endorsements, setEndorsements] = useState([]);
  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false)
  const [page, setPage] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [results, setResults] = useState([]);
  const [driverNameFilter, setDriverNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Submitted");
  const [completeResultQuery, setCompleteResultQuery] = useState(null);

  const handleEndorsementRequest = async () => {
    try {
      setSkeletonTableLoader(true)
      const payload = {
        currentPage: 0,
        itemsPerPage: 10,
        filters: [selectedStatus]
      }
      const result = await apiService.paginatedRequestsEndorsement(payload)
      setCompleteResultQuery(result)
      setEndorsements(result?.data)
      setSkeletonTableLoader(false)
    } catch (error) {
      console.error(error)
      setEndorsements([])
      setSkeletonTableLoader(false)
    }
  }

  useEffect(() => {
    handleEndorsementRequest()
  }, []);

  return {
    endorsements,
    openModal,
    modalIsOpen,
    afterOpenModal,
    closeModal,
    policyID,
    insuredInfo,
    agencyInfo,
    userInfo,
    endoEffectiveDate,
    formData,
    truckData,
    currentEndoFilters,
    setCurrentEndoFilters,
    skeletonTableLoader,
  };
};

export default useEndorsements;
