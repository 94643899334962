import config from "../../config/env";
import HttpService from "./httpService";

const { REACT_APP_SOUTHERN_BACKEND } = config;

class ApiService extends HttpService {
  constructor(backend) {
    let url = "";
    switch (backend) {
      case "southern":
        url = REACT_APP_SOUTHERN_BACKEND;
        break;
      default:
        url = REACT_APP_SOUTHERN_BACKEND;
        break;
    }
    super(url);
  }

  async sendSupportTicketEmail(supportTicketParams) {
    const url = "/support-ticket/send-support-ticket";
    try {
      const response = await this._post(url, supportTicketParams);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendLossRunsEmail(lossRunsParams) {
    const url = "/lossruns/incoming-loss-runs";
    try {
      const response = await this._post(url, lossRunsParams);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createUser(data) {
    const url = "/users/create-user";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }

  async createSubusers(data) {
    const url = "/users/create-sub-user";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
  
  async createClientUsers(data) {
    const url = "/users/create-client";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }

  async createLiabilityPdf(data) {
    const url = "/waiver-of-subrogation/create-waiver-of-subrogation-pdf-complete";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async printPolicy(data) {
    const url = "/policy/print-policy";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async abortPolicyPrinting() {
    const url = "/policy/policy-generation-abortion";
    try {
      const response = await this._post(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async base64ToStorage(data) {
    const url = "/files/upload-file-to-storage";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteFileFromStorage(fileUrl) {
    const url = `/files/delete-file-from-storage/${encodeURIComponent(fileUrl)}`;
    try {
      const response = await this._delete(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailToNewCreatedUser(data) {
    const url = "/send-email/new-user-created";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async setSctInfo(data) {
    const url = "/sct/add-driver";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateSCT(data) {
    const url = "/sct";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async generateOAuth() {
    const url = "/calendar/generate-code";
    try {
      const response = await this._get(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async generateToken(url) {
    try {
      const response = await this._get(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // async getCalendarEvents(data) {
  //   const url = "/calendar/events";
  //   try {
  //     const response = await this._post(url, data);
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async createCalendarEvent(data) {
  //   const url = "/calendar/add-event";
  //   try {
  //     const response = await this._post(url, data);
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getCalendarEvents(data) {
    const url = "/calendar/events";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createCalendarEvent(data) {
    const url = "/calendar/add-event";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailPoliciesDetails(data) {
    const url = "/power-units/found-policies-email";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailPoliciesDetailsOfManyVins(data) {
    const url = "/power-units/found-policies-email-in-many-vins";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getNotificationRecipients(data) {
    const url = `/notifications-permissions/recipients?fieldName=${data}`;
    try {
      const response = await this._get(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async printEndorsement(data) {
    const url = "/endorsement/print-endorsement";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async printQuote(data) {
    const url = "/quote/print-quote";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async addNewInsured(data) {
    const url = "/safer";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessModuleUpdate(data) {
    const url = "/renewal-process/module-update-email";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessModuleUpdateConfirmation(data) {
    const url = "/renewal-process/module-update-confirmation-email";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessPolicyData(data) {
    const url = "/renewal-process/policy-data";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessDrivers(data) {
    const url = "/renewal-process/drivers";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessPowerUnits(data) {
    const url = "/renewal-process/power-units";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessTrailers(data) {
    const url = "/renewal-process/trailers";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessDriver(data) {
    const url = "/renewal-process/driver";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessDriversBlacklist() {
    const url = "/renewal-process/drivers-blacklist";
    try {
      const response = await this._post(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessUpdateDriver(data) {
    const url = "/renewal-process/update-driver";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessUpdatePowerUnit(data) {
    const url = "/renewal-process/update-power-unit";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessUpdateTrailer(data) {
    const url = "/renewal-process/update-trailer";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessCreateDriver(data) {
    const url = "/renewal-process/create-driver";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessCreatePowerUnit(data) {
    const url = "/renewal-process/create-power-unit";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessCreateTrailer(data) {
    const url = "/renewal-process/create-trailer";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessLossControl(data) {
    const url = "/renewal-process/loss-control";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessEndorsementOperations(data) {
    const url = "/renewal-process/endorsement-operations";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async renewalProcessUpdatePolicyField(data) {
    const url = "/renewal-process/update-policy-field";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async lossExperienceAnalysisExcel(data) {
    const url = "/create-information-sheet/loss-experience-analysis";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async ofacCrawling(data) {
    const url = "/ofac";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async bugReportSubmissionConfirmation(data) {
    const url = "/bug-reports/sending-email-confirming-submission";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSaferWithDotScreenshot(data) {
    const url = "/safer/dot";
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteUserWithFlag(data) {
    const url = `/users/delete-user-by-email-with-flag/${data}`;
    try {
      const response = await this._delete(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailWithRedflags(data) {
    const url = `/send-email/redflags`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailWithAddedFlag(data) {
    const url = `/send-email/red-flag-added`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async setInsuredInfo(data) {
    const url = `/renewal-process/insured`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async setPolicyInfo(data) {
    const url = `/renewal-process/policy`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getTrailersMake() {
    const url = `/renewal-process/trailer-makes`;
    try {
      const response = await this._get(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async parseFilings(data) {
    const url = `/filings/parse-filings`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async setMassiveSctToUpdateDrivers(data) {
    const url = `/sct/massive-sct-to-update-drivers`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async printDriverMap(data) {
    const url = `/drivers/print-driver-map`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async printDriverExclusion(data) {
    const url = `/drivers/print-driver-exclusion`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createCancelEventAndNoc(data) {
    const url = `/cronjob/createcancelevent-and-noc`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createCancelCancellationProcessThroughExclusionDeclination(data) {
    const url = `/cronjob/cancel-cancellation-process-through-exclusion-declination`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendEnvelopeDriverExclusion(data) {
    const url = `/docusign/sendEnvelope-driverExclusion`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async paginatedRequestsEndorsement(data) {
    const {
      filters,
      currentPage,
      totalCount,
    } = data
    const filterParam = JSON.stringify(filters)
    const currentPageParam = String(currentPage)
    const totalCountParam = String(totalCount)
    const url = `/paginated-requests/endorsements?currentPage=${currentPageParam}&totalCount=${totalCountParam}&filters=${filterParam}`;
    try {
      const response = await this._get(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendEnvelope(data) {
    const url = `/docusign/sendEnvelope`;
    try {
      const response = await this._post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default ApiService;
