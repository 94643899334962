import React, { useState } from "react";
import TextInput from "../../../assets/forms/TextInput";
import NumberInput from "../../../assets/forms/NumberInput";
import useTranslations from "../../../../Context/actions/useTranslations";

const LossDataForm = ({
  handleSubmit,
  currentYear,
  numberOfYearsPrior,
  formData,
  setFormData,
  policyID,
  year,
  coverageType
}) => {

  const initialState = formData?.lossHistory?.[`year${year + "" + coverageType}LossData`] || {}
  const [lossData, setLossData] = useState(initialState)
  const section = "lossHistory";
  const category = `year${year + "" + coverageType}LossData`;

  const { t } = useTranslations()

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(
          e,
          currentYear -
            numberOfYearsPrior -
            1 +
            " " +
            "to" +
            " " +
            (currentYear - numberOfYearsPrior),
          lossData,
          `year${year + "" + coverageType}LossData`
        );
      }}
    >
      <fieldset disabled={formData.documentType !== "Application"}>
        <TextInput
          label={t("plm.losses.al-gl.carrier") + ":"}
          required={true}
          saveForm={() => {}}
          property={"carrier"}
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category
          }}
        />
        <NumberInput
          label={t("plm.losses.al-gl.number-of-units") + ":"}
          required={true}
          saveForm={() => {}}
          property="numberOfUnits"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category
          }}
        />
        <NumberInput
          label={t("plm.losses.al-gl.premium-ppu") + ":"}
          required={true}
          saveForm={() => {}}
          property="premiumPPU"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category
          }}
        />
        <NumberInput
          label={t("plm.losses.al-gl.losses") + ":"}
          required={true}
          saveForm={() => {}}
          property="losses"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category
          }}
        />
        <NumberInput
          label={t("plm.losses.al-gl.open-bi-claims") + ":"}
          required={true}
          saveForm={() => {}}
          property="openBIClaims"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category
          }}
        />
        <NumberInput
          label={t("plm.losses.al-gl.open-at-fault-bi-claims") + ":"}
          required={true}
          saveForm={() => {}}
          property="openAtFaultBIClaims"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category
          }}
        />
        <NumberInput
          label={t("plm.losses.al-gl.total-open-claims") + ":"}
          required={true}
          saveForm={() => {}}
          property="totalOpenClaims"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category
          }}
        />
        <button className="submit-button" type="submit">
          {t("plm.losses.al-gl.calculate-loss-ratio")}
        </button>
      </fieldset>
    </form>
  );
};

export default LossDataForm;
