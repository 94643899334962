import React from "react";

export default function ButtonInstructions({ isRequestException }) {
  return (
    <article className="my-4">
      <p
        className={
          isRequestException
            ? "text-red-700 text-center !max-w-[345px] mx-auto"
            : ""
        }
      >
        {isRequestException
          ? `This endorsement has already been declined. 
                    You are accepting/decling an exception request`
          : `Approving this endorsement will add the above Power Unit(s) to
                    the policy and notify the insured of the change.`}
      </p>
    </article>
  );
}
