const getTotals = (trucks, policyID) => {
    let GWP = 0;
    let otherFees = 0;
    let processingFeeAmount = 0;
    let stampTaxAmount = 0;
    let surplusLinesTaxAmount = 0;
    let totalPremium = 0;
    trucks.forEach((truck) => {
      const policyInfo = truck.policiesDetails.filter((policy) => {
        return policy.id === policyID;
      });
      GWP += policyInfo[0].adjustedRate;
      processingFeeAmount += policyInfo[0].taxes.processingFee;
      stampTaxAmount += policyInfo[0].taxes.stampFee;
      surplusLinesTaxAmount += policyInfo[0].taxes.surplusLinesTax;
      totalPremium += policyInfo[0].totalPremiumAfterTaxes;
    });
    return {
      GWP,
      otherFees,
      processingFeeAmount,
      stampTaxAmount,
      surplusLinesTaxAmount,
      totalPremium,
    };
  };

export default getTotals;