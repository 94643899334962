import React, { useState, useEffect } from 'react';

const ProgressLoader = ({ percentage, error }) => {
  const [loadingPercentage, setLoadingPercentage] = useState(percentage);
  const [hasError, setHasError] = useState(error);

  useEffect(() => {
    setLoadingPercentage(percentage);
  }, [percentage]);

  useEffect(() => {
    setHasError(error);
  }, [error]);

  const loadingTheme = {
    text: 'text-[#072a48]',
    container: 'bg-gray-200',
    line: 'bg-[#072a48]'
  };

  const errorTheme = {
    text: 'text-[#8B0000]',
    container: 'bg-red-100',
    line: 'bg-[#8B0000]'
  };

  const theme = hasError ? errorTheme : loadingTheme;
  const text =
    hasError ? 'There was an error' :
    loadingPercentage === 0 ? `${loadingPercentage}% if after 30 seconds does not progress, cancel the request and print it again` :
    `${loadingPercentage}%`;

  return (
    <>
      {typeof percentage !== 'undefined' ?
        <>
          <div className="flex">
            {!hasError && <p className="mb-1">Progress:&nbsp;</p>}
            <p className={theme.text}>{text}</p>
          </div>
          <div className="relative pt-1">
            <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded ${theme.container}`}>
              <div
                style={{
                  width: `${loadingPercentage}%`,
                }}
                className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${theme.line}`}
              />
            </div>
          </div>
        </> : null
      }
    </>
  );
};

export default ProgressLoader;