import React, { useEffect, useState } from "react";
import usePoliciesPdfState from "../../../Context/actions/usePoliciesPdfState";
import {
  domesticAlPolicyPayload,
  domesticGlPolicyPayload,
  naftaAlPolicyPayload,
  naftaGlPolicyPayload
} from "../../../utils/policiesPdfPayloads";
import { useMediaQuery } from "@mui/material";
import MenuBar from "./MenuBar";
import excludeEmptyStrings from "../../utils/excludeEmptyStrings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import GavelIcon from "@mui/icons-material/Gavel";
import ReplyIcon from "@mui/icons-material/Reply";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import PrintIcon from "@mui/icons-material/Print";
import AssignmentIcon from "@mui/icons-material/Assignment";
import bindPolicy from "../../utils/bindPolicy";
import PrintOptionsModal from "../PrintOptionsModal";
import RequestBindModal from "../RequestBindModal";
import RenewPolicyModal from "../RenewPolicyModal";
import returnToAgent from "../../utils/returnToAgent";
import ReasonsModal from "../ReturnToAgentModal";
import RequestQuoteModal from "../RequestQuoteModal";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import SendQuoteModal from "../SendQuoteModal";
import { HistoryEdu } from "@mui/icons-material";
import useFormDataState from "../../../Context/actions/useFormData";
import { collection, doc, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";
import BindPolicyModal from "../BindPolicyModal";
import RequestSignaturesModal from "../RequestSignaturesModal";
import useTranslations from "../../../Context/actions/useTranslations";

const returnToAgentFn = returnToAgent;

const ActionButtons = ({
  isAgentOrSubUser,
  formSaved,
  isAdminOrUnderwriter,
  isQuoteRequest,
  isApplication,
  isPolicy,
  isSubmitted,
  isFulfilled,
  isSubmittedOrFulfilled,
  isInCancellation,
  isMissingAtLeastOneCoverage,
  formData,
  insuredInfo,
  driverList,
  truckList,
  trailerList,
  returnToAgent,
  setDisplayEndorsementManager,
  isBindOrder,
  printPolicy,
  printPolicyProgress,
  policyID,
  setDisplayUnderwritingPanel,
  handleOpenRequestQuoteModal,
  handleCloseRequestQuoteModal,
  openRequestQuoteModal,
  handleOpenRenewPolicyModal,
  handleCloseRenewPolicyModal,
  openRenewPolicyModal,
  handleOpenReturnToAgentModal,
  handleCloseReturnToAgentModal,
  openReturnToAgentModal,
  openBindPolicyModal,
  handleOpenBindPolicyModal,
  handleCloseBindPolicyModal,
  openRequestBindModal,
  handleCloseRequestBindModal,
  handleOpenRequestBindModal,
  openPrintOptionsModal,
  handleOpenPrintOptionsModal,
  handleClosePrintOptionsModal,
  openSendQuoteModal,
  handleOpenSendQuoteModal,
  openRequestSignaturesModal,
  handleOpenRequestSignaturesModal,
  handleCloseRequestSignaturesModal,
  handleCloseSendQuoteModal,
  powerUnitCount,
  driverCount,
  trailerCount,
  userInfo,
  agencyInfo,
  nextAL,
  nextGL,
  insuredID,
  eligibleForRenewal,
}) => {
  const { percentage } = printPolicyProgress;
  const { policiesPdf } = usePoliciesPdfState();
  const { formDataGL } = useFormDataState();

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const isNotRenewal = formData.status !== "In-Renewal";

  const { t } = useTranslations()
  const { user: userAuth } = UserAuth();
  const { user: userState } = useUsersState();

  const buttonIcons = [
    // Request Quote
    isApplication ? <RequestQuoteIcon /> : "",
    // Endorsements
    isPolicy ? <PlaylistAddCheckIcon /> : "",
    // Renewal Options
    eligibleForRenewal && isNotRenewal && isPolicy ? <AutorenewIcon /> : "",
    // Request Signatures
    isAdminOrUnderwriter && isSubmitted ? <AssignmentIcon /> : "",
    // Send Quotes 
    isAdminOrUnderwriter && isSubmittedOrFulfilled ? <AttachEmailIcon /> : "",
    // Request Bind
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest ? (
      <GavelIcon />
    ) : (
      ""
    ),
    // Return To Agent
    isSubmittedOrFulfilled ? <ReplyIcon /> : "",
    // Bind Policy
    isSubmittedOrFulfilled &&
    isAdminOrUnderwriter &&
    formData.documentType === "Bind Order" ? (
      <LibraryAddCheckIcon />
    ) : (
      ""
    ),
    // Send Letters
    isAdminOrUnderwriter && isPolicy ? <HistoryEdu /> : "",
    // Print Policy
    isPolicy && formData.hasALCoverage ? <PrintIcon /> : "",
  ];

  const signatureStatus = formData?.signedProposalData?.status || "";
  const buttonLabels = [
    isApplication ? t('plm.navbar.desktop.request-quote') : "",
    isPolicy ? t('plm.navbar.desktop.endorsements') : "",
    eligibleForRenewal && isNotRenewal && isPolicy ? t('plm.navbar.desktop.renewal-options') : "",
    isAdminOrUnderwriter && isSubmitted
      ? signatureStatus === "sent"
        ? t('plm.navbar.desktop.signature-requested')
        : signatureStatus === "complete"
        ? t('plm.navbar.desktop.signature-complete')
        : t('plm.navbar.desktop.request-signatures')
      : "",
    isAdminOrUnderwriter && isSubmittedOrFulfilled ? t('plm.navbar.desktop.send-quotes') : "",
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest
      ? t('plm.navbar.desktop.request-bind')
      : "",
    isSubmittedOrFulfilled ? t('plm.navbar.desktop.return-to-agent') : "",
    isSubmittedOrFulfilled &&
    isAdminOrUnderwriter &&
    formData.documentType === "Bind Order"
      ? t('plm.navbar.desktop.bind-policy')
      : "",
    isAdminOrUnderwriter && isPolicy ? t('plm.navbar.desktop.send-letters') : "",
    isPolicy && formData.hasALCoverage ? t('plm.navbar.desktop.print-policy') : "",
  ];

  const onClickFunctions = [
    // Request Quote
    isApplication ? (e) => handleOpenRequestQuoteModal() : "",
    // Endorsements
    isPolicy ? () => setDisplayEndorsementManager(true) : "",
    // Renewal Options
    eligibleForRenewal && isPolicy && isNotRenewal
      ? (e) => handleOpenRenewPolicyModal()
      : "",
    // Request Signatures
    isAdminOrUnderwriter && isSubmitted
      ? () => handleOpenRequestSignaturesModal()
      : "",
    // Send Quotes
    isAdminOrUnderwriter && isSubmittedOrFulfilled ? () => handleOpenSendQuoteModal() : "",
    // Request Bind
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest
      ? () => handleOpenRequestBindModal()
      : "",
    // Return To Agent
    isSubmittedOrFulfilled ? (e) => returnToAgent(e) : "",
    // Bind Policy
    isSubmittedOrFulfilled &&
    isAdminOrUnderwriter &&
    formData.documentType === "Bind Order"
      ? () => handleOpenBindPolicyModal()
      : "",
    // Send Letters
    isAdminOrUnderwriter && isPolicy
      ? () => setDisplayUnderwritingPanel(true)
      : "",
    // Print Policy
    isPolicy && formData.hasALCoverage
      ? (e) => handleOpenPrintOptionsModal()
      : "",
  ];

  const handleSubmit = async (e, reasons) => {
    try {
      await returnToAgentFn(
        e,
        policyID,
        reasons,
        formData,
        userAuth,
        userState,
        insuredInfo,
        userInfo
      );
    } catch (error) {
      throw error;
    }
  };

  const [quote, setQuote] = useState(null);

  useEffect(() => {
    if (!formData?.selectedQuote) return () => {};
    const quoteDocRef = doc(
      db,
      "policy-applications",
      policyID,
      "quotes",
      formData?.selectedQuote
    );
    const unsubscribe = onSnapshot(quoteDocRef, (docSnapshot) => {
      if (docSnapshot.exists) {
        setQuote({
          ...docSnapshot.data(),
          id: docSnapshot.id,
        });
      } else {
        console.log("No such document!");
        setQuote(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [formData?.selectedQuote]);

  if (isMobileScreen) {
    return (
      <>
        <ReasonsModal
          title="Reasons for Return"
          text="Please enter the reason(s) why the application is being returned to the agent."
          addButtonText="Add A Reason"
          onSubmit={handleSubmit}
          open={openReturnToAgentModal}
          onClose={handleCloseReturnToAgentModal}
        />
        <RenewPolicyModal
          open={openRenewPolicyModal}
          onClose={handleCloseRenewPolicyModal}
          policyID={policyID}
          formData={formData}
          insuredInfo={insuredInfo}
          driverList={driverList}
          truckList={truckList}
          trailerList={trailerList}
        />
        <RequestQuoteModal
          open={openRequestQuoteModal}
          onClose={handleCloseRequestQuoteModal}
          policyID={policyID}
          formData={formData}
          insuredInfo={insuredInfo}
        />
        <SendQuoteModal
          open={openSendQuoteModal}
          onClose={handleCloseSendQuoteModal}
          policyID={policyID}
          formData={formData}
          insuredInfo={insuredInfo}
          selectedQuote={quote}
        />
        <RequestSignaturesModal
          signatureStatus={signatureStatus}
          open={openRequestSignaturesModal}
          onClose={handleCloseRequestSignaturesModal}
          policyID={policyID}
          formData={formData}
          insuredInfo={insuredInfo}
          selectedQuote={quote}
        />
        <PrintOptionsModal
          insuredInfo={insuredInfo}
          formData={formData}
          open={openPrintOptionsModal}
          onClose={handleClosePrintOptionsModal}
          policiesPdf={policiesPdf}
          percentage={percentage}
          setDidSuccess={() => {}}
          printPolicy={(e, option) => {
            const determinedType =
              option.includes("NaftaAL") ?
              "NAFTA AL" :
              option.includes("DomesticAL") ?
              "Domestic AL" :
              option.includes("NaftaGL") ?
              "NAFTA GL" :
              "Domestic GL";
            const fnPayload =
              option.includes("NaftaAL") ?
              naftaAlPolicyPayload :
              option.includes("DomesticAL") ?
              domesticAlPolicyPayload :
              option.includes("NaftaGL") ?
              naftaGlPolicyPayload :
              domesticGlPolicyPayload
            printPolicy(
              e,
              determinedType,
              fnPayload(formData, insuredInfo, driverList, agencyInfo),
              option
            )
          }}
        />
      </>
    );
  }

  console.log('percentage', percentage)

  return (
    <div>
      <MenuBar
        icons={excludeEmptyStrings(buttonIcons)}
        labels={excludeEmptyStrings(buttonLabels)}
        onClickFunctions={excludeEmptyStrings(onClickFunctions)}
      />
      <ReasonsModal
        title="Reasons for Return"
        text="Please enter the reason(s) why the application is being returned to the agent."
        addButtonText="Add A Reason"
        onSubmit={handleSubmit}
        open={openReturnToAgentModal}
        onClose={handleCloseReturnToAgentModal}
      />
      <RenewPolicyModal
        open={openRenewPolicyModal}
        formData={formData}
        onClose={handleCloseRenewPolicyModal}
        policyID={policyID}
        insuredInfo={insuredInfo}
        driverList={driverList}
        truckList={truckList}
        trailerList={trailerList}
      />
      <RequestQuoteModal
        open={openRequestQuoteModal}
        onClose={handleCloseRequestQuoteModal}
        policyID={policyID}
        formData={formData}
        insuredInfo={insuredInfo}
      />
      <SendQuoteModal
        open={openSendQuoteModal}
        onClose={handleCloseSendQuoteModal}
        policyID={policyID}
        formData={formData}
        insuredInfo={insuredInfo}
        selectedQuote={quote}
      />
      <RequestSignaturesModal
        signatureStatus={signatureStatus}
        open={openRequestSignaturesModal}
        onClose={handleCloseRequestSignaturesModal}
        policyID={policyID}
        formData={formData}
        insuredInfo={insuredInfo}
        selectedQuote={quote}
      />
      <RequestBindModal
        open={openRequestBindModal}
        onClose={handleCloseRequestBindModal}
        policyID={policyID}
        formData={formData}
        insuredInfo={insuredInfo}
      />
      <BindPolicyModal
        open={openBindPolicyModal}
        onClose={handleCloseBindPolicyModal}
        policyID={policyID}
        formData={formData}
        bindFunction={() =>
          bindPolicy(
            formData,
            policyID,
            powerUnitCount,
            driverCount,
            trailerCount,
            userInfo,
            insuredInfo,
            nextAL,
            nextGL,
            insuredID,
            userAuth,
            userState,
            formDataGL
          )
        }
        insuredInfo={insuredInfo}
      />
      <PrintOptionsModal
        formData={formData}
        userInfo={userInfo}
        insuredInfo={insuredInfo}
        open={openPrintOptionsModal}
        onClose={handleClosePrintOptionsModal}
        policiesPdf={policiesPdf}
        percentage={percentage}
        setDidSuccess={() => {}}
        printPolicy={(e, option) => {
          const determinedType =
            option.includes("NaftaAL") ?
            "NAFTA AL" :
            option.includes("DomesticAL") ?
            "Domestic AL" :
            option.includes("NaftaGL") ?
            "NAFTA GL" :
            "Domestic GL";
          const fnPayload =
            option.includes("NaftaAL") ?
            naftaAlPolicyPayload :
            option.includes("DomesticAL") ?
            domesticAlPolicyPayload :
            option.includes("NaftaGL") ?
            naftaGlPolicyPayload :
            domesticGlPolicyPayload
          printPolicy(
            e,
            determinedType,
            fnPayload(formData, insuredInfo, driverList, agencyInfo),
            option
          )
        }}
      />
    </div>
  );
};

export default ActionButtons;
