import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

export default function UserListCheckbox({
  field = 'hasALCoverage',
  name = 'userIsAgent',
  disabled = false,
  userData
}) {
  const updateUserIsAgent = async (userData) => {
    try {
      const userRef = doc(db, "users", userData.id);
      await updateDoc(userRef, {
        [name]: !userData[name],
      });
      console.log("Permissions and endorsements updated successfully!");
    } catch (error) {
      console.error("Error updating permissions and endorsements: ", error);
    }
  };
  return (
    <div className="flex items-center my-2">
      <input
        id={field}
        name={name}
        type="checkbox"
        className="large-checkbox mx-auto"
        checked={userData[name]}
        onChange={(e) => {
          updateUserIsAgent(userData);
        }}
        disabled={disabled}
      />
    </div>
  );
}
