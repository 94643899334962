import React from "react";
import { Td, Tr } from "react-super-responsive-table";
import QuillModal from "../../../../QuillModal";
import { Button, ButtonGroup, Grid } from "@mui/material";
import BlueButton from "../../../../assets/forms/BlueButton";
import {
  CheckCircle as CheckCircleIcon,
  Visibility as VisibilityIcon,
  HourglassBottom as HourglassBottomIcon,
} from "@mui/icons-material";
import useTranslations from "../../../../../Context/actions/useTranslations";
import { formatAmericanDate } from "../../../../../utils/driversPayload";

const OfacReview = ({
  task,
  formData,
  index,
  handleUpload,
  setFiles,
  isOpen,
  setIsOpen,
  handleOFAC,
  ofacExists,
  setOfacResultsModal,
  insuredInfo,
  ofacWasRequested,
  ofacLoading,
}) => {

  const { t, timezone } = useTranslations();

  return (
    <>
      <Tr key={"otherLossRun" + index}>
        <Td valign="top">
          <p className="my-1">
            {formData.underwritingTasks?.[task.description]?.completed
              ? t("plm.tasks.ofac.complete")
              : t("plm.tasks.ofac.incomplete")}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">
            {task.cabURL ? (
              <a
                className="text-blue"
                target="_blank"
                rel="noreferrer"
                href={task.cabURL + insuredInfo.dot}
              >
                {task.description}
              </a>
            ) : (
              <>{task.description}</>
            )}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">{formatAmericanDate(task.dueDate, timezone)}</p>
        </Td>
        <Td valign="top">
          {task.type === "note" && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <QuillModal
                        handleUpload={handleUpload}
                        isOpen={isOpen[task.description] || false}
                        setIsOpen={(state) =>
                          setIsOpen((prev) => ({
                            ...prev,
                            [task.description]: state,
                          }))
                        }
                        label={task.description}
                        setFile={(files) =>
                          setFiles((prev) => ({
                            ...prev,
                            [task.description]: files,
                          }))
                        }
                        taskLocation={
                          formData.underwritingTasks?.[task.description]
                            ?.note || ""
                        }
                        hideButtonLabel={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <BlueButton
                    text={
                      ofacLoading
                        ? t("plm.tasks.ofac.getting")
                        : formData?.underwritingTasks?.[task.description]
                            ?.completed
                        ? t("plm.tasks.ofac.update")
                        : !ofacWasRequested
                        ? t("plm.tasks.ofac.run-ofac")
                        : t("plm.tasks.ofac.re-run-ofac")
                    }
                    onClickFunction={(e) => {
                      const existingTaskList =
                        formData?.underwritingTasks || {};
                      const newTaskList = {
                        ...existingTaskList,
                        [task.description]: {
                          completed: true,
                        },
                      };
                      handleOFAC(e, newTaskList);
                    }}
                    icon={
                      ofacLoading ? (
                        <HourglassBottomIcon />
                      ) : (
                        <CheckCircleIcon />
                      )
                    }
                    disabled={ofacLoading}
                    className={"w-full"}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {ofacExists ? (
                    <>
                      <ButtonGroup
                        fullWidth
                        disableElevation
                        variant="contained"
                      >
                        <Button
                          onClick={() => setOfacResultsModal(true)}
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            flexGrow: 1,
                            marginTop: "8px",
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                            marginBottom: "8px",
                          }}
                          startIcon={<VisibilityIcon />}
                        >
                          {t("plm.tasks.ofac.view-ofac-results")}
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default OfacReview;
