  import React, { useState, useEffect } from "react";
  import { UserAuth } from "../../Context/AuthContent";
  import { useParams } from "react-router-dom";
  import { doc, onSnapshot, updateDoc } from "firebase/firestore";
  import { db, auth, storage } from "../../firebase";
  import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
  import { getDoc } from "firebase/firestore";
  import Box from "@mui/material/Box";
  import Modal from "@mui/material/Modal";
  import "../styling/app.css";
  import SubmissionHistory from "../toRetireOrReplace/submissions/SubmissionHistory";
  import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
  import axios from "axios";
  import { Grid, Row, Col } from "react-flexbox-grid";
  import useUsersState from "../../Context/actions/useUsersState";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function AgentProfile() {
    let { paramAgentID, paramAgencyID } = useParams();

    // Modal Stuff
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      if (!agentProfile.phoneInterviewComplete)
        return alert(
          "You must complete the phone interview before accepting or declining the agent."
        );
      else setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const { user } = useUsersState();
    const userID = paramAgentID;
    const agencyID = paramAgencyID;

    const [submittedCount, setSubmittedCount] = useState(0);
    const [unsubmittedCount, setUnsubmittedCount] = useState(0);

    const [agentProfile, setAgentProfile] = useState({});
    const [gotProfile, setGotProfile] = useState(false);
    const [noProfile, setNoProfile] = useState(false);
    
    useEffect(() => {
      const docRef = doc(db, "agencies", agencyID);
    
      // Set up a real-time listener for the agency profile document
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setAgentProfile(docSnap.data());
          setGotProfile(true);
          setNoProfile(false);
    
          // Set URLs for the contract and W9 form
          setContractURL(docSnap.data()?.contractSigned?.downloadURL || "");
          setW9URL(docSnap.data()?.W9Complete?.downloadURL || "");
        } else {
          console.log("No such document! User must create a profile to proceed.");
          setNoProfile(true);
        }
      });
    
      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }, [agencyID]); // Run the effect whenever agencyID changes    

    const [applicationData, setApplicationData] = useState({});
    const [gotApplicationData, setGotApplicationData] = useState(false);
    
    useEffect(() => {
      const docRef = doc(db, "appointment-applications", userID);
    
      // Set up a real-time listener for the user application data
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        setGotApplicationData(false); // Reset state before processing new data
    
        if (docSnap.exists()) {
          setApplicationData(docSnap.data());
          setGotApplicationData(true);
          console.log("Got User Application Data");
        } else {
          console.log("No application exists for this user!");
        }
      });
    
      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }, [userID]); // Run the effect whenever userID changes

    const convertDate = (secs) => {
      const output = new Date(secs * 1000);
      return output.toString().slice(3, 15);
    };

    const acceptAgent = async (agentID) => {
      if (!agentProfile.phoneInterviewComplete)
        return alert(
          "You must complete the phone interview before accepting or declining the agent."
        );
      const agentRef = doc(db, "users", agentID);
      await updateDoc(agentRef, {
        nextStep: "Review Contract",
        status: "Accepted",
        agentAccepted: {
          status: "Accepted",
          when: new Date(),
          by: auth.currentUser.displayName,
        },
      });
      axios
        .post(
          "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/ssmga-accepted-email",
          {
            to: agentProfile.email,
            cc: "colt@southernstarmga.com",
          }
        )
        .catch((e) => console.log(e));
      handleClose();
      
    };

    const declineAgent = async (agentID) => {
      if (!agentProfile.phoneInterviewComplete)
        return alert(
          "You must complete the phone interview before accepting or declining the agent."
        );
      const agentRef = doc(db, "users", agentID);
      await updateDoc(agentRef, {
        nextStep: "N/A",
        status: "Declined",
        agentAccepted: {
          status: "Declined",
          when: new Date(),
          by: auth.currentUser.displayName,
        },
      });
      axios
        .post(
          "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/ssmga-declined-email",
          {
            to: agentProfile.email,
            cc: "colt@southernstarmga.com",
          }
        )
        .catch((e) => console.log(e));
      handleClose();
      
    };

    const markApplicationReviewed = async (agentID) => {
      const agentRef = doc(db, "users", agentID);
      const applicationRef = doc(db, "appointment-applications", agentID);
      await updateDoc(agentRef, {
        nextStep: "Phone Interview",
        applicationReviewed: {
          when: new Date(),
          by: auth.currentUser.displayName,
        },
      });
      await updateDoc(applicationRef, {
        status: "Approved",
      });
      axios
        .post(
          "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/ssmga-approved-email",
          {
            to: agentProfile.email,
            cc: "colt@southernstarmga.com",
          }
        )
        .catch(function (error) {
          console.log("Error: " + error);
        });
      setGotProfile(false);
      
    };

    const markInterviewComplete = async (agentID) => {
      if (!agentProfile.applicationReviewed)
        return alert(
          "You must mark the application review as complete before you can mark the phone interview as complete."
        );
      const agentRef = doc(db, "users", agentID);
      await updateDoc(agentRef, {
        nextStep: "Accept or Decline",
        phoneInterviewComplete: {
          when: new Date(),
          by: auth.currentUser.displayName,
        },
      });
      setGotProfile(false);
      
    };

    const markContractApproved = async (agentID) => {
      if (
        !agentProfile.contractSigned ||
        agentProfile?.contractSigned?.downloadURL === ""
      ) {
        return alert("You must upload the contract before approving it.");
      }

      if (
        !agentProfile.W9Complete ||
        agentProfile?.W9Complete?.downloadURL === ""
      ) {
        return alert("You must upload the W9 before approving the contract.");
      }

      if (!agentProfile.agentAccepted) {
        return alert(
          "You must accept the agent before you can mark the contract as approved."
        );
      }

      const agentRef = doc(db, "users", agentID);
      await updateDoc(agentRef, {
        nextStep: "Grant Tier I Appointment",
        contractApproved: {
          when: new Date(),
          by: auth.currentUser.displayName,
        },
      });
      setGotProfile(false);
      
    };

    const grantTier1Appointment = async (agentID) => {
      if (!agentProfile.contractApproved)
        return alert(
          "You must approve the contract before granting a tier 1 appointment."
        );
      const agentRef = doc(db, "users", agentID);
      await updateDoc(agentRef, {
        nextStep: "Grant Tier 2 Appointment",
        status: "Appointed",
        tier: 1,
        tier1granted: {
          when: new Date(),
          by: auth.currentUser.displayName,
        },
      });
      axios
        .post(
          "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/ssmga-tier-1-email",
          {
            to: agentProfile.email,
            cc: "colt@southernstarmga.com",
          }
        )
        .catch((e) => console.log(e));
      setGotProfile(false);
      
    };

    const updateContractDownloadURL = async (agentID, URL) => {
      const agentRef = doc(db, "users", agentID);
      await updateDoc(agentRef, {
        contractSigned: {
          downloadURL: URL,
        },
      });
      setGotProfile(false);
      
    };

    const updateW9DownloadURL = async (agentID, URL) => {
      const agentRef = doc(db, "users", agentID);
      await updateDoc(agentRef, {
        W9Complete: {
          downloadURL: URL,
        },
      });
      setGotProfile(false);
      
    };

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);
    const [contractURL, setContractURL] = useState("");
    const [W9URL, setW9URL] = useState("");
    const handleSubmit = (e, changer, callback) => {
      e.preventDefault();
      const file = e.target[0]?.files[0];

      if (!file) return;

      const storageRef = ref(
        storage,
        `files/agent-contracts/${agentProfile.company + "-" + file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgresspercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL);
            changer(downloadURL);
            callback(userID, downloadURL);
          });
        }
      );
    };

    return (
      <>
        {user.role === "Admin" || user.role === "Underwriter" ? (
          <>
            {noProfile === true && (
              <>Agent profile does not exist. Please contact an administrator.</>
            )}
            {noProfile === false && (
              <>
                <div>
                  {/* User Info */}
                  <div className="card  my-3">
                    <div className="card-header">
                      <h1 className="text-2xl font-bold">Agent Profile:</h1>
                    </div>
                    <div className="card-body bg-gray-200">
                      <Grid fluid id="user-Info">
                        <Row>
                          <Col>
                            <p>
                              Here you may view all the details about an agent,
                              update their appointment status, and access all
                              steps in the appointment process.
                            </p>
                          </Col>
                        </Row>
                        <Row className="border-2 p-4 bg-gray-200">
                          <Col sm="12" md="4" className="pb-1">
                            <h4 className="text-lg font-medium">
                              {agentProfile?.name}
                            </h4>
                            <h4 className="text-lg font-medium mt-2">
                              {agentProfile?.primaryContact}
                            </h4>
                            <h4 className="text-lg font-medium mt-2">
                              {agentProfile?.primaryContactEmail}
                            </h4>
                            <h4 className="text-lg font-medium">
                              {agentProfile?.phoneNumber}
                            </h4>
                            <h4 className="text-lg font-medium">
                              {agentProfile?.mailingAddress}
                            </h4>
                            <h4 className="text-lg font-medium">
                              {agentProfile?.city}
                              {agentProfile?.city &&
                                agentProfile.city !== "" && <>, </>}{" "}
                              {agentProfile?.state} {agentProfile?.zip}
                            </h4>
                            <h4 className="text-lg font-medium mt-2">
                              Status: {applicationData?.status || "Not Started"}
                            </h4>
                            {agentProfile.tier && (
                              <h4 className="text-lg font-medium">
                                Tier: {agentProfile?.tier}
                              </h4>
                            )}
                            <h4 className="text-lg font-medium">
                              Next Step: {agentProfile?.nextStep}
                            </h4>

                            <div>
                              <h4 className="text-lg font-medium mt-3">
                                Contract:
                              </h4>
                              {contractURL !== "" ? (
                                <div>
                                  File Uploaded, you may{" "}
                                  <a
                                    className="text-[#072a48]"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={contractURL}
                                  >
                                    View
                                  </a>{" "}
                                  or{" "}
                                  <button
                                    className="text-[#8B0000]"
                                    onClick={(e) => setContractURL("")}
                                  >
                                    Delete
                                  </button>{" "}
                                  the file.
                                </div>
                              ) : (
                                <form
                                  onSubmit={(e) =>
                                    handleSubmit(
                                      e,
                                      setContractURL,
                                      updateContractDownloadURL
                                    )
                                  }
                                  className="form"
                                >
                                  <input type="file" />
                                  <button
                                    type="submit"
                                    className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold"
                                  >
                                    Upload
                                  </button>
                                </form>
                              )}
                            </div>
                            <div>
                              <h4 className="text-lg font-medium mt-3">W9:</h4>
                              {W9URL !== "" ? (
                                <div>
                                  File Uploaded, you may{" "}
                                  <a
                                    className="text-[#072a48]"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={W9URL}
                                  >
                                    View
                                  </a>{" "}
                                  or{" "}
                                  <button
                                    className="text-[#8B0000]"
                                    onClick={(e) => setW9URL("")}
                                  >
                                    Delete
                                  </button>{" "}
                                  the file.
                                </div>
                              ) : (
                                <form
                                  onSubmit={(e) =>
                                    handleSubmit(e, setW9URL, updateW9DownloadURL)
                                  }
                                  className="form"
                                >
                                  <input type="file" />
                                  <button
                                    type="submit"
                                    className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold"
                                  >
                                    Upload
                                  </button>
                                </form>
                              )}
                            </div>
                          </Col>

                          <Col sm="12" md="8" className="pb-1">
                            <h3 className="text-xl font-bold text-[#072a48] mb-2">
                              Appointment Steps:
                            </h3>

                            {agentProfile.applicationReviewed ? (
                              <>
                                <button
                                  disabled
                                  className="border w-full block px-6 py-2 mt-2 mr-5 text-center bg-green-400 text-black rounded "
                                >
                                  Application Review (Complete)
                                </button>
                                <p className="text-sm mb-2">
                                  Completed on:{" "}
                                  {convertDate(
                                    agentProfile.applicationReviewed.when.seconds
                                  )}
                                  {" by "}
                                  {agentProfile.applicationReviewed.by} {"("}
                                  <a
                                    href={
                                      "/appointment-application/" + userID
                                    }
                                    className="text-[#072a48]"
                                  >
                                    View Application
                                  </a>
                                  {")"}
                                </p>
                              </>
                            ) : (
                              <>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={"/view-application/" + userID}
                                  className="border w-full block px-6 py-2 mt-2 mr-5 text-center bg-white text-[#072a48] rounded "
                                >
                                  Review Application
                                </a>
                                <button
                                  onClick={(e) =>
                                    markApplicationReviewed(userID)
                                  }
                                  className="text-sm mb-2 text-[#072a48]"
                                >
                                  Mark as Complete
                                </button>
                              </>
                            )}

                            {agentProfile.phoneInterviewComplete ? (
                              <>
                                <button
                                  disabled
                                  className="border w-full block px-6 py-2 mr-5 text-center bg-green-400 text-black rounded"
                                >
                                  Phone Interview (Complete)
                                </button>
                                <p className="text-sm mb-2">
                                  Completed on:{" "}
                                  {convertDate(
                                    agentProfile.phoneInterviewComplete.when
                                      .seconds
                                  )}{" "}
                                  {" by "}{" "}
                                  {agentProfile.phoneInterviewComplete.by}
                                </p>
                              </>
                            ) : (
                              <>
                                <a
                                  href={"tel:" + agentProfile.phone}
                                  className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded"
                                >
                                  Phone Interview
                                </a>
                                <button
                                  onClick={(e) =>
                                    markInterviewComplete(userID)
                                  }
                                  className="text-sm text-[#072a48]"
                                >
                                  Mark Phone Interview as Complete
                                </button>
                              </>
                            )}

                            {/* If Agent Accepted, show that on button */}
                            {agentProfile.agentAccepted ? (
                              <>
                                <button
                                  disabled
                                  onClick={handleOpen}
                                  className="mt-3 border w-full block px-6 py-2 mr-5 text-center bg-green-400 text-black rounded mb-1"
                                >
                                  Accept or Decline Agent (Complete)
                                </button>
                                <p className="text-sm mb-2">
                                  Agent Was {agentProfile.agentAccepted.status} on{" "}
                                  {convertDate(
                                    agentProfile.agentAccepted.when.seconds
                                  )}{" "}
                                  by {agentProfile.agentAccepted.by}
                                </p>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={handleOpen}
                                  className="mt-3 border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                                >
                                  Accept or Decline Agent
                                </button>
                              </>
                            )}

                            {agentProfile?.contractSigned ? (
                              <>
                                {/* If no download URL, show Upload Button */}
                                {agentProfile?.contractSigned?.downloadURL ===
                                "#" ? (
                                  <>
                                    <button
                                      disabled
                                      className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                                    >
                                      Final Review (Contract Signed but Not
                                      Uploaded)
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    {agentProfile?.contractApproved ? (
                                      <>
                                        <button
                                          disabled
                                          className="border w-full block px-6 py-2 mr-5 text-center bg-green-400 text-black rounded mb-1"
                                        >
                                          Final Review (Complete)
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            agentProfile?.contractSigned
                                              ?.downloadURL
                                          }
                                          className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                                        >
                                          Final Review (Contract Ready)
                                        </a>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <button
                                  disabled
                                  className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                                >
                                  Final Review (Contract Not Signed)
                                </button>
                              </>
                            )}
                            {agentProfile.contractApproved ? (
                              <p className="text-sm mb-2">
                                Contract approved on{" "}
                                {convertDate(
                                  agentProfile.contractApproved.when.seconds
                                )}{" "}
                                by {agentProfile.contractApproved.by}
                              </p>
                            ) : (
                              <>
                                <button
                                  onClick={(e) =>
                                    markContractApproved(userID)
                                  }
                                  className="text-sm mb-2 text-[#072a48]"
                                >
                                  Mark Contract as Approved
                                </button>
                              </>
                            )}

                            {agentProfile.tier1granted ? (
                              <>
                                <button
                                  onClick={(e) =>
                                    grantTier1Appointment(userID)
                                  }
                                  className="border w-full block px-6 py-2 mr-5 text-center bg-green-400 text-black rounded mb-1"
                                >
                                  Grant Tier 1 Appointment (Complete)
                                </button>
                                <p className="text-sm mb-2">
                                  Appointment granted on{" "}
                                  {convertDate(
                                    agentProfile.tier1granted.when.seconds
                                  )}{" "}
                                  by {agentProfile.tier1granted.by}
                                </p>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={(e) =>
                                    grantTier1Appointment(userID)
                                  }
                                  className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                                >
                                  Grant Tier 1 Appointment
                                </button>
                              </>
                            )}
                            <button
                              disabled
                              className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                            >
                              Grant Tier 2 Appointment
                            </button>
                            <button
                              disabled
                              className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                            >
                              Revoke Appointment
                            </button>
                            <h3 className="text-xl font-bold text-[#072a48] mt-3 mb-2">
                              Automated Follow Ups:
                            </h3>
                            <p>
                              To send a follow up select a template below and
                              click send.
                            </p>
                            <select className="form-input w-full rounded my-3">
                              <option value="">Choose One</option>
                              <option>Incomplete Application</option>
                              <option>Unsubmitted Application</option>
                              <option>Contract & W9 Incomplete</option>
                              <option>Contract Incomplete</option>
                              <option>W9 Incomplete</option>
                            </select>
                            <button
                              disabled
                              className="border w-full block px-6 py-2 mr-5 text-center bg-white text-[#072a48] rounded mb-1"
                            >
                              Send Follow Up
                            </button>
                          </Col>
                        </Row>
                        {/* At A Glance */}
                      </Grid>
                    </div>
                  </div>

                  {/* PTO Request Modal */}
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div>
                        <p>
                          Accepting an agent grants them access to the contracts
                          and other materials needed to become a Tier 1 agent. Do
                          you wish to Accept or Decline this agent?
                        </p>
                        <button
                          className="px-3 py-2 bg-green-600 text-white m-3"
                          onClick={(e) => acceptAgent(userID)}
                        >
                          Accept
                        </button>
                        <button
                          className="px-3 py-2 bg-[#8B0000] text-white m-3"
                          onClick={(e) => declineAgent(userID)}
                        >
                          Decline
                        </button>
                      </div>
                      <button
                        onClick={handleClose}
                        className="button-blue mt-2 mb-2"
                      >
                        Close
                      </button>
                    </Box>
                  </Modal>
                </div>
              </>
            )}
          </>
        ) : (
          <p>You must be an underwriter or administrator to view this page.</p>
        )}
      </>
    );
  }

  export default AgentProfile;
