import React, { useState } from "react";
import Breadcrumbs from "../../assets/Breadcrumbs";
import SingleCheckbox from "../../assets/forms/SingleCheckbox";
import { useLocation } from "react-router-dom";
import BlueButton from "../../assets/forms/BlueButton";
import {
  IosShare as IosShareIcon,
} from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import useTranslations from "../../../Context/actions/useTranslations";
import useFormDataState from "../../../Context/actions/useFormData";

export default function PrintOptions({
  printOptions,
  setPrintOptions,
  userInfo,
  setOpenShareModal,
  handleCancel,
  formData,
}) {
  const breadcrumbs = [
    { label: "Policy" },
    { label: "Printing" },
    { label: "Options" },
  ];

  const handleSingleCheckbox = (name) => {
    const updatedOptions = {};
    for (const optionName in printOptions) {
      updatedOptions[optionName] = optionName === name ? true : false;
    }
    setPrintOptions(updatedOptions)
  }

  const { t } = useTranslations()
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')

  const { formDataGL } = useFormDataState();
  
  return (
    <div className="max-w-[550px]">
      <Breadcrumbs items={breadcrumbs} />
      <h1 className={`${"text-3xl mt-2 mb-2 font-semibold md:mb-0"}`}>
        {`${t("Printing Options")}`}
      </h1>
      
      {!isTemporalUrl &&
      (
        userInfo?.role === "Agent" ||
        userInfo?.role === "Admin" ||
        userInfo?.role === "Underwriter"
      ) ? (
        <BlueButton
          text={t("plm.share-module.share")}
          hasContentToTop={true}
          onClickFunction={() => {
            setOpenShareModal(true)
            handleCancel()
          }}
          icon={<IosShareIcon />}
          className={isMobileScreen ? "w-[100%]" : ""}
        />
      ) : null}

      <p className="my-3">
        Make a selection to continue. Printing the entire policy may take a few
        minutes.
      </p>
      {formData?.program === "Domestic" ? (
        <SingleCheckbox
          name='printDomesticALDecPageOnly'
          label={"Print Domestic AL Declarations Page Only"}
          checked={printOptions['printDomesticALDecPageOnly']}
          onChange={() => handleSingleCheckbox('printDomesticALDecPageOnly')}
        />
      ) : formData?.program === "NAFTA" ? (
        <SingleCheckbox
          name='printNaftaALDecPageOnly'
          label={"Print NAFTA AL Declarations Page Only"}
          checked={printOptions['printNaftaALDecPageOnly']}
          onChange={() => handleSingleCheckbox('printNaftaALDecPageOnly')}
        />
      ) : null}
      {formData?.program === "Domestic" ? (
        <SingleCheckbox
          name='printEntireDomesticALPolicy'
          label={"Print Domestic AL Entire Policy"}
          checked={printOptions['printEntireDomesticALPolicy']}
          onChange={() => handleSingleCheckbox('printEntireDomesticALPolicy')}
        />
      ) : formData?.program === "NAFTA" ? (
        <SingleCheckbox
          name='printEntireNaftaALPolicy'
          label={"Print NAFTA AL Entire Policy"}
          checked={printOptions['printEntireNaftaALPolicy']}
          onChange={() => handleSingleCheckbox('printEntireNaftaALPolicy')}
        />
      ) : null}
      {formData?.program === "Domestic" ? (
        <SingleCheckbox
          name='printDomesticALMCS09'
          label={"Print MCS-09"}
          checked={printOptions['printDomesticALMCS09']}
          onChange={() => handleSingleCheckbox('printDomesticALMCS09')}
        />
      ) : formData?.program === "NAFTA" ? (
        <SingleCheckbox
          name='printNaftaALMCS09'
          label={"Print MCS-09"}
          checked={printOptions['printNaftaALMCS09']}
          onChange={() => handleSingleCheckbox('printNaftaALMCS09')}
        />
      ) : null}
      {formData?.program === "Domestic" && formDataGL?.hasGLCoverage ? (
        <SingleCheckbox
          name='printEntireDomesticGLPolicy'
          label={"Print Domestic GL Entire Policy"}
          checked={printOptions['printEntireDomesticGLPolicy']}
          onChange={() => handleSingleCheckbox('printEntireDomesticGLPolicy')}
        />
      ) : formData?.program === "NAFTA"  && formDataGL?.hasGLCoverage ? (
        <SingleCheckbox
          name='printEntireNaftaGLPolicy'
          label={"Print NAFTA GL Entire Policy"}
          checked={printOptions['printEntireNaftaGLPolicy']}
          onChange={() => handleSingleCheckbox('printEntireNaftaGLPolicy')}
        />
      ) : null}
    </div>
  );
}
