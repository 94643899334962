import React from "react";
import { handleDynamicQuestionnaireChange } from "../../../utils/helpers";
import useTranslations from "../../../Context/actions/useTranslations";

export default function AddContacts({
  firstContact,
  setFirstContact,
  formData,
  setFormData,
  setFormSaved,
  policyID
}) {

  const { t } = useTranslations()

  const handleAddContact = () => {
    setFormData({
      ...formData,
      questionnaire: {
        ...formData?.questionnaire,
        contactInfo: {
          ...formData?.questionnaire?.contactInfo,
          contactFields: [
            ...(Array.isArray(formData?.questionnaire?.contactInfo?.contactFields)
              ? formData?.questionnaire?.contactInfo?.contactFields
              : []),
            {
              contactName: "",
              contactTitle: "",
              contactEmail: "",
              contactCountryCode: "",
              contactPhone: "",
            },
          ],
        }
      },
    });
  };

  const handleSubtractContact = async (e, i) => {
    try {
      e.preventDefault();
      setFormSaved(false);
      const values = [...formData?.questionnaire?.contactInfo?.contactFields];
      values.splice(i, 1);
      let fieldsPayload = values;
      if (values.length === 0) {
        fieldsPayload = [];
      }
      await handleDynamicQuestionnaireChange(
        policyID,
        "questionnaire.contactInfo.contactFields",
        fieldsPayload,
        formData,
        setFormData,
        setFormSaved
      );
    } catch (error) {
      throw error
    }
  };

  const handleChange = async (index, fieldName, value) => {
    if (index === -1) {
      let updatedValues = {};
      let requestFieldName = fieldName
      if (fieldName === 'contactName') {
        updatedValues['primaryContact'] = value
        requestFieldName = 'primaryContact'
        setFirstContact({
          ...firstContact,
          contactName: value
        })
      } else if (fieldName === 'contactTitle') {
        updatedValues['title'] = value
        requestFieldName = 'title'
        setFirstContact({
          ...firstContact,
          contactTitle: value
        })
      } else if (fieldName === 'contactEmail') {
        updatedValues['email'] = value
        requestFieldName = 'email'
        setFirstContact({
          ...firstContact,
          contactEmail: value
        })
      } else if (fieldName === 'contactCountryCode') {
        updatedValues['countryCode'] = value
        requestFieldName = 'countryCode'
        setFirstContact({
          ...firstContact,
          contactCountryCode: value
        })
      } else if (fieldName === 'contactPhone') {
        updatedValues['contactPhone'] = value
        requestFieldName = 'contactPhone'
        setFirstContact({
          ...firstContact,
          contactPhone: value
        })
      }
      await handleDynamicQuestionnaireChange(
        policyID,
        requestFieldName,
        updatedValues,
        formData,
        null,
        setFormSaved,
        false,
        true
      );
    } else {
      let updatedFields = [];
      if (formData?.questionnaire?.contactInfo?.contactFields) {
        updatedFields = [...formData?.questionnaire?.contactInfo?.contactFields];
      }
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldName]: value,
      };
      if (formData.primaryContact) delete formData.primaryContact
      if (formData.title) delete formData.title
      if (formData.email) delete formData.email
      if (formData.contactCountryCode) delete formData.contactCountryCode
      if (formData.contactPhone) delete formData.contactPhone
      await handleDynamicQuestionnaireChange(
        policyID,
        "questionnaire.contactInfo.contactFields",
        updatedFields,
        formData,
        setFormData,
        setFormSaved
      );
    }
  };

  const contactFieldsList =
    formData?.questionnaire?.contactInfo?.contactFields ?
    formData?.questionnaire?.contactInfo?.contactFields : []

  const mixedFields = [
    firstContact,
    ...contactFieldsList
  ]

  const determineContactTitleText = (contactIndex) => {
    if ((contactIndex + 1) === 1) return t("plm.questionnaire.contact-information.primary-contact")
    return `${t("plm.questionnaire.contact-information.item1")} ${contactIndex + 1}`
  }

  return (
    <div>
      {mixedFields.map((field, index) => (
        <div key={`contact${index}`} className="my-3">
          <h2 className="text-lg text-[#072a48] font-semibold mb-2">
            {determineContactTitleText(index)}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.contact-information.contact-name")}:
              </label>
              <input
                name="contactName"
                onChange={(e) =>
                  handleChange(
                    (index - 1),
                    "contactName",
                    e.target.value
                  )
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="text"
                value={field.contactName}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.contact-information.contact-title")}:
              </label>
              <input
                name="contactTitle"
                onChange={(e) =>
                  handleChange(
                    (index - 1),
                    "contactTitle",
                    e.target.value
                  )
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="text"
                value={field.contactTitle}
              />
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.contact-information.contact-country-code")}:
              </label>
              <select
                name="contactCountryCode"
                onChange={(e) =>
                  handleChange(
                    (index - 1),
                    "contactCountryCode",
                    e.target.value
                  )
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={field.contactCountryCode}
              >
                <option value="">Select an option</option>
                <option value="+1">+1 (USA)</option>
                <option value="+52">+52 (Mexico)</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.contact-information.contact-phone")}:
              </label>
              <input
                name="contactPhone"
                onChange={(e) =>
                  handleChange(
                    (index - 1),
                    "contactPhone",
                    e.target.value
                  )
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="tel"
                value={field.contactPhone}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-semibold mb-1">
                {t("plm.questionnaire.contact-information.contact-email")}:
              </label>
              <input
                name="contactEmail"
                onChange={(e) =>
                  handleChange(
                    (index - 1),
                    "contactEmail",
                    e.target.value
                  )
                }
                className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="email"
                value={field.contactEmail}
              />
            </div>
          </div>
          {formData.documentType === "Application" &&
          index !== 0 ? (
            <button
              className="mt-2 text-[#8B0000] hover:text-red-700"
              onClick={(e) => handleSubtractContact(e, index - 1)}
            >
              {t(`${t("plm.questionnaire.contact-information.item2")} ${index + 1}`)}
            </button>
          ) : null}
        </div>
      ))}

      {formData.documentType === "Application" && (
        <button
          className="text-blue-500 hover:text-blue-700"
          onClick={handleAddContact}
        >
          {t("plm.questionnaire.contact-information.item3")}
        </button>
      )}
    </div>
  );
}
