import rateTruck from "../RateTruck";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase";
import getFleetCredit from "../getFleetCredit";
import getPolicyData from "../premiumCalculation/getPolicyData";
import miniValidation from "../premiumCalculation/miniValidation";
import getTaxSchedule from "../getTaxSchedule";
import getStampFee from "../premiumCalculation/getStampFee";
import getSurplusLinesRate from "../premiumCalculation/getSurplusLinesRate";
import getCurrentPolicyTotals from "../getCurrentPolicyTotals";
import cleanTruckList from "./cleanTruckList";
import getEndorsementData from "./getEndorsementData";
import calculateTotal from "./calculateTotal";
import updateValuesArray from "./updateValuesArray";
import updateBatch from "./updateBatch";
import humanDate from "./humanDate";
import prorateUnit from "./prorateUnit";

const rateAllUnits = async (
  e,
  policyID,
  formData,
  truckList,
  adjustmentPercentage,
  isEndorsement,
  doNotRerate,
  endoID,
  isEndo
) => {
  const fd = formData;
  try {
    let trucks = cleanTruckList(truckList);
    if (trucks.length === 0) return [0, trucks, 0];
    let values = [...trucks];
    let totalRating = 0;
    let TIV = 0;
    // TODO: better way of managing adjustments (for colt)
    const [policyData, adjustmentPercent] = await getPolicyData(
      policyID,
      fd,
      isEndorsement,
      adjustmentPercentage
    );

    let endoRef;
    let endoSnap;
    let endoData;
    if (isEndorsement) {
      endoRef = doc(
        db,
        "policy-applications",
        policyID,
        "endorsements",
        endoID
      );
      endoSnap = await getDoc(endoRef);
      endoData = {
        ...endoSnap.data(),
        id: endoSnap.id,
      };
    }

    if (!miniValidation(trucks, policyData)) return;

    const hasMCP65 = fd?.hasMCP65 || false;
    const hasNonOwned = policyData?.hasNonOwned || false;

    const batch = writeBatch(db);
    const unitCountForCalculations = isEndorsement
      ? policyData?.bindData?.powerUnitCountAtBind
      : trucks.length;
    const fleetCredit = await getFleetCredit(unitCountForCalculations);

    for (const unit of trucks) {
      const policyDetailToShow = unit?.policiesDetails.find(
        (detail) => detail.id === policyID
      );
      const pdts = policyDetailToShow;

      const truckClass = pdts?.truckClass || unit.truckClass;
      const truckRadius = pdts?.truckRadius || unit.truckRadius;
      const stateOfEntry = pdts?.stateOfEntry || fd.stateOfEntry;
      const coverageSingleLimit =
        pdts?.coverageSingleLimit || fd.coverageSingleLimit;
      const program = pdts?.program || policyData.program;

      // Get the base rating for the power unit
      let rating = rateTruck(
        policyData.product,
        coverageSingleLimit,
        false,
        stateOfEntry,
        truckClass,
        truckRadius,
        program
      );

      // Set the rating to negative if it's a remove power units endorsement
      const setToNegative = (rating) => {
        if (endoData?.type === "Remove Power Units") return rating * -1;
        else return rating;
      };
      rating = setToNegative(rating);

      // Calculate the total premium after fleet credit (without proration)
      let totalPremiumAfterFleetCredit = calculateTotal(
        rating,
        hasNonOwned,
        hasMCP65,
        adjustmentPercent,
        fleetCredit,
        isEndorsement,
        endoData,
        fd
      );

      // Prorate the unit if it is an endorsement unit
      if (isEndo)
        totalPremiumAfterFleetCredit = prorateUnit(
          fd,
          endoData,
          pdts,
          totalPremiumAfterFleetCredit
        );

      // Keep track of the TIV
      TIV += parseInt(unit.truckACV);

      // Calculate the taxes and fees
      const taxSchedule = await getTaxSchedule();
      const taxesAndFees = { ...taxSchedule };
      const stampFeePercent = getStampFee(fd.stateOfEntry, taxesAndFees);
      const surplusLinesTaxPercent = getSurplusLinesRate(
        fd.stateOfEntry,
        taxesAndFees
      );
      const otherFees = isEndo ? 0 : taxesAndFees.otherFees;
      const processingFee =
        taxesAndFees.processingFee * totalPremiumAfterFleetCredit;
      const stampFee = stampFeePercent * totalPremiumAfterFleetCredit;
      const surplusLinesTax =
        surplusLinesTaxPercent *
        (totalPremiumAfterFleetCredit + otherFees + processingFee);
      const taxes = {
        surplusLinesTax,
        stampFee,
        processingFee,
        otherFees: isEndo ? 0 : otherFees,
      };

      // Assemble the rating data
      const ratingData = {
        rating,
        fleetCredit,
        nonOwnedAdjustment: hasNonOwned ? rating * 0.05 : 0,
        mcp65Adjustment: hasMCP65 ? rating * 0.05 : 0,
        adjustedRate: totalPremiumAfterFleetCredit,
        taxes,
        totalPremiumAfterTaxes:
          totalPremiumAfterFleetCredit +
          stampFee +
          otherFees +
          taxes.surplusLinesTax +
          processingFee,
      };

      values = updateValuesArray(values, unit, ratingData, policyID);

      const policiesDetails = unit.policiesDetails.map((detail) => {
        if (detail.id === policyID) {
          return {
            ...detail,
            ...ratingData,
          };
        }
        return detail;
      });

      const newUnit = {
        ...unit,
        policiesDetails,
      };

      if (!doNotRerate) {
        await updateBatch(batch, fd, newUnit);
      }
    }

    if (!doNotRerate) await batch.commit();
    trucks = values;
    const agencyID = fd.agencyID;
    const insuredID = fd.insuredID;
    const newTotalRating = !isEndorsement
      ? await getCurrentPolicyTotals(agencyID, insuredID, policyID)
      : await getCurrentPolicyTotals(agencyID, insuredID, policyID, endoID);
    return [newTotalRating, trucks, TIV];
  } catch (error) {
    console.log("error rate all units", error);
    throw error;
  }
};

export default rateAllUnits;
