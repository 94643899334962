import getDailyPremium from "./getDailyPremium";
import getLeapDays from "./getLeapDays";
import humanDate from "./humanDate";

const prorateUnit = (
  formData,
  endoData,
  truck,
  totalPremiumAfterFleetCredit
) => {
  // Set some values
  const effectiveDate = formData.effectiveDate.toDate();
  const addedDate = truck.addedDate.toDate // Check if is Firestore Timestamp object
    ? truck.addedDate.toDate() // If it is let it ride
    : humanDate(truck.addedDate); // If it isn't, conver it

  const endoEffectiveDate = humanDate(endoData.endoEffectiveDate);

  console.log("effective date: ", effectiveDate);
  console.log("added date: ", addedDate);
  console.log("endo effective date: ", endoEffectiveDate);

  const daysRemainingInPolicy = Math.floor(
    (endoEffectiveDate - effectiveDate) / (1000 * 60 * 60 * 24)
  );

  // Calculate the number of leap years between the effective date and expiration date
  const leapDays = getLeapDays(effectiveDate, endoEffectiveDate);

  // Make the final proration calculation
  const dailyPremium = getDailyPremium(
    endoData,
    totalPremiumAfterFleetCredit,
    leapDays,
    truck,
    effectiveDate,
    addedDate,
    endoEffectiveDate
  );

  console.log("daily premium: ", dailyPremium);
  console.log("total days: ", daysRemainingInPolicy);

  totalPremiumAfterFleetCredit -=
    parseFloat(dailyPremium.toFixed(2)) * daysRemainingInPolicy;

  return totalPremiumAfterFleetCredit;
};

export default prorateUnit;
