import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useTranslations from "../../../../../Context/actions/useTranslations";

export default function Footer({
  note,
  documentID,
  notesList,
  editMode,
  editedContent,
  editedSubject,
  setEditedContent,
  setEditMode,
  setEditedSubject,
  handleEdit,
  handleSave,
  handleDelete,
  collectionPath,
}) {

  const { t } = useTranslations()

  return (
    <div
      className="w-full"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      {!editMode[note.id] ? (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#0c4a6e",
            '&:hover': {
              backgroundColor: '#072a48', 
            },
            color: "#FFFFFF",
          }}
          startIcon={<EditIcon />}
          onClick={() => handleEdit(note.id, setEditMode)}
        >
          {t("plm.notes.edit")}
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#0c4a6e",
            '&:hover': {
              backgroundColor: '#072a48', 
            },
            color: "#FFFFFF",
          }}
          onClick={() =>
            handleSave(
              documentID,
              note.id,
              notesList,
              editedContent,
              editedSubject,
              setEditedContent,
              setEditMode,
              setEditedSubject,
              collectionPath
            )
          }
        >
          {t("plm.notes.save")}
        </Button>
      )}
      {editMode[note.id] ? (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#8B0000",
            color: "#FFFFFF",
            marginLeft: "8px",
          }}
          onClick={() => setEditMode({})}
        >
          {t("plm.notes.cancel")}
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#8B0000",
            color: "#FFFFFF",
            marginLeft: "8px",
          }}
          startIcon={<DeleteIcon />}
          onClick={() => handleDelete(documentID, note.id, collectionPath)}
        >
          {t("plm.notes.delete")}
        </Button>
      )}
    </div>
  );
}
