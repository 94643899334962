// src/Components/BugReports/ViewReports.jsx

import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Tooltip
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from '@mui/icons-material/Check';
import ViewIcon from '@mui/icons-material/Visibility';

const ViewReports = () => {
  const [value, loading, error] = useCollection(collection(db, 'bugReports'));
  const [updating, setUpdating] = useState('');

  const handleUpdateStatus = async (id) => {
    setUpdating(id);
    try {
      const reportRef = doc(db, 'bugReports', id);
      await updateDoc(reportRef, {
        status: 'Complete',
        completedAt: new Date()
      });
    } catch (err) {
      console.error('Error updating document: ', err);
    } finally {
      setUpdating('');
    }
  };

  const dateParse = (dateObj) => {
    if (!dateObj) return '';
    const date = new Date(
      dateObj.seconds * 1000 + Math.floor(dateObj.nanoseconds / 1000000)
    );
    const humanDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    })
    return humanDate;
  }

  const extractFileName = (url) => {
    if (!url) return '';
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  return (
    <Container maxWidth="lg">
      <Box mt={5}>
        <Typography variant="h4" gutterBottom>
          Bug Reports
        </Typography>
        {error && <Typography color="error">Error: {error.message}</Typography>}
        {loading && <Typography>Loading...</Typography>}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Expected Results</TableCell>
                <TableCell>Actual Results</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Error Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>See images</TableCell>
                <TableCell>Completion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {value && value.docs.map(doc => {
                const {
                  description,
                  expectedResult,
                  actualResult,
                  notes,
                  errorLocation,
                  status = 'Submitted',
                  completedAt,
                  createdAt,
                  bugReportsDocs
                } = doc.data();
                return (
                  <TableRow key={doc.id}>
                    <TableCell>{dateParse(createdAt)}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{expectedResult || ''}</TableCell>
                    <TableCell>{actualResult || ''}</TableCell>
                    <TableCell>{notes}</TableCell>
                    <TableCell>{errorLocation}</TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>
                      {bugReportsDocs && bugReportsDocs.length > 0 ? (
                        <Tooltip
                          title={<Box>
                            <p>Click an image to see in another tab</p>
                            {bugReportsDocs.map((url, i) => (
                              <a
                                href={url}
                                target="_blank"
                                key={i}
                                className="flex items-center cursor-pointer"
                              >
                                <p>{extractFileName(url)}</p>
                                <OpenInNewIcon
                                  fontSize={"smaller"}
                                  className="ml-2"
                                />
                              </a>
                            ))}
                          </Box>}
                          placement="top"
                          arrow
                        >
                          <Button
                            variant="contained"
                            color="primary"
                          >
                            <ViewIcon />
                          </Button>
                        </Tooltip>
                      ) : ''}
                    </TableCell>
                    <TableCell>
                      {status === 'Submitted' ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdateStatus(doc.id)}
                          disabled={updating === doc.id}
                        >
                          {updating === doc.id ? 'Updating...' :
                            <Tooltip
                              title={<Box>Mark as Complete</Box>}
                              placement="top"
                              arrow
                            >
                              <CheckIcon />
                            </Tooltip> 
                          }
                        </Button>
                      ) : dateParse(completedAt)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ViewReports;
