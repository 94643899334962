import { useContext, useEffect, useState } from "react"
import { AppContext } from "../AppContext"
import { SET_LANGUAGE } from "../types"
import { LANGUAGES } from "../../utils/constants"
import enTranslations from "../../languages/en.json"
import esTranslations from "../../languages/es.json"

const useTranslations = () => {

    const { state, dispatch } = useContext(AppContext)
    const { language } = state

    const { spanish, english } = LANGUAGES
    const defaultTranslations = enTranslations

    const changeLanguage = lang => {
        dispatch({ type: SET_LANGUAGE, payload: lang })
    }

    const [translations, setTranslations] = useState(defaultTranslations)

    function translate(key) {
        const translation = key.split('.').reduce((acc, cur) => {
            return acc && acc[cur]
        }, translations)
        return translation || key
    }

    useEffect(() => {
        switch(state.language) {
            case spanish:
                setTranslations(esTranslations)
                break;
            default:
                setTranslations(defaultTranslations)
        }
    }, [state.language])

    const timezone = language === spanish ? 'es-ES' : 'en-US'

    return {
        changeLanguage,
        language,
        t: translate,
        spanish,
        english,
        timezone
    }
}

export default useTranslations