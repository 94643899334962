import { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import Modal from "react-modal";
import { Endorsement } from "../Endorsement";
import { UNDERWRITING_ENDORSEMENT_ROUTE } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import useTranslations from "../../Context/actions/useTranslations";
import useEndorsements from "../../hooks/endorsements/useEndorsements";
import {
  endorsementDetailsTable,
  endorsementTableColumns,
  filterEndorsementKeysToSearch,
} from "../../utils/endorsementDetails";
import DynamicTable from "../../Components/assets/lists/DynamicTable";
import ListFilters from "../../Components/agent/policies/ListFilters";
import { manageEndorsementOptions } from "../../utils/searchFilters";
import useGeneralState from "../../Context/actions/useGeneralState";
import { UserAuth } from "../../Context/AuthContent";
import { endorsementPdfPayloads } from "../../utils/endorsementsPdfPayloads";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import EndoSearch from "../../Components/underwriter/endorsements/EndoSearch";
import AdvancedEndoSearch from "../../Components/underwriter/endorsements/AdvancedEndoSearch";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent dark overlay
    backdropFilter: "blur(5px)", // Blur the background behind the modal
    zIndex: 1000, // Ensure the overlay is on top
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

export default function Endorsements(props) {
  const { t, language } = useTranslations();
  const {
    endorsements,
    modalIsOpen,
    afterOpenModal,
    closeModal,
    policyID,
    insuredInfo,
    agencyInfo,
    userInfo,
    endoEffectiveDate,
    formData,
    truckData,
    currentEndoFilters,
    setCurrentEndoFilters,
    skeletonTableLoader,
  } = useEndorsements(props);

  const navigate = useNavigate();

  const { user: auth } = UserAuth();
  const [loading, setLoading] = useState(false);
  const { controlToast } = useGeneralState();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    endorsementDetailsTable(endorsements, false, language)
      .then((res) => {
        const { tableData } = res;
        setDetails(tableData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [endorsements]);

  const showItem = (e, id) => {
    e.preventDefault();
    const endo = endorsements.find((endo) => endo.id === id);
    navigate(`${UNDERWRITING_ENDORSEMENT_ROUTE}/${id}/${endo?.policyID}`);
  };

  const handleDownload = async (e, item) => {
    e.preventDefault();
    try {
      setLoading(true);
      const endo = endorsements.find((endo) => endo.id === item.id);
      const agencyDocRef = doc(db, "agencies", endo.agencyID);
      const agencyDocSnap = await getDoc(agencyDocRef);
      let agencyInfo = null;
      if (agencyDocSnap?.exists()) {
        agencyInfo = agencyDocSnap?.data();
      }
      await endorsementPdfPayloads(endo, auth, insuredInfo, agencyInfo);
      setLoading(false);
      controlToast(true, "Endorsement printing was succesful", "success");
    } catch (error) {
      setLoading(false);
      controlToast(
        true,
        "There was an error printing the endorsement",
        "error"
      );
    }
  };

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  return (
    <div className="mt-3">
      <div className="card">
        <div className="card-body">
          <h1 className="text-xl">{t("endorsements.title")}</h1>
          <section className="mt-2">
            {t("endorsements.view-endorsements")}
          </section>
          <EndoSearch
            userInfo={userInfo}
            loading={loading}
            manageEndorsementOptions={manageEndorsementOptions}
            currentEndoFilters={currentEndoFilters}
            setCurrentEndoFilters={setCurrentEndoFilters}
            skeletonTableLoader={skeletonTableLoader}
            endorsementTableColumns={endorsementTableColumns}
            filterEndorsementKeysToSearch={filterEndorsementKeysToSearch}
            details={details}
            showItem={showItem}
            handleDownload={handleDownload}
          />
        </div>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button onClick={closeModal}>X</button>
          <div>
            <Endorsement
              policyID={policyID}
              insuredInfo={insuredInfo}
              userInfo={userInfo}
              endoEffectiveDate={endoEffectiveDate}
              formData={formData}
              truckData={truckData}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
