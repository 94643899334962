import React, { useMemo, useState } from "react";
import AddDriver from "./AddDriver";
import LoadingScreen from "react-loading-screen";
import ImportDrivers from "./ImportDrivers";
import DriverDetails from "./DriverDetails";
import DriversList from "./DriversList";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useTranslations from "../../../Context/actions/useTranslations";
import useDriversList from "./useDriversList";
import FormPopup from "../../generals/Popup/FormPopup";
import { isExpired } from "../../../utils/helpers";
import TableSkeleton from "../../generals/TableSkeleton";
import { useMediaQuery } from "@mui/material";
import { generateXlsxFile } from "../../../utils/excelCreator";
import {
  Download as DownloadIcon,
  IosShare as IosShareIcon,
  Loop as LoopIcon,
} from "@mui/icons-material";
import { filterRightPoliciesDetails } from "./setPolicyInfo";
import DropdownMultiUpload from "../../assets/forms/DynamicPropertiesInputs/DropdownMultiUpload";
import { handleDropdown } from "../../assets/lists/DynamicTable/utils";
import ImportAllLists from "./ImportAllLists";
import ImportInspas from "./ImportInspas";
import { useLocation } from "react-router-dom";
import ApiService from "../../../services/southern-api/apiService";
import ConfirmDeleteModal from "../../ConfirmDeleteModal";
import ShareModal from "../../ShareModal";
import "../../styling/styles.css";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Popup from "../../generals/Popup";

export default function PolicyDrivers(props) {
  const {
    formData,
    policyID,
    insuredInfo,
    userInfo,
    driverList,
    truckList,
    trailerList,
    isEndoManager,
    getEndorsements,
    setDidSuccess,
    emailValue,
  } = props;

  const [display, setDisplay] = useState(true);
  const [showAddDriver, setShowAddDriver] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const [showDriverDetails, setShowDriverDetails] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState();
  const { controlToast } = useGeneralState()
  const { t } = useTranslations();
  const driverHook = useDriversList({
    ...props,
    setSelectedDriver,
    setShowTable,
    setShowDriverDetails,
    setShowImport,
    setShowAddDriver,
  });
  const { setDeletionMode, handleSubmit, deletionMode, skeletonTableLoader } =
    driverHook;

  const breadcrumbs = [{ label: t(`plm.status.${formData.documentType}`) }, { label: "Drivers" }];

  const breadcrumbsDetails = [
    { label: formData.documentType },
    { label: "Drivers" },
    { label: "Driver Details" },
  ];

  const breadcrumbsDelete = [
    { label: formData.documentType },
    { label: "Drivers" },
    { label: "Delete" },
  ];

  const count = useMemo(() => {
    const effectiveElementsList = driverList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  }, [driverList]);

  const handleCount = (list) => {
    const effectiveElementsList = list.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  }

  const counts = useMemo(() => {
    const driverCount = handleCount(driverList)
    const powerUnitCount = handleCount(truckList)
    const trailerCount = handleCount(trailerList)
    return {
      driverCount,
      powerUnitCount,
      trailerCount
    }
  }, [driverList, truckList, trailerList]);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const activeList = driverList?.filter((elem) =>
    Boolean(
      filterRightPoliciesDetails(elem.policiesDetails, policyID)?.status ===
        "Active"
    )
  );

  const handleImportFn = () => {
    setShowImport(true);
    setShowTable(false);
  };

  const [showImportAll, setShowImportAll] = useState(false);
  const [showImportInspas, setShowImportInspas] = useState(false);

  const handleImportAll = () => {
    setShowImportAll(true);
    setShowTable(false);
  };

  const handleImportInspas = () => {
    setShowImportInspas(true);
    setShowTable(false);
  };

  const titleDocumentType = formData?.documentType ?
    t(`plm.status.${formData?.documentType}`) : "";
  const title = formData?.documentType ?
    t(`plm.status.Drivers`) : "";

  const location = useLocation();
  const isTemporalUrl = location?.pathname?.includes("temporarily");
  const isTemporalUrlClient = location?.pathname?.includes("temporarily-client");

  const apiService = new ApiService();
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessModuleUpdateConfirmation({
        policyData: formData,
        module: "drivers",
        emails: [emailValue],
      });
      setDidSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  const [modalDeletionConfirm, setModalDeletionConfirm] = useState(false);

  const openModalDeletionConfirm = (e) => {
    e.preventDefault();
    setModalDeletionConfirm(true);
  };

  const confirmDelete = async () => {
    try {
      await handleSubmit(null, "multiple", "massive");
      setModalDeletionConfirm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [openShareModal, setOpenShareModal] = useState(false);

  const [openSCTMassiveModal, setOpenSCTMassiveModal] = useState(false);
  const [loadingSCT, setLoadingSCT] = useState(false);

  const handleUpdateSct = async () => {
    try {
      // setLoadingSCT(true);
      setOpenSCTMassiveModal(false);
      controlToast(true, "Massive SCT update was requested successfully", "success");
      const drivers = driverList.filter((driver) => Boolean(driver.mxNMP));
      const payloadDrivers = drivers.map((driver) => {
        return {
          "driverLicenseNumber": driver.licenseNumber,
          "driverSCTNumber": driver.mxNMP,
          "agencyID": formData.agencyID,
          "insuredID": formData.insuredID,
          "driverID": driver.id
        }
      })
      const payload = {
        licenses: payloadDrivers
      }
      await apiService.setMassiveSctToUpdateDrivers(payload);
      // const results = await apiService.setMassiveSctToUpdateDrivers(payload);
      // const resultsWithErrors = results.resultsArray.filter((driver) => {
      //   return Boolean(driver?.result?.error)
      // })
      // const licensesWithErrors = resultsWithErrors.map((driver) => {
      //   return driver.driverLicenseNumber
      // })
      // const textExplaning =
      //   licensesWithErrors && licensesWithErrors?.length > 0 ?
      //   `These license were not found ${licensesWithErrors.join(', ')}; it will be tried later, the other licenses were updated successfully` :
      //   "The sct info from licenses was updated successfully for every mexican driver"
      // setLoadingSCT(false);
      // setOpenSCTMassiveModal(false);
      // controlToast(true, textExplaning, "success");
    } catch (error) {
      controlToast(true, "There was an error completing the sct info", "error");
      setLoadingSCT(false);
      console.error(error);
    }
  }

  // console.log('formData', formData)

  return (
    <>
      <Popup
        isOpen={openSCTMassiveModal}
        onRequestClose={() => setOpenSCTMassiveModal(false)}
        maxWidth="40%"
      >
        <div className="h-[300px] flex justify-center items-center">
          <LoadingScreen
            loading={loadingSCT}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            padding="0"
            logoSrc=""
            text={`Updating SCT info for all the mexican drivers`}
          >
            <p className="text-2xl mt-2 font-semibold mb-20 text-center">
              {t('plm.drivers.details.massive-sct-description')}
            </p>
            <BlueButton
              text={t('plm.drivers.details.massive-sct')}
              hasContentToBottom={true}
              onClickFunction={handleUpdateSct}
              icon={<LoopIcon />}
              className={"w-[100%]"}
              disabled={loadingSCT}
            />
          </LoadingScreen>
        </div>
      </Popup>
      <ShareModal
        insuredInfo={insuredInfo}
        formData={formData}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        module={"drivers"}
      />
      <ConfirmDeleteModal
        open={modalDeletionConfirm}
        onClose={() => setModalDeletionConfirm(false)}
        onConfirm={confirmDelete}
      />
      {display && (
        <div className={`card-body ${isEndoManager ? "p-0" : ""}`}>
          {showTable && !showImport && !showAddDriver && (
            <>
              {!isEndoManager && !isTemporalUrl ? (
                <Breadcrumbs
                  items={deletionMode ? breadcrumbsDelete : breadcrumbs}
                />
              ) : null}
              <h1
                className={`${
                  isEndoManager
                    ? "text-2xl mt-2.5 font-bold md:mb-0"
                    : "text-3xl mt-2 font-semibold md:mb-0"
                }`}
              >
                {!isEndoManager
                  ? `${
                      deletionMode
                        ? t("Delete")
                        : `${titleDocumentType} ${title}`
                    } ${deletionMode ? "" : `(${count})`}`
                  : `${deletionMode ? t("Delete") : t("Endorse Drivers")}`}
              </h1>
              <p className="my-3">
                {deletionMode
                  ? t(
                      "Select drivers from the bottom list to add to the deletion list. Click submit once you have selected all necessary drivers."
                    )
                  : t("plm.drivers.explanation")}
                {deletionMode && formData.documentType === "Policy" && (
                  <p className="my-2">
                    {t("The driver")}
                    <b> {t("will not be removed from the policy")}</b>{" "}
                    {t("until the endorsement is approved by an underwriter.")}
                  </p>
                )}
                {formData.documentType !== "Application" &&
                  formData.documentType !== "Policy" && (
                    <>Drivers may not be edited during the quoting phase.</>
                  )}
              </p>
              {skeletonTableLoader ? (
                <TableSkeleton rows={1} columns={4} />
              ) : (
                (formData.documentType === "Application" ||
                  formData.documentType === "Policy") && (
                  <div className="mt-3 mb-1 flex items-center flex-wrap">
                    <BlueButton
                      text={t("plm.drivers.add")}
                      onClickFunction={() => {
                        setShowAddDriver(true);
                        setShowTable(false);
                      }}
                      disabled={deletionMode}
                      hasContentToBottom={true}
                      hasContentToRight={!isMobileScreen}
                      className={isMobileScreen ? "w-[100%]" : ""}
                    />
                    {!isTemporalUrl ? (
                      <>
                        <DropdownMultiUpload
                          containerStyle={`bg-transparent border-none p-0
                            ${isMobileScreen ? "w-[100%]" : ""}
                            ${isMobileScreen ? "dropdown-multiupload" : ""}
                          `}
                          wrapperStyle={`
                            text-[#8B0000] hover:text-[#8B0000] d-inline
                            ${isMobileScreen ? "w-[100%]" : ""}
                          `}
                          handleDropdown={() =>
                            handleDropdown(
                              null,
                              "import",
                              "drivers",
                              handleImportFn,
                              handleImportAll,
                              handleImportInspas,
                              t
                            )
                          }
                          formData={formData}
                          disabled={deletionMode}
                          item={null}
                          icon="import"
                          dropdownType="import"
                          list={driverList}
                        />
                        {activeList?.length > 0 ? (
                          <BlueButton
                            text={t("plm.drivers.export")}
                            onClickFunction={(e) => {
                              e.preventDefault();
                              generateXlsxFile(
                                activeList,
                                "Active Drivers",
                                "Active Drivers.xlsx"
                              );
                            }}
                            disabled={deletionMode}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                            icon={<DownloadIcon />}
                          />
                        ) : null}
                        {formData?.documentType === "Application" &&
                        formData.status !== "In-Renewal" &&
                        Boolean(deletionMode) ? (
                          <RedButton
                            text={t("plm.drivers.delete")}
                            // onClickFunction={openModalDeletionConfirm}
                            onClickFunction={(e) => {
                              e.preventDefault();
                              handleSubmit(null, "multiple", "massive");
                            }}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                        {!Boolean(deletionMode) && count !== 0 ? (
                          <RedButton
                            text={t("plm.drivers.delete")}
                            onClickFunction={(e) => {
                              setDeletionMode(true);
                            }}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                        {userInfo?.role === "Agent" ||
                        userInfo?.role === "Admin" ||
                        userInfo?.role === "Underwriter" ? (
                          <BlueButton
                            text={t("plm.share-module.share")}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            onClickFunction={() => setOpenShareModal(true)}
                            icon={<IosShareIcon />}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                        <BlueButton
                          text={t('plm.drivers.details.massive-sct')}
                          hasContentToBottom={true}
                          onClickFunction={() => setOpenSCTMassiveModal(true)}
                          icon={<LoopIcon />}
                          className={isMobileScreen ? "w-[100%]" : ""}
                        />
                      </>
                    ) : null}
                  </div>
                )
              )}
            </>
          )}
          {showTable && (
            <>
              {formData.documentType === "Policy" && !isTemporalUrl && (
                <p className="mb-2">{t("plm.drivers.see-driver-details")}</p>
              )}
              {isTemporalUrl && !isTemporalUrlClient ? (
                <>
                  {/* TODO: Translate this line */}
                  <p className="mb-2">
                    Once you have made all necessary changes, please click the
                    'Submit Changes' button below.
                  </p>
                  <BlueButton
                    text="Submit Changes"
                    onClickFunction={handleFinishOperation}
                    hasContentToBottom={true}
                    hasContentToRight={!isMobileScreen}
                    className={isMobileScreen ? "w-[100%]" : ""}
                    icon="no-icon"
                  />
                </>
              ) : null}
              <DriversList
                formData={formData}
                policyID={policyID}
                userInfo={userInfo}
                insuredInfo={insuredInfo}
                driverList={driverList}
                setDriverList={props.setDriverList}
                setShowTable={setShowTable}
                setSelectedDriver={setSelectedDriver}
                setShowDriverDetails={setShowDriverDetails}
                isEndoManager={isEndoManager}
                getEndorsements={getEndorsements}
                {...driverHook}
              />
              <FormPopup
                isOpen={driverHook.openModal}
                onRequestClose={driverHook.handleCloseModal}
                title={`Select the expiration date of the drivers
                ${driverHook.deletionMode ? " and the deletion reason" : ""}`}
                formSettings={driverHook.formSettings}
                loading={driverHook.modalSubmitting}
              />
            </>
          )}
          {showAddDriver && (
            <AddDriver
              isEndoManager={isEndoManager}
              getEndorsements={getEndorsements}
              driverList={driverList}
              setDriverList={props.setDriverList}
              formData={formData}
              insuredInfo={insuredInfo}
              setShowAddDriver={setShowAddDriver}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              saveForm={props.saveForm}
              setFormSaved={props.setFormSaved}
              formSaved={props.formSaved}
            />
          )}
          {showImport && (
            <ImportDrivers
              insuredInfo={insuredInfo}
              setDriverList={props.setDriverList}
              setShowAddDriver={setShowAddDriver}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImport}
              count={count}
            />
          )}
          {showImportAll && (
            <ImportAllLists
              insuredInfo={insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportAll}
              count={counts.driverCount + counts.powerUnitCount + counts.trailerCount}
            />
          )}
          {showImportInspas && (
            <ImportInspas
              insuredInfo={insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportInspas}
              count={counts.driverCount + counts.powerUnitCount + counts.trailerCount}
            />
          )}
          {showDriverDetails && (
            <>
              {!isEndoManager ? (
                <Breadcrumbs items={breadcrumbsDetails} />
              ) : null}
              <DriverDetails
                formData={formData}
                userInfo={userInfo}
                driverList={driverList}
                selectedDriver={selectedDriver}
                setShowDriverDetails={setShowDriverDetails}
                setShowTable={setShowTable}
                policyID={policyID}
                insuredInfo={insuredInfo}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
