import React, { useEffect, useState } from "react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DatePicker from "react-datepicker";
import ApiService from "../../services/southern-api/apiService";
import "react-datepicker/dist/react-datepicker.css";
import useTranslations from "../../Context/actions/useTranslations";

export default function InsuredInfo(props) {

  const { t } = useTranslations();

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();

  const agencyID = props.agencyID;
  const enableEditing = props.enableEditing;
  const setEnableEditing = props.setEnableEditing;
  const success = props.success;
  const setSuccess = props.setSuccess;
  const insuredsID = props.insuredsID;
  const policyID = props.policyID;
  const [formData, setFormData] = useState({});
  const [dateEstablished, setDateEstablished] = useState(new Date());

  const apiService = new ApiService()

  const saveForm = async (e) => {
    e.preventDefault();
    const insuredRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredsID
    );
    const newFormData = { ...formData };
    if (dateEstablished) {
      newFormData['dateEstablished'] = dateEstablished;
    }
    if (Boolean(moduleValue)) {
      apiService.setInsuredInfo({ agencyID, insuredID: insuredsID, payload: newFormData });
    } else {
      setDoc(insuredRef, newFormData, { merge: true });
    }
    setEnableEditing(false);
    setSuccess(true);
  };

  const convertToDate = (timestamp) => {
    if (timestamp && typeof timestamp === 'object' && '_seconds' in timestamp) {
      return new Date(timestamp._seconds * 1000);
    }
    return null;
  };

  const handleApiPolicyData = async () => {
    const data = await apiService.renewalProcessPolicyData({ policyID });
    setFormData(data?.insuredData); 
    const establishedDate = data?.insuredData?.dateEstablished
    const establishedDateValue = convertToDate(establishedDate);
    if (establishedDateValue) {
      setDateEstablished(establishedDateValue);
    }
  }

  const getCompanyInfo = (agencyID) => {
    if (!agencyID) {
      return () => {};
    }
    if (Boolean(moduleValue)) {
      handleApiPolicyData()
      return () => {};
    }
    const docRef = doc(db, "agencies", agencyID, "insureds", insuredsID);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setFormData({ ...docSnap.data(), id: docSnap.id });
        if (docSnap.data()?.dateEstablished) {
          setDateEstablished(docSnap.data().dateEstablished.toDate());
        }
      } else {
        console.log("No such document!");
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = getCompanyInfo(agencyID);
    return () => unsubscribe();
  }, [agencyID]);

  const calculateYearsInBusiness = (date) => {
    const today = new Date();
    let yearsDifference = today.getFullYear() - date.getFullYear();
    const monthDifference = today.getMonth() - date.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < date.getDate())) {
      yearsDifference--;
    }
    return yearsDifference;
  }

  return (
    <>
      <fieldset disabled={!enableEditing}>
        <form onSubmit={(e) => saveForm(e)}>
          <div>
            <div className="mb-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-600"
              >
                {t('plm.coverage-side-panel.insured-table.company-name')}
              </label>
              <input
                name="company"
                type="text"
                value={formData.company}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-600"
              >
                {t('plm.coverage-side-panel.insured-table.company-email-address')}
              </label>
              <input
                name="email"
                type="text"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="primaryContact"
                className="block text-sm font-medium text-gray-600"
              >
                {t('plm.coverage-side-panel.insured-table.primary-contact-full-name')}
              </label>
              <input 
                name="primaryContact"
                type="text"
                value={formData.primaryContact}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="contactEmail"
                className="block text-sm font-medium text-gray-600"
              >
                {t('plm.coverage-side-panel.insured-table.primary-contact-email')}
              </label>
              <input
                name="contactEmail"
                type="text"
                value={formData.contactEmail}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            
            <div className="my-4">
              <div className="mb-2">
                <label
                  htmlFor="mailingAddress"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.insured-table.mailing-address')}
                </label>
                <input
                  name="mailingAddress"
                  type="text"
                  value={formData.mailingAddress}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="mailingCity"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.ma-city')}
                  </label>
                  <input
                    name="mailingCity"
                    type="text"
                    value={formData.mailingCity}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div>
                  <label
                    htmlFor="mailingState"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.ma-state')}
                  </label>
                  <select
                    name="mailingState"
                    value={formData.mailingState}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="">Select a state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div className="mb-2 grid grid-cols-3 gap-4">
                <div>
                  <label
                    htmlFor="mailingZip"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.ma-zip')}
                  </label>
                  <input
                    name="mailingZip"
                    type="text"
                    value={formData.mailingZip}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div className="mb-2">
                  <label
                    htmlFor="countryCode"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.company-country-code')}
                  </label>
                  <select
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="+1">USA (+1)</option>
                    <option value="+52">Mexico (+52)</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.company-phone-number')}
                  </label>
                  <input
                    name="phone"
                    type="number"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
              <div className="mb-2 grid grid-cols-2 gap-4">
                <div className="mb-2">
                  <label
                    htmlFor="contactCountryCode"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.contact-country-code')}
                  </label>
                  <select
                    name="contactCountryCode"
                    value={formData.contactCountryCode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="+1">USA (+1)</option>
                    <option value="+52">Mexico (+52)</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="contactPhone"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.contact-phone-number')}
                  </label>
                  <input
                    name="contactPhone"
                    type="number"
                    value={formData.contactPhone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
            </div>

            <div className="my-4">
              <div className="mb-2">
                <label
                  htmlFor="physicalAddress"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.insured-table.physical-address')}
                </label>
                <input
                  name="physicalAddress"
                  type="text"
                  value={formData.physicalAddress}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="physicalCity"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.pa-city')}
                  </label>
                  <input
                    name="physicalCity"
                    type="text"
                    value={formData.physicalCity}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div>
                  <label
                    htmlFor="physicalState"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.pa-state')}
                  </label>
                  <select
                    name="physicalState"
                    value={formData.physicalState}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="">Select a state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div className="mb-2 grid grid-cols-3 gap-4">
                <div>
                  <label
                    htmlFor="physicalZip"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t('plm.coverage-side-panel.insured-table.pa-zip')}
                  </label>
                  <input
                    name="physicalZip"
                    type="text"
                    value={formData.physicalZip}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
            </div>

            <div className="mb-2">
              <label
                htmlFor="businessStructure"
                className="block text-sm font-medium text-gray-600"
              >
                {t('plm.coverage-side-panel.insured-table.business-structure')}
              </label>
              <select
                name="businessStructure"
                value={formData.businessStructure}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Choose One</option>
                <option value="Sole Proprietorship">Sole Proprietorship</option>
                <option value="Partnership">Partnership</option>
                <option value="LLC">LLC</option>
                <option value="Corporation">Corporation</option>
              </select>
            </div>

            <div className="mb-2 grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="federalTaxID"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.insured-table.federal-tax-id')}
                </label>
                <input
                  name="federalTaxID"
                  type="text"
                  value={formData.federalTaxID}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div>
                <label
                  htmlFor="yearEstablished"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.insured-table.year-established')}
                </label>
                <input
                  name="yearEstablished"
                  type="number"
                  value={formData.yearEstablished}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </div>

            <div className="mb-2 grid grid-cols-2 gap-4">
              <div>
                <label
                  className="block text-sm font-medium text-gray-600"
                  htmlFor="dateEstablished"
                >
                  {t('plm.coverage-side-panel.insured-table.date-reinstatement')}
                </label>
                <DatePicker
                  name="dateEstablished"
                  wrapperClassName="datePicker"
                  selected={dateEstablished}
                  maxDate={new Date()}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      dateEstablished: date,
                    })
                    setDateEstablished(date)
                    const yearsInBusiness = calculateYearsInBusiness(date)
                    setFormData({
                      ...formData,
                      yearsInBusiness: yearsInBusiness,
                    })
                  }}
                  autoComplete="dateEstablished-input"
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div>
                <label
                  htmlFor="yearsInBusiness"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.insured-table.year-in-business')}
                </label>
                <input
                  name="yearsInBusiness"
                  type="number"
                  disabled={true}
                  value={formData.yearsInBusiness}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </div>
          </div>

          {enableEditing && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-[#072a48] focus:outline-none focus:ring focus:border-blue-300"
              >
                {t('plm.coverage-side-panel.insured-table.submit')}
              </button>
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:border-gray-500"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({});
                  setEnableEditing(false);
                }}
              >
                {t('plm.coverage-side-panel.insured-table.cancel')}
              </button>
            </div>
          )}
        </form>
      </fieldset>
      {success && (
        <main className="bg-green-100 p-6 rounded-md shadow-md text-green-700">
          <p className="text-2xl font-semibold mb-4">
            {t('plm.coverage-side-panel.insured-table.success')}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              setSuccess(false);
            }}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            OK
          </button>
        </main>
      )}
    </>
  );
}
