import React, { useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import ApiService from '../../services/southern-api/apiService';
import useGeneralState from '../../Context/actions/useGeneralState';
import LoadingScreen from "react-loading-screen";
import MultiUpload from '../assets/forms/DynamicPropertiesInputs/MultiUpload';

const SubmitReport = () => {
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [expectedResult, setExpectedResult] = useState('');
  const [actualResult, setActualResult] = useState('');
  const [errorLocation, setErrorLocation] = useState('');
  const [customLocation, setCustomLocation] = useState('');
  const [bugReportsDocs, setBugReportsDocs] = useState([]);
  const [user] = useAuthState(auth);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { controlToast } = useGeneralState()

  const apiService = new ApiService();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !description || !notes ||
      !expectedResult || !actualResult ||
      (!errorLocation || (errorLocation === 'Other' && !customLocation)) ||
      !Boolean(bugReportsDocs?.length)
    ) {
      setError('Please fill in all fields');
      setLoading(false);
      controlToast(true, 'Please fill in all fields', 'error')
      return;
    }
    setError('');
    setSuccess('');
    try {
      const payload = {
        description,
        expectedResult,
        actualResult,
        notes,
        bugReportsDocs,
        errorLocation: errorLocation === 'Other' ? customLocation : errorLocation,
        createdAt: Timestamp.fromDate(new Date()),
        userId: user ? user.uid : null,
        userEmail: user ? user.email : null,
        status: 'Submitted'
      }
      await addDoc(collection(db, 'bugReports'), payload);
      setDescription('');
      setNotes('');
      setExpectedResult('');
      setActualResult('');
      setBugReportsDocs([]);
      setErrorLocation('');
      setCustomLocation('');
      setSuccess('Report submitted successfully');
      await apiService.bugReportSubmissionConfirmation(payload)
      setLoading(false);
      controlToast(true, 'Report submitted successfully', 'success')
    } catch (err) {
      setLoading(false);
      setError('Error submitting report');
      controlToast(true, 'There was an error trying to submit the bug report', 'error')
    }
  };

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      padding="0"
      logoSrc=""
      text={`Sending Bug Report...`}
    >
      <Container maxWidth="sm">
        <Box mt={5}>
          <Typography variant="h4" gutterBottom>
            Submit a Bug Report
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="primary">{success}</Typography>}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              variant="outlined"
            />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Error Location</InputLabel>
              <Select
                value={errorLocation}
                onChange={(e) => setErrorLocation(e.target.value)}
                label="Error Location"
              >
                <MenuItem value="Policy">Policy</MenuItem>
                <MenuItem value="Endorsements">Endorsements</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            {errorLocation === 'Other' && (
              <TextField
                fullWidth
                label="Custom Location"
                value={customLocation}
                onChange={(e) => setCustomLocation(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            )}
            <TextField
              fullWidth
              label="Expected Result"
              value={expectedResult}
              onChange={(e) => setExpectedResult(e.target.value)}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="Actual Result"
              value={actualResult}
              onChange={(e) => setActualResult(e.target.value)}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <MultiUpload
              label="Bug Report Documents"
              property="bug-report-docs"
              formData={bugReportsDocs}
              setFormData={setBugReportsDocs}
              storageFile={`files/bug-reports/${user && user?.uid ? user?.uid : 'no-user'}`}
              multiple={true}
              changeNormal={true}
              fileInButton={true}
              width="100%"
              dissapearWhenImgExists={true}
              showInAPopup={true}
              isViewMode={false}
              // disabled={isViewMode}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
        </Box>
      </Container>
    </LoadingScreen>
  );
};

export default SubmitReport;