import React, { useEffect, useState } from "react";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Popup from "../../generals/Popup";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useTranslations from "../../../Context/actions/useTranslations";
import {
  Save as SaveIcon,
  ModeEdit as PencilIcon,
} from "@mui/icons-material";
import { db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";

const TruckModal = ({
  truck,
  isOpen,
  onClose,
  contentLabel,
  formData,
  policyID,
}) => {
  const { controlToast } = useGeneralState();
  const { t } = useTranslations();

  const policyDetailToShow = truck?.policiesDetails?.find(
    (policy) => policy?.id === policyID
  );

  const breadcrumbs = [
    { label: t("plm.units.modal.breadcrumb.title") },
    { label: t("plm.units.modal.breadcrumb.details") },
  ];

  const [edit, setEdit] = useState({
    truckACV: false,
  });

  const [form, setForm] = useState({
    truckACV: 0,
  })

  useEffect(() => {
    setForm({
      truckACV: policyDetailToShow?.truckACV || 0,
    })
  }, [truck, isOpen])

  const handleEdit = async (field) => {
    try {
      if (Boolean(edit[field])) {
        const powerUnitRef = doc(
          db,
          "agencies",
          formData?.agencyID,
          "insureds",
          formData?.insuredID,
          "power-units",
          truck.id
        );
        const policiesDetails = truck.policiesDetails.map((policyDetail) => {
          if (policyDetail.id === policyID) {
            policyDetail['truckACV'] = parseFloat(form.truckACV);
          }
          return policyDetail;
        })
        const newTruck = {
          ...truck,
          policiesDetails
        }
        await setDoc(powerUnitRef, newTruck, { merge: true });
      }
      setEdit({
        ...edit,
        [field]: !edit[field],
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={onClose}
      onClose={onClose}
      contentLabel={contentLabel}
      title="Power Unit Information"
    >
      <div className="modal-content">
        <Breadcrumbs items={breadcrumbs} />
        <p className="text-2xl font-semibold mb-3 mt-2">
          {t("plm.units.modal.truck-information")}
        </p>
        <section>
          <table className="table">
            <tbody>
              <tr>
                <td>VIN:</td>
                <td>
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(truck?.truckVIN);
                        controlToast(
                          true,
                          "The VIN was copied to clipboard",
                          "success"
                        );
                      }}
                      className="copy-button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-copy mr-1"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                        />
                      </svg>
                    </button>
                    {truck?.truckVIN}
                  </div>
                </td>
              </tr>
              {formData?.hasALCoverage && (
                <tr>
                  <td>{t("plm.units.modal.rating")}:</td>
                  <td>{policyDetailToShow?.rating}</td>
                </tr>
              )}
              <tr>
                <td>{t("plm.units.modal.year")}:</td>
                <td>{truck?.truckYear}</td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.make")}:</td>
                <td>{truck?.truckMake}</td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.model")}:</td>
                <td>{truck?.truckModel}</td>
              </tr>
              {formData.documentType === "Application" ? (
                <tr>
                  <td>{t("plm.units.modal.actual-cash-value")}:</td>
                  <td>
                    <div className="flex justify-between">
                      <input
                        type="number"
                        value={form.truckACV}
                        onChange={(e) => setForm({ ...form, truckACV: e.target.value })}
                        disabled={!edit.truckACV}
                      />
                      <div
                        onClick={() => handleEdit("truckACV")}
                        className="cursor-pointer"
                      >
                        {!edit.truckACV ?
                          <PencilIcon sx={{ color: "red" }} /> :
                          <SaveIcon sx={{ color: "blue" }} />    
                        }
                      </div>
                    </div>
                  </td>
                </tr>
              ) :
              <tr>
                <td>{t("plm.units.modal.actual-cash-value")}:</td>
                <div>{policyDetailToShow?.truckACV || 0}</div>
              </tr>
              }
              <tr>
                <td>{t("plm.units.modal.radius")}:</td>
                <td>{policyDetailToShow?.truckRadius}</td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.class")}:</td>
                <td>{truck?.truckClass}</td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.weight")}:</td>
                <td>{truck?.truckWeight}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </Popup>
  );
};

export default TruckModal;
