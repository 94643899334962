import updatePolicyField from "../../../utils/updatePolicyField";

const updateCommodityTotal = async (policyID, formData) => {
  let customCommodityTotal = 0;
  formData.commodityFields.forEach((element) => {
    customCommodityTotal =
      parseInt(customCommodityTotal, 10) + parseInt(element.commodityLoad, 10);
  });
  const standardCommoditiesTotal = Object.values(
    formData.standardCommodityPercentages ?? {}
  )
    .filter((value) => !isNaN(parseInt(value, 10))) // Filter out non-integer values
    .reduce((a, b) => a + parseInt(b, 10), 0);
  const total =
    parseInt(customCommodityTotal, 10) + parseInt(standardCommoditiesTotal);
  if (policyID !== null) await updatePolicyField(policyID, "commodityTotal", total);
  return total;
};

export default updateCommodityTotal;
