import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import processDriverData from "../listeners/processDriverData";
import { db } from "../../../../firebase";

const searchByName = async (searchValue) => {
    const fullNameParts = searchValue.split(' ');
    const firstName = fullNameParts[0];
    const lastName = fullNameParts.slice(1).join(' ');
    console.log('searching by name')
    console.log('first: ', firstName);
    console.log('last: ', lastName);
    // Fetch Driver Info
    const q = query(
      collectionGroup(db, "drivers"),
      where("driverFirst", "==", firstName),
      where("driverLast", "==", lastName)
    );
    const querySnapshot = await getDocs(q);
    const driversMap = processDriverData(querySnapshot);
    console.log(driversMap)
    // convert driversmap to an array
    const driversArray = Object.values(driversMap)
    console.log(driversArray)
    return driversArray
  }

export default searchByName