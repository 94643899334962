import React, { useState } from "react";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import { ACCOUNT_ROUTE, ADMIN_ROUTE } from "../../utils/routes";
import TaxManager from "../../Components/Admin/TaxManager";
import Notifications from "../../Components/Admin/Notifications";
import ManageUnderwriters from "../../Components/Admin/ManageUsers";
import FleetCreditManager from "../../Components/Admin/FleetCreditManager";

export default function Admin({ userInfo }) {
  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: "Admin Panel", link: ADMIN_ROUTE },
  ];

  const [value, setValue] = useState("");

  return (
    <main>
      {userInfo.role === "Admin" && (
        <div className="my-4 mx-auto bg-white rounded-md shadow-md p-6">
          <Breadcrumbs items={breadcrumbs} />
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
            <span className="text-3xl font-semibold mb-4 md:mb-0">
              Admin Panel
            </span>
          </div>
          <p className="mb-4">
            Here you may adjust notifications, taxes and fees, and more.
          </p>

          <p className="mb-4">Choose an action to get started.</p>
          <select
            onChange={(e) => {
              setValue(e.target.value);
            }}
            className="p-2 w-full border rounded"
          >
            <option value="">Make A Selection</option>
            <option value="manageUnderwriters">Manage Underwriters</option>
            <option value="manageAdmins">Manage Admins</option>
            <option value="Notification">Manage Notifications</option>
            <option value="Taxes">Manage Taxes & Fees</option>
            <option value="fleetCredits">Manage Fleet Credits</option>
          </select>
          {value === "Notification" && <Notifications userInfo={userInfo} />}
          {value === "manageUnderwriters" && (
            <ManageUnderwriters userInfo={userInfo} userRole={"Underwriter"} />
          )}
          {value === "manageAdmins" && <></>}
          {value === "Taxes" && <TaxManager userInfo={userInfo} />}
          {value === "fleetCredits" && <FleetCreditManager userInfo={userInfo} />}
        </div>
      )}
    </main>
  );
}
