import React from "react";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../../../firebase";
import deleteFileFromStorage from "../../../utils/deleteFromStorage";
import updatePolicyField from "../../../utils/updatePolicyField";
import { confirmDeletePromptGlobal } from "../../../../layout/ConfirmDeleteProvider";
import useTranslations from "../../../../Context/actions/useTranslations";

const FileDisplayAndReplacement = ({
  year,
  policyID,
  formData,
  setFormData,
  setProgressState,
  coverageType
}) => {

  const { t } = useTranslations();

  return (
    <div>
      {t("plm.losses.apd-mtc.loss-runs-uploaded")}:{" "}
      <a
        href={formData[`year${year}${coverageType}LossRunsFile`]}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sky-900 hover:text-[#072a48]"
      >
        {t("plm.losses.apd-mtc.view")}
      </a>{" "}
      {formData.documentType === "Application" &&
        !formData.lossHistory?.[`year${year}LossData`] && (
          <>
            {" | "}
            <button
              className="text-[#8B0000]"
              onClick={async (e) => {
                let verify = await confirmDeletePromptGlobal(t("plm.losses.apd-mtc.delete-confirmation"));
                if (verify === "DELETE") {
                  updatePolicyField(policyID, `year${year}LossRunsFile`, "");
                  deleteFileFromStorage(formData[`year${year}LossRunsFile`]);
                  setProgressState(0);
                }
              }}
            >
              {t("plm.losses.apd-mtc.delete")}
            </button>
          </>
        )}
    </div>
  );
};

export default FileDisplayAndReplacement;
