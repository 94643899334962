import countAllViolations from "../countAllViolations";
import getScoreWeights from "../scores/getScoreWeights";
import scoreAge from "../scores/scoreAge";
import scoreClaimsAmount from "../scores/scoreClaimsAmount";
import scoreExp from "../scores/scoreExp";
import scoreMajor from "../scores/scoreMajor";
import scoreMinor from "../scores/scoreMinor";
import scoreNotIncluded from "../scores/scoreNotIncluded";
import scoreNumberOfClaims from "../scores/scoreNumberOfClaims";
import scoreOutOfDriving from "../scores/scoreOutOfDriving";

const aggregateDriverInfo = async (
  violations,
  drivers,
  crashes,
  setDataReady,
  inspections,
  driversIsArray = false,
  searchType
) => {
  const aggregatedDrivers = {};
  console.log('search type: ', searchType);
  console.log('initial drivers: ', drivers)
  // if drivers is array of objects, convert it to an object with each objects licenseNumber as the key
  if (driversIsArray) {
    console.log('drivers is array true')
    const driversObj = {};
    drivers.forEach((driver) => {
      driversObj[driver.licenseNumber] = driver;
    });
    drivers = driversObj;
  }
  console.log('drivers: ', drivers)


  // Step 1: Process violations
  violations.forEach((violation) => {
    const licenseNumber = violation?.drvLicNbr ? violation.drvLicNbr : null;
    
    // If the driver doesn't exist, add it to the aggregated drivers.
    if (!aggregatedDrivers[licenseNumber]) {
      aggregatedDrivers[licenseNumber] = {
        ...drivers[licenseNumber],
        onPoliciesCount: 0,
        majorViolationsCount: 0,
        minorViolationsCount: 0,
        caseByCaseCount: 0,
        claimsCount: 0,
        claimsCountScore: 0,
        claimsAmountScore: 0,
        claimsAmount: 0,
        crashes: [],
        crashesCount: 0,
        inspections: [],
        inspectionsCount: 0,
        violations: [],
        notIncludedCount: 0,
        outOfDrivingCount: 0,
      };
    }
    
    // Get the on policies count
    aggregatedDrivers[licenseNumber].onPoliciesCount +=
      drivers[licenseNumber]?.policies?.length;

      // Count finalClassification to determine major/minor violations counts
    // if (violation.finalClassification === "Major") {
    //   console.log('major violation')
    //   aggregatedDrivers[licenseNumber].majorViolationsCount += 1;
    // } else if (violation.finalClassification === "Minor") {
    //   console.log('minor violation')
    //   console.log('aggregatedDrivers[licenseNumber]', aggregatedDrivers[licenseNumber])
    //   aggregatedDrivers[licenseNumber].minorViolationsCount += 1;
    // } else if (violation.finalClassification === "Not included") {
    //   aggregatedDrivers[licenseNumber].notIncludedCount += 1;
    // } else if (violation.finalClassification === "Out of Driving") {
    //   aggregatedDrivers[licenseNumber].outOfDrivingCount += 1;
    // } else if (violation.finalClassification === "Case by Case") {
    //   aggregatedDrivers[licenseNumber].caseByCaseCount += 1;
    // }

    // Add the violation to the driver's violations array
    aggregatedDrivers[licenseNumber].violations.push(violation);
  });

  // Step 2: Join crashes
  crashes.forEach((crash) => {
    const licenseNumber = crash?.drvLicNbr;
    if (aggregatedDrivers[licenseNumber]) {
      // If the driver exists, add the crash to the driver's crashes array.
      aggregatedDrivers[licenseNumber].crashes.push(crash);
      aggregatedDrivers[licenseNumber].crashesCount += 1;
    } else {
      // If the driver doesn't exist, add it to the aggregated drivers with 1 crash.
      aggregatedDrivers[licenseNumber] = {
        ...drivers[licenseNumber],
        onPoliciesCount: 0,
        majorViolationsCount: 0,
        minorViolationsCount: 0,
        caseByCaseCount: 0,
        claimsCount: 0,
        claimsCountScore: 0,
        claimsAmountScore: 0,
        claimsAmount: 0,
        crashes: [crash],
        crashesCount: 1,
        inspections: [],
        inspectionsCount: 0,
        violations: [],
        notIncludedCount: 0,
        outOfDrivingCount: 0,
      };
    }
  });

  // Step 3: Perform other aggregations
  await Promise.all(
    Object.keys(aggregatedDrivers).map(async (licenseNumber) => {
      const counts = await countAllViolations(
        aggregatedDrivers[licenseNumber].violations,
        licenseNumber,
        crashes
      );
      const weights = await getScoreWeights();
      const ageScore = await scoreAge(aggregatedDrivers[licenseNumber].dob);
      const expScore = await scoreExp(aggregatedDrivers[licenseNumber].yoe);
      const majorScore = await scoreMajor(counts.majorViolationsCount);
      const minorScore = await scoreMinor(counts.minorViolationsCount);
      const outOfDrivingScore = await scoreOutOfDriving(
        counts.outOfDrivingCount
      );
      const notIncludedScore = await scoreNotIncluded(counts.notIncludedCount);

      // Apply weights
      const weightedAge = ageScore * weights.age;
      const weightedExp = expScore * weights.exp;
      const weightedMajor = majorScore * weights.major;
      const weightedMinor = minorScore * weights.minor;
      const weightedOutOfDriving = outOfDrivingScore * weights.outOfDriving;
      const weightedNotIncluded = notIncludedScore * weights.notIncluded;

      const scoreTotal =
        weightedAge +
        weightedExp +
        weightedMajor +
        weightedMinor +
        weightedOutOfDriving +
        weightedNotIncluded;

      const result = scoreTotal > 0.6 ? "Need Training" : "No Training Needed";

      aggregatedDrivers[licenseNumber] = {
        ...aggregatedDrivers[licenseNumber],
        ...counts,
        claimsAmountScore: await scoreClaimsAmount(counts.claimsAmount),
        claimsCountScore: await scoreNumberOfClaims(counts.claimsCount),
        ageScore,
        expScore,
        majorScore,
        minorScore,
        outOfDrivingScore,
        notIncludedScore,
        weightedAge,
        weightedExp,
        weightedMajor,
        weightedMinor,
        weightedOutOfDriving,
        weightedNotIncluded,
        scoreTotal,
        result,
        crashes: aggregatedDrivers[licenseNumber].crashes,
      };
    })
  );

  setDataReady(true);
  return Object.values(aggregatedDrivers);
};

export default aggregateDriverInfo;
