import React from "react";
import { formatPrice } from "../../../Finances/Tables/utils";
import useTranslations from "../../../../Context/actions/useTranslations";

const LossDataDisplay = ({ formData, year, resetLosses, coverageType }) => {

  const { t } = useTranslations()

  return (
    <div className="my-4">
      <article>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.loss-ratio")}: </span>
          {parseFloat(formData.lossHistory?.[`year${year + "" + coverageType}LossData`].lossRatio).toFixed(2)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.annual-premium")}: </span>
          {formatPrice(
            formData.lossHistory?.[`year${year + "" + coverageType}LossData`].annualPremium
          )}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.carrier")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].carrier}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.number-of-units")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].numberOfUnits}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.premium-ppu")}: </span>
          {formatPrice(formData.lossHistory?.[`year${year + "" + coverageType}LossData`].premiumPPU)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.losses")}: </span>
          {formatPrice(formData.lossHistory?.[`year${year + "" + coverageType}LossData`].losses)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.open-bi-claims")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].openBIClaims}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.open-at-fault-bi-claims")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].openAtFaultBIClaims}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("plm.losses.al-gl.total-open-claims")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].totalOpenClaims}
        </p>
      </article>
      {formData.documentType === "Application" && (
        <button
          onClick={(e) => resetLosses(e, `year${year + "" + coverageType}LossData`)}
          className="bg-[#8B0000] text-white w-full p-2 mt-4 rounded"
        >
          {t("plm.losses.al-gl.reset")}
        </button>
      )}
    </div>
  );
};

export default LossDataDisplay;
