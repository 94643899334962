import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import useTranslations from "../../Context/actions/useTranslations";
import TableSkeleton from "../generals/TableSkeleton";
import UserListCheckbox from "./UserListCheckbox";
import Pagination from "../assets/lists/DynamicTable/Pagination";
import { db } from "../../firebase";
import { Tooltip } from "@mui/material";

const usersPerPage = 10;

function UserList({ userList, setUserList, userInfo, userRole }) {
  const initialSortConfig = {
    key: "",
    direction: "asc",
  };

  const { t } = useTranslations();

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (userInfo.agencyID) {
      const usersCollection = collection(db, "users");
      const q = query(
        usersCollection,
        where("role", "==", userRole)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedUserList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserList(updatedUserList);
        setLoading(false)
      });
      return () => unsubscribe();
    }
  }, [userInfo.agencyID]);

  const filteredData = userList.filter((user) => {
    const {
      first,
      last,
      email,
      userPermissions,
      policyPermissions,
      applicationPermissions
    } = user
    return (
      `${first} ${last} ${email} ${userPermissions} ${policyPermissions} ${applicationPermissions}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    )
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * usersPerPage;
  const currentItems = sortedData.slice(offset, offset + usersPerPage);

  const pageCount = Math.ceil(sortedData.length / usersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updatePerms = async (e, userID) => {
    const field = e.target.name;
    const value = e.target.value;
    try {
      // Update the Firestore document with the new permissions and endorsements
      const userRef = doc(db, "users", userID);
      await updateDoc(userRef, {
        [field]: value,
      });

      console.log("Permissions and endorsements updated successfully!");
    } catch (error) {
      console.error("Error updating permissions and endorsements: ", error);
    }
  };

  const userCanView =
    userInfo.userPermissions === true ||
    userInfo.userPermissions === true;
  const userCanModify = userInfo.userPermissions === true;
  const permissions = userInfo.userPermissions;
  const userIsOwner = userInfo.companyOwner;
  const role = userInfo.role;
  const userIsAdmin = role === 'Admin'

  return (
    <main>
      {loading ? <TableSkeleton rows={5} columns={8} /> :
      (permissions && userCanView) ||
      userIsOwner || userIsAdmin ? (
        <div>
          {userList.length === 0 ? (
            <p>{t("agent.add-one-user")}</p>
          ) : (
            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="my-2 p-2 border rounded w-full"
              />
              <fieldset
                disabled={!permissions && !userIsOwner && !userCanModify && !userIsAdmin}
              >
                <Table>
                  <Thead>
                    <Tr>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
                          {
                            "hover:bg-[#072a48]": sortConfig.key !== "first",
                            "bg-blue-500": sortConfig.key === "first",
                          }
                        )}
                        onClick={() => handleSort("first")}
                      >
                        {t("Full Name")}
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white cursor-pointer",
                          {
                            "hover:bg-[#072a48]": sortConfig.key !== "email",
                            "bg-blue-500": sortConfig.key === "email",
                          }
                        )}
                        onClick={() => handleSort("email")}
                      >
                        {t("E-Mail Address")}
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white cursor-pointer"
                        )}
                      >
                        Has Bind Authority
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white cursor-pointer"
                        )}
                      >
                        Has Finance Authority
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white cursor-pointer",
                          {
                            "hover:bg-[#072a48]":
                              sortConfig.key !== "userPermissions",
                            "bg-blue-500": sortConfig.key === "userPermissions",
                          }
                        )}
                        onClick={() => handleSort("userPermissions")}
                      >
                        {t("Users/Company")}
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white cursor-pointer",
                          {
                            "hover:bg-[#072a48]":
                              sortConfig.key !== "applicationPermissions",
                            "bg-blue-500": sortConfig.key === "applicationPermissions",
                          }
                        )}
                        onClick={() => handleSort("applicationPermissions")}
                      >
                        Applications
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white rounded-tr-md cursor-pointer",
                          {
                            "hover:bg-[#072a48]":
                              sortConfig.key !== "policyPermissions",
                            "bg-blue-500": sortConfig.key === "policyPermissions",
                          }
                        )}
                        onClick={() => handleSort("policyPermissions")}
                      >
                        {t("Endorsements")}
                      </Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems.map((user) => (
                      <Tr key={user.id}>
                        <Td>
                          <span title={`User ID: ${user.id}`}>
                            {user.first + " " + user.last}
                            {user.companyOwner && "*"}
                          </span>
                        </Td>
                        <Td>{user.email}</Td>
                        <Td>
                          <UserListCheckbox
                            name={"hasBindAuthority"}
                            userData={user}
                            field={"hasBindAuthority"}
                            userID={user.id}
                          />
                        </Td>
                        <Td>
                          <UserListCheckbox
                            name={"hasFinanceAuthority"}
                            userData={user}
                            field={"hasFinanceAuthority"}
                            userID={user.id}
                          />
                        </Td>
                        <Td>
                          <select
                            disabled={user.companyOwner && !userIsAdmin}
                            value={user.userPermissions}
                            name="userPermissions"
                            onChange={(e) => {
                              updatePerms(e, user.id);
                            }}
                            className="block py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                          >
                            <option value="None">{t("agent.none")}</option>
                            <option value="Read Only">
                              {t("agent.read-only")}
                            </option>
                            <option value="Modify">
                              {t("agent.read-modify")}
                            </option>
                          </select>
                        </Td>
                        <Td>
                          <select
                            disabled={user.companyOwner && !userIsAdmin}
                            value={user.policyPermissions}
                            name="policyPermissions"
                            onChange={(e) => {
                              updatePerms(e, user.id);
                            }}
                            className="block py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                          >
                            <option value="None">{t("agent.none")}</option>
                            <option value="Read Only">
                              {t("agent.read-only")}
                            </option>
                            <option value="Modify">
                              {t("agent.read-modify")}
                            </option>
                          </select>
                        </Td>
                        <Td>
                          <select
                            disabled={user.companyOwner && !userIsAdmin}
                            value={user.endoPermissions}
                            name="endoPermissions"
                            onChange={(e) => {
                              updatePerms(e, user.id);
                            }}
                            className="block py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                          >
                            <option value="None">{t("agent.none")}</option>
                            <option value="Read Only">
                              {t("agent.read-only")}
                            </option>
                            <option value="Modify">
                              {t("agent.read-modify")}
                            </option>
                          </select>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </fieldset>
              <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      ) : (
        <p>
          {/* TODO: Add a request permission button. Low priority. */}
          You do not have permission to view this page. Please contact your
          company administrator.
        </p>
      )}
    </main>
  );
}

export default UserList;
