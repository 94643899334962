import { arrayUnion, doc, getDoc, runTransaction } from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import getFleetCredit from "./getFleetCredit";
import updateSystemLog from "./updateSystemLog";
import assignPolicyNumber from "./assignPolicyNumber";
import { formatDateToYYYYMMDD } from "../Finances/Tables/utils";

const bindPolicy = async (
  formData,
  policyID,
  powerUnitCount,
  driverCount,
  trailerCount,
  userInfo,
  insuredInfo,
  nextAL,
  nextGL,
  insuredID,
  userAuth,
  userState,
  formDataGL
) => {
  const agencyID = formData.agencyID;
  const isReferral = formData.isReferral;
  const referralApproved = formData.referralApproved;

  if (!Boolean(userInfo?.hasBindAuthority)) {
    return alert("You do not have permissions to bind policies. Contact your administrator.");
  }

  if (isReferral && !referralApproved) {
    return alert("This policy is a referral and has not been approved yet.");
  }
  
  const policyRef = doc(db, "policy-applications", formData.id);
  const insuredRef = doc(
    db,
    "agencies",
    userInfo.agencyID,
    "insureds",
    insuredInfo.id
  );

  // Check if a new identifier is needed for the policy and get the next policy numbers.
  const policyNumberResults = await assignPolicyNumber(
    formData,
    formDataGL,
    insuredID,
    agencyID,
    insuredInfo
  );
  const useNewIdentifier = policyNumberResults[1];
  const newALPolicyNumber = policyNumberResults[0].nextAL;
  const newGLPolicyNumber = policyNumberResults[0].nextGL;
  const glPolicyNo = useNewIdentifier ? newGLPolicyNumber : nextGL;
  const alPolicyNo = useNewIdentifier ? newALPolicyNumber : nextAL;

  // Get the fleet credit for the policy.
  const fleetCredit = await getFleetCredit(powerUnitCount);
  // Get the adjustment data.
  const adjustmentFormDataRef = doc(db, "policy-applications", policyID, "quotes", formData.selectedQuote);
  const adjustmentFormDataSnapshot = await getDoc(adjustmentFormDataRef);
  const adjustmentFormData = adjustmentFormDataSnapshot.data().adjustmentFormData;

  const bindData = {
    powerUnitCountAtBind: powerUnitCount,
    bindDate: new Date(),
    boundBy: auth.currentUser.displayName,
    boundByID: auth.currentUser.uid,
    powerUnitCount,
    driverCount,
    trailerCount,
    fleetCredit,
    adjustments: adjustmentFormData,
    boundPremiums: {
      autoLiability: formData.alPremiums || "",
      generalLiability: formData.glPremiums || "",
    },
    coverages: {
      hasALCoverage: formData.hasALCoverage || false,
      hasGLCoverage: formData.hasGLCoverage || false,
      hasAPDCoverage: formData.hasAPDCoverage || false,
      hasMTCCoverage: formData.hasMTCCoverage || false,
      hasNonOwned: formData.hasNonOwned || false,
    },
    additionalInfo: {
      hasMCP65: formData.hasMCP65 || false,
      hasFormE: formData.hasFormE || false,
      has91X: formData.has91X || false,
    },
  };

  await runTransaction(db, async (transaction) => {
    const sourceDocRef = doc(db, "policy-applications", policyID);
    const sourceDocSnapshot = await getDoc(sourceDocRef);
    const documentType = "Policy";
    const status = "Bound";
    const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
    const agencyID = sourceDocSnapshot.data().agencyID;

    const policyGLRef = doc(db, "gl-policies", formDataGL.id);

    const formattedDate = formatDateToYYYYMMDD(new Date())

    const rootLevelData = {
      status,
      documentType,
      inRenewal: false,
      IssuedDate: formattedDate,
      bindData,
      isActive: true
    };
    const glPolicyData = {
      effectiveDate: formDataGL.effectiveDate,
      policyID,
      policyNumber: glPolicyNo,
    };
    const alPolicyData = {
      effectiveDate: formData.effectiveDate,
      program: formData.program,
      product: formData.product,
      coverageSingleLimit: formData.coverageSingleLimit,
      policyID,
      policyNumber: alPolicyNo,
      bindData,
    };

    if (formDataGL.hasGLCoverage) {
      if (formDataGL.id) {
        transaction.update(policyGLRef, {
          ...glPolicyData,
          ...rootLevelData,
          glPolicyNo,
          alPolicyNo,
          glPremiums: formDataGL?.glPremiums || null,
        });
      }
      transaction.update(insuredRef, {
        alPolicies: arrayUnion(alPolicyData),
        glPolicies: arrayUnion(glPolicyData),
      });
      transaction.update(policyRef, {
        ...rootLevelData,
        glPolicyNo,
        alPolicyNo,
        hasGLCoverage: formDataGL?.hasGLCoverage || false,
        glPremiums: formDataGL?.glPremiums || null,
      });
    } else {
      transaction.update(insuredRef, {
        alPolicies: arrayUnion(alPolicyData),
      });
      transaction.update(policyRef, { ...rootLevelData, alPolicyNo });
    }
    policyStatusChangeNotification(
      documentType,
      policyNumber,
      status,
      policyID,
      agencyID,
      formData,
      userAuth,
      userState,
      insuredInfo
    );
    updateSystemLog(
      auth,
      policyID,
      "Policy Bound for " + insuredInfo.company + ".",
      "Policy Bound",
      { agencyID, policyID }
    );
  });
};

export default bindPolicy;
