import React, { useState } from "react";
import CreatePolicyPanel from "../../Components/agent/policies/CreatePolicyPanel";
import PoliciesList from "../../Components/agent/policies/PoliciesList";
import Graphs from "../../Components/agent/policies/Graphs";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import { TextField, Button, Container, Typography } from "@mui/material";
import ApprovalButtons from "../../Components/assets/forms/ApprovalButtons";
import GoBack from "../../Components/generals/GoBack";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups"; // Using the Groups icon for the menu button
import PolicyIcon from "@mui/icons-material/Policy"; // Icon for "Group Policies"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"; // Icon for "Manage Groups"
import PolicyGroupsList from "../../Components/agent/policies/PolicyGroupsList";

export default function ManagePolicies({ userInfo, loading, setLoading }) {
  const [showGroupPoliciesPanel, setShowGroupPoliciesPanel] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [policyGroupList, setPolicyGroupList] = useState([]);
  const [policyList, setPolicyList] = useState([]);
  const [groupName, setGroupName] = useState("");
  const { t } = useTranslations();

  const clearForm = () => {
    setGroupName("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const policyGroupCollection = collection(db, "policyGroups");

    await addDoc(policyGroupCollection, {
      groupName,
      policies: [],
    });

    clearForm();
    setShowCreateGroup(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroupPolicies = () => {
    setShowGroupPoliciesPanel(true);
    handleClose(); // Close the dropdown after selecting
  };

  const handleManageGroups = () => {
    // Placeholder for the Manage Groups functionality
    handleClose();
  };

  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: t("breadcrumbs.manage-policies") },
  ];

  return (
    <div className="my-4 max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6">
      <Breadcrumbs items={breadcrumbs} />
      {showGroupPoliciesPanel && (
        <div className="mt-2">
          <GoBack
            handleFn={() => {
              setShowGroupPoliciesPanel(false);
              setShowCreateGroup(false);
              clearForm();
            }}
          />
        </div>
      )}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {!showGroupPoliciesPanel
            ? t("manage-policies.title")
            : t("manage-policies.group")}
        </span>
        {!showGroupPoliciesPanel && (
          <>
            {/* IconButton to open the menu */}
            <button
            type="button"
            className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
            onClick={handleClick}
          >
            {t("manage-policies.groups")}
          </button>

            {/* Menu that drops down when the icon button is clicked */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleGroupPolicies}>
                <ListItemIcon>
                  <PolicyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("manage-policies.group-policies")}</ListItemText>
              </MenuItem>

              <Divider />

              <MenuItem onClick={handleManageGroups}>
                <ListItemIcon>
                  <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("manage-policies.endorse-groups")}</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
        {showGroupPoliciesPanel && !showCreateGroup && (
          <button
            type="button"
            className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
            onClick={() => setShowCreateGroup(true)}
          >
            {t("manage-policies.create-new-group")}
          </button>
        )}
      </div>
      {!showGroupPoliciesPanel && (
        <>
          <p className="mb-4">{t("manage-policies.view-information")}</p>
          {/* // List of Policies */}
          <PoliciesList
            policyList={policyList}
            setPolicyList={setPolicyList}
            loading={loading}
            setLoading={setLoading}
            userInfo={userInfo}
          />
        </>
      )}
      {showGroupPoliciesPanel && (
        // Graphs for Insights From List of Policies
        <div className="">
          <p>{t("manage-policies.new-or-edit")}</p>
          {!showCreateGroup && (
            <PolicyGroupsList
              policyList={policyList}
              policyGroupList={policyGroupList}
              setPolicyGroupList={setPolicyGroupList}
              loading={loading}
              setLoading={setLoading}
              userInfo={userInfo}
            />
          )}
          {showCreateGroup && (
            <Container maxWidth="sm" className="mt-4">
              <Typography variant="h5" gutterBottom>
                {t("manage-policies.create-new-group")}
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label={t("manage-policies.group-name")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
                <ApprovalButtons
                  onDecline={() => setShowCreateGroup(false)}
                  handleAsSubmit={true}
                  titleAccept={t("manage-policies.create-group")}
                  titleDecline={t("manage-policies.cancel")}
                />
              </form>
            </Container>
          )}
        </div>
      )}
    </div>
  );
}
