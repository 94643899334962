import React, { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import AddNote from "./AddNote";
import NoteHistory from "./NoteHistory";
import Breadcrumbs from "../../../assets/Breadcrumbs";
import BlueButton from "../../../assets/forms/BlueButton";
import { useMediaQuery } from "@mui/material";
import submitNote from "../utils/submitNote";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function Notes(props) {
  const {
    formData,
    setFormData,
    documentID,
    collectionPath,
    userInfo,
    firstBreadcrumb,
    hideBreadcrumbs
  } = props;
  const [noteData, setNoteData] = useState({});
  const [note, setNote] = useState("");
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const { t } = useTranslations()

  const breadcrumbs = [
    { label: t(`plm.status.${firstBreadcrumb}`) },
    { label: t('plm.drivers.details.notes') }
  ];

  const [notesList, setNotesList] = useState([]);
  const [gotNotesList, setGotNotesList] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, collectionPath), orderBy("createdOn", "desc")),
      (snapshot) => {
        let values = [];
        snapshot.forEach((doc) => {
          values.push({ ...doc.data(), id: doc.id });
        });
        setNotesList(values);
        setGotNotesList(true);
      }
    );

    return () => unsubscribe();
  }, [documentID]);

  return (
    <main>
      {!hideBreadcrumbs && 
      <Breadcrumbs items={breadcrumbs} />}
      <h1 className="text-3xl mt-2 font-semibold mb-4">
        {t('plm.drivers.details.notes')}
      </h1>
      {!show ? (
        <BlueButton
          text={t('plm.drivers.details.add-note')}
          onClickFunction={() => {
            setShow(true);
          }}
          disabled={false}
          hasContentToBottom={false}
          hasContentToRight={false}
          className={isMobileScreen ? "w-[100%] mb-2" : " mb-2"}
        />
      ) : (
        <AddNote
          noteData={noteData}
          formData={formData}
          userInfo={userInfo}
          documentID={documentID}
          setNoteData={setNoteData}
          setFormData={setFormData}
          note={note}
          setNote={setNote}
          submitNote={submitNote}
          setValue={setValue}
          value={value}
          setShow={setShow}
          collectionPath={collectionPath}
        />
      )}
      {/* Agent Note History */}
      <NoteHistory notesList={notesList} documentID={documentID} collectionPath={collectionPath} />
    </main>
  );
}
