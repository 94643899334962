import React from "react";
import updatePolicyField from "../../../utils/updatePolicyField";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function NamedShippers({
  formData,
  setFormData,
  policyID,
  formSaved,
  setFormSaved,
}) {

  const { t } = useTranslations();

    // TODO: Debounce these like others
  const handleAddNamedShipper = () => {
    setFormData({
      ...formData,
      namedShipperFields: [
        ...(formData.namedShipperFields || []),
        { namedShipperDescription: "", namedShipperLoad: "0" },
      ],
    });
  };

  const handleSubtractNamedShipper = async (e, i) => {
    e.preventDefault();
    setFormSaved(false);
    const values = [...formData.namedShipperFields];
    values.splice(i, 1);
    setFormData({ ...formData, namedShipperFields: values });
    if (values.length === 0) {
      setFormData({
        ...formData,
        namedShipperFields: [
          {
            namedShipperDescription: "",
            namedShipperLoad: "0",
          },
        ],
      });
    }
    updatePolicyField(policyID, "namedShipperFields", values);
  };

  const handleChangeNamedShipperName = (i, e) => {
    setFormSaved(false);
    const values = [...formData.namedShipperFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, namedShipperFields: values });
  };

  const handleChangeNamedShipperCommodities = (i, e) => {
    setFormSaved(false);
    const values = [...formData.namedShipperFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, namedShipperFields: values });
  };

  const handleChangeNamedShipperPercentage = (i, e) => {
    setFormSaved(false);
    const values = [...formData.namedShipperFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, namedShipperFields: values });
    updatePolicyField(policyID, "namedShipperFields", values);
    setFormSaved(true);
  };

  const handleBlurNamedShipper = async (e, i) => {
    if (formSaved) return;
    e.preventDefault();
    const policyRef = doc(db, "policy-applications", policyID);
    await updateDoc(policyRef, {
      namedShipperFields: formData.namedShipperFields
    });
    setFormSaved(true);
  };

  return (
    <div>
      <h2 className="text-lg text-[#072a48] font-semibold mb-2">
        {t("plm.mtc.named-shippers.title")}
      </h2>
      {!formData.namedShipperFields ||
      formData.namedShipperFields.length === 0 ? (
        <div>
          <p>
          {t("plm.mtc.named-shippers.no-shippers")}
          </p>
        </div>
      ) : (
        <>
          {formData.namedShipperFields.map((field, index) => (
            <div key={`namedShipper${index}`} className="my-3">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                <div className="flex flex-col">
                  <label className="text-sm font-semibold mb-1">
                    {t("plm.mtc.named-shippers.named-shipper")} {index + 1}:
                  </label>
                  <input
                    name="namedShipperDescription"
                    onChange={(e) => handleChangeNamedShipperName(index, e)}
                    onBlur={handleBlurNamedShipper}
                    className="p-2 border rounded focus:outline-none focus:border-blue-500"
                    type="text"
                    value={field.namedShipperDescription}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-semibold mb-1">
                    {t("plm.mtc.named-shippers.hauls")} {index + 1}:
                  </label>
                  <input
                    name="namedShipperLoad"
                    onChange={(e) =>
                      handleChangeNamedShipperPercentage(index, e)
                    }
                    onBlur={handleBlurNamedShipper}
                    className="p-2 border rounded focus:outline-none focus:border-blue-500"
                    type="number"
                    value={field.namedShipperLoad}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                  <label className="text-sm font-semibold mb-1 mt-2">
                    {t("plm.mtc.named-shippers.commodities")} {index + 1}:
                  </label>
                  <input
                    name="namedShipperCommodities"
                    onChange={(e) => handleChangeNamedShipperCommodities(index, e)}
                    onBlur={handleBlurNamedShipper}
                    className="p-2 border rounded focus:outline-none focus:border-blue-500"
                    type="text"
                    value={field.namedShipperCommodities}
                  />
                </div>
              {formData.documentType === "Application" && (
                <button
                  className="mt-2 text-[#8B0000] hover:text-red-700"
                  onClick={(e) => handleSubtractNamedShipper(e, index)}
                >
                  {t("plm.mtc.named-shippers.remove")} {index + 1}
                </button>
              )}
            </div>
          ))}
        </>
      )}

      {/* Add button */}
      {formData.documentType === "Application" && (
        <button
          className="mt-2 text-blue-500 hover:text-blue-700"
          onClick={handleAddNamedShipper}
        >
          {t("plm.mtc.named-shippers.add")}
        </button>
      )}
    </div>
  );
}
