import React from "react";
import Breadcrumbs from "../../assets/Breadcrumbs";
import TaskTable from "./TaskTable";
import useTranslations from "../../../Context/actions/useTranslations";

export default function UnderwriterTasks({ formData, insuredInfo, driverList }) {
  const { t } = useTranslations();
  const breadcrumbs = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t("plm.tasks.title") }
  ];
  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="text-3xl mt-2 font-semibold mb-4">
        {t("plm.tasks.title")}
      </h1>
      <TaskTable
        policyID={formData.id}
        formData={formData}
        insuredInfo={insuredInfo}
        driverList={driverList}
      />
    </div>
  );
}
