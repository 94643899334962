import React from "react";
import { Td, Tr } from "react-super-responsive-table";
import QuillModal from "../../../../QuillModal";
import { Button, ButtonGroup, Grid } from "@mui/material";
import BlueButton from "../../../../assets/forms/BlueButton";
import {
  CheckCircle as CheckCircleIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import useTranslations from "../../../../../Context/actions/useTranslations";
import { formatAmericanDate } from "../../../../../utils/driversPayload";

const CabAnalysis = ({
  task,
  formData,
  index,
  handleUpload,
  setFiles,
  isOpen,
  setIsOpen,
  policyID,
  updatePolicyField,
  insuredInfo
}) => {

  const { t, timezone } = useTranslations();

  return (
    <>
      <Tr key={"otherLossRun" + index}>
        <Td valign="top">
          <p className="my-1">
            {formData.underwritingTasks?.[task.description]?.completed
              ? t("plm.tasks.cab-analysis.complete")
              : t("plm.tasks.cab-analysis.incomplete")}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">
            {task.cabURL ? (
              <a
                className="text-blue"
                target="_blank"
                rel="noreferrer"
                href={task.cabURL + insuredInfo.dot}
              >
                {task.description}
              </a>
            ) : (
              <>{task.description}</>
            )}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">{formatAmericanDate(task.dueDate, timezone)}</p>
        </Td>
        <Td valign="top">
          {task.type === "note" && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <QuillModal
                        handleUpload={handleUpload}
                        isOpen={isOpen[task.description] || false}
                        setIsOpen={(state) =>
                          setIsOpen((prev) => ({
                            ...prev,
                            [task.description]: state,
                          }))
                        }
                        label={task.description}
                        setFile={(files) =>
                          setFiles((prev) => ({
                            ...prev,
                            [task.description]: files,
                          }))
                        }
                        taskLocation={
                          formData.underwritingTasks?.[task.description]?.note || ""
                        }
                        hideButtonLabel={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  {formData?.underwritingTasks?.[task.description]
                    ?.completed && (
                    <>
                      <ButtonGroup
                        fullWidth
                        disableElevation
                        variant="contained"
                      >
                        <Button
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            flexGrow: 1,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                          }}
                        >
                          {t("plm.tasks.cab-analysis.complete")}
                        </Button>
                        <Button
                          onClick={() => {
                            const existingTaskList =
                              formData?.underwritingTasks || {};
                            const newTaskList = {
                              ...existingTaskList,
                              [task.description]: {
                                completed: false,
                              },
                            };
                            updatePolicyField(
                              policyID,
                              "underwritingTasks",
                              newTaskList
                            );
                          }}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            maxWidth: "50px",
                            minWidth: "50px",
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                          }}
                        >
                          <UndoIcon />
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                  {!formData?.underwritingTasks?.[task.description]
                    ?.completed ? (
                    <BlueButton
                      text={t("plm.tasks.cab-analysis.mark-complete")}
                      onClickFunction={(e) => {
                        const existingTaskList =
                          formData?.underwritingTasks || {};
                        const newTaskList = {
                          ...existingTaskList,
                          [task.description]: {
                            completed: true,
                          },
                        };
                        updatePolicyField(
                          policyID,
                          "underwritingTasks",
                          newTaskList
                        );
                      }}
                      hasContentToBottom={true}
                      icon={<CheckCircleIcon />}
                      className={"w-full"}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default CabAnalysis;
