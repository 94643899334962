import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { setPolicyInfo } from "../../../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import endoStatusChangeNotification from "../../../../utils/notifications/endoStatusChangeNotification";
import updateEndorsementStatusAndCreatePowerUnit from "./updateEndorsementStatusAndCreatePowerUnit";

const approveAddPowerUnitsEndorsement = async (
  e,
  requestExceptionData,
  endorsement,
  paramPolicyID,
  paramRefID,
  setTaxSchedule,
  insuredInfo,
  setSuccess,
  setOpenInCancellationModal,
  endorsementData
) => {
  e.preventDefault();
  const { agencyID, insuredID } = endorsement;

  const policyRef = doc(db, "policy-applications", paramPolicyID);
  const policyDoc = await getDoc(policyRef);
  const policyData = policyDoc.data();
  const policyStatus = policyData.status;
  if (policyStatus === "In-Cancellation") {
    setOpenInCancellationModal(true);
    return;
  }

  await updateEndorsementStatusAndCreatePowerUnit(
    paramRefID,
    "Approved",
    endorsementData,
    policyData,
    setSuccess,
    paramPolicyID,
    agencyID,
    insuredID,
    requestExceptionData,
    endorsement,
    setPolicyInfo,
    insuredInfo,
    setTaxSchedule,
    paramRefID
  );

  endoStatusChangeNotification(
    endorsement.type,
    endorsement.endorsementNumber,
    "Approved",
    paramRefID,
    paramPolicyID,
    endorsement.agencyID
  );
};

export default approveAddPowerUnitsEndorsement;
