import React from "react";
import SearchField from "./SearchField";
import TableSelection from "../TableSelection";
import PolicySearch from "./PolicySearch";
import SearchNameInput from "./SearchNameInput";

export default function Search({
  searchType,
  searchValue,
  setSearchValue,
  selectedOption,
  setSelectedOption,
  selectedInsured,
  setSelectedInsured,
  fetchViolations,
  selectedPolicy,
  setSelectedPolicy,
  loading
}) {
  return (
    <>
      {searchType === "license" && (
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchType={searchType}
        />
      )}
      {searchType === "policy" && (
        <PolicySearch
          searchValue={searchValue}
          selectedInsured={selectedInsured}
          setSelectedInsured={setSelectedInsured}
          fetchViolations={fetchViolations}
          selectedPolicy={selectedPolicy}
          setSelectedPolicy={setSelectedPolicy}
          setSearchValue={setSearchValue}
        />
      )}
      {searchType === "name" && (
        <SearchNameInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchType={searchType}
        />
      )}
    </>
  );
}
