import React, { useState } from "react";
import {
  TextField,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { db } from "../../../../firebase"; // Updated import path
import {
  collectionGroup,
  query,
  where,
  getDocs,
  orderBy
} from "firebase/firestore";

function AdvancedEndoSearch({
  userInfo
}) {
  const [queryText, setQueryText] = useState("");
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchFunction, setSearchFunction] = useState("company"); // Default search 
  
  const userAgencyID = userInfo.agencyID;

  const handleSearch = async () => {
    let endorsementsQuery;

    const searchValue = queryText.toUpperCase(); // Convert to uppercase for consistent querying

    if (searchFunction === "company") {
      endorsementsQuery = query(
        collectionGroup(db, "endorsements"),
        where("agencyID", "==", userAgencyID),
        where("namedInsured", ">=", searchValue),
        where("namedInsured", "<", searchValue + "\uf8ff"),
        orderBy("endorsementNumber", "desc")
      );
    } else if (searchFunction === "driver") {
      // Search for driver name in searchData array
      endorsementsQuery = query(
        collectionGroup(db, "endorsements"),
        where("agencyID", "==", userAgencyID),
        where("searchData", "array-contains", searchValue),
        orderBy("endorsementNumber", "desc")
      );
    } else if (searchFunction === "license") {
      // Search for license number in searchData array
      endorsementsQuery = query(
        collectionGroup(db, "endorsements"),
        where("agencyID", "==", userAgencyID),
        where("searchData", "array-contains", searchValue),
        orderBy("endorsementNumber", "desc")
      );
    }

    if (endorsementsQuery) {
      try {
        const querySnapshot = await getDocs(endorsementsQuery);
        const searchResults = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Search results:", searchResults);
        setResults(searchResults);
      } catch (error) {
        console.error("Error fetching endorsements:", error);
      }
    } else {
      setResults([]); // Clear results if the input is empty
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Select
          value={searchFunction}
          onChange={(e) => setSearchFunction(e.target.value)}
          sx={{ mr: 1 }}
        >
          <MenuItem value="company">Search By Company Name</MenuItem>
          <MenuItem value="driver">Search By Driver Name</MenuItem>
          <MenuItem value="license">Search By Driver License Number</MenuItem>
        </Select>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={queryText}
          onChange={(e) => setQueryText(e.target.value)}
        />
        <IconButton onClick={handleSearch} sx={{ ml: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection="asc">
                <TableSortLabel active>Endo No.</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel>Named Insured</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel>Endo Type</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel>Effective Date</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel>Author</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((result) => (
                <TableRow
                  component="a"
                  href={`/underwriting/endorsements/${result.id}/${result.policyID}`}
                  target="_blank"
                  key={result.id}
                >
                  <TableCell>{result.endorsementNumber}</TableCell>
                  <TableCell>{result.namedInsured}</TableCell>
                  <TableCell>{result.type}</TableCell>
                  <TableCell>
                    {result.endoEffectiveDate
                      .toDate()
                      .toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                  </TableCell>
                  <TableCell>{result.author}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={results.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default AdvancedEndoSearch;
