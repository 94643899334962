import React, { useEffect, useState } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import { Tooltip } from "@mui/material";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableSkeleton from "../../generals/TableSkeleton";
import useTranslations from "../../../Context/actions/useTranslations";
import { POLICY_ROUTE } from "../../../utils/routes";

export default function MiniPoliciesList({ driver, userInfo }) {
  const [skeletonLoader, setSkeletonLoader] = useState(true);

  const filterNonEmptyStrings = (arr) => {
    return arr.filter(item => typeof item === 'string' && item.trim().length > 0);
  };

  const { t } = useTranslations()

  const [policies, setPolicies] = useState([]);
  const getPolicyDetails = async () => {
    setSkeletonLoader(true);
    const policiesIds = driver.policiesDetails.map((policy) => policy.id) || [];
    const filteredPoliciesIds = filterNonEmptyStrings(policiesIds);
    const q = query(
      collection(db, "policy-applications"),
      where(documentId(), "in", filteredPoliciesIds)
    );
    const querySnapshot = await getDocs(q);
    const promises = querySnapshot.docs.map(async (docu) => {
      const documentData = docu.data();
      const insuredRef = doc(
        db,
        "agencies",
        documentData.agencyID,
        "insureds",
        documentData.insuredID
      );
      const insuredDoc = await getDoc(insuredRef);
      const namedInsured = insuredDoc.data().company || "";
      const effectiveDate = documentData.effectiveDate
        .toDate()
        .toString()
        .slice(4, 15);
      const policyIndex = driver.policiesDetails.findIndex(
        (policy) => policy.id === docu.id
      );
      const status = driver?.policiesDetails?.[policyIndex]?.status || "";
      return {
        ...documentData,
        id: docu.id,
        namedInsured,
        effectiveDate,
        status,
      };
    });
    const values = await Promise.all(promises);
    setPolicies(values);
    setSkeletonLoader(false);
  };

  useEffect(() => {
    getPolicyDetails();
    return () => {};
  }, []);

  return (
    <>
      {skeletonLoader ? (
        <TableSkeleton rows={2} columns={4} />
      ) : (
        <div>
          {userInfo.role === "Admin" || userInfo.role === "Underwriter" ? (
            <>
              {policies?.length === 0 ? (
                <p>{t('plm.drivers.details.no-item')}</p>
              ) : (
                <Table>
                  <Thead>
                    <Tr>
                      <Td className="bg-[#072a48]  rounded-tl-md text-white cursor-pointer hover:bg-[#072a48]">
                        {t('plm.drivers.details.policy-no')}
                      </Td>
                      <Td className="bg-[#072a48]  text-white cursor-pointer hover:bg-[#072a48]">
                        {t('plm.drivers.details.named-insured')}
                      </Td>
                      <Td className="bg-[#072a48]  text-white cursor-pointer hover:bg-[#072a48]">
                        {t('plm.drivers.details.effective-date')}
                      </Td>
                      <Td className="bg-[#072a48]  text-white rounded-tr-md cursor-pointer hover:bg-[#072a48]">
                        {t('plm.drivers.details.status')}
                      </Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {policies.map((policy, i) => {
                      return (
                        <Tr key={"policy" + i}>
                          <Td>
                            <a
                              target="_blank"
                              href={`${POLICY_ROUTE}/${policy.id}`}
                            >
                              {policy?.alPolicyNo || "Application"}
                              {/* // mui symbol for external Link below */}
                              <Tooltip title="Open This Policy" placement="top">
                                <OpenInNewIcon
                                  fontSize={"smaller"}
                                  className="ml-2 mb-1"
                                />
                              </Tooltip>
                            </a>
                          </Td>
                          <Td>{policy?.namedInsured}</Td>
                          <Td>{policy?.effectiveDate}</Td>
                          <Td>{policy?.status}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          ) : (
            <p>This panel is only available to underwriters.</p>
          )}
        </div>
      )}
    </>
  );
}
