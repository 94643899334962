import React, { useState } from "react";
import ExpandCollapseArrow from "../generals/DesignElements/ExpandCollapseArrow";
import RedButton from "../assets/forms/RedButton";

export default function DisplayCancellationReasons({
  className,
  formData,
  setLetterType,
  setDisplayUnderwritingPanel
}) {
  const reasons = formData?.cancellationData?.cancellationReasons || {};
  const cancellationDate = formData?.cancellationData?.cancellationDate;
  const [show, setShow] = useState(true);
  const color = "red";
  const reasonsArray = Object.entries(reasons)
    .filter(([key, value]) => value === true)
    .map(([key]) => key.replace(/([A-Z])/g, ' $1').trim());
  const cancellationDateParsed = cancellationDate ? cancellationDate.toDate().toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  }) : ''

  const handleRedirectionToNor = (e) => {
    e.preventDefault();
    setLetterType("NOR");
    setDisplayUnderwritingPanel(true);
  }

  return (
    <main
      className={`card mt-3 bg-${color}-100 text-${color}-700 ${className || ""}`}
    >
      <div className="card-header" onClick={(e) => setShow(!show)}>
        <h1 className={`text-lg font-bold text-${color}-700`}>
          <button>Policy Pending Cancellation</button>
          <ExpandCollapseArrow show={show} setShow={setShow} />
        </h1>
      </div>
      {show && (
        <div className="card-body">
          <p className="mb-2">
            This policy has a pending
            cancellation {cancellationDate ? `at ${cancellationDateParsed}` : ''} for
            the following reasons:</p>
          <ul className="list-disc ml-6">
            {reasonsArray.map((reason, i) => {
              const capitalizedReason = reason.charAt(0).toUpperCase() + reason.slice(1);
              return (
                <li key={i} className="mb-2">
                  {capitalizedReason}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div className="ml-4 mb-1">
        <RedButton
          text="Send Notice of Reinstatement"
          onClickFunction={handleRedirectionToNor}
          hasContentToTop={true}
          hasContentToBottom={true}
          icon={"no-icon"}
        />
      </div>
    </main>
  );
}
