import React, { useState } from "react";
import TextInput from "../../../assets/forms/TextInput";
import NumberInput from "../../../assets/forms/NumberInput";
import useTranslations from "../../../../Context/actions/useTranslations";

const LossDataForm = ({
  handleSubmit,
  currentYear,
  numberOfYearsPrior,
  formData,
  setFormData,
  year,
  coverageType,
  policyID,
}) => {
  const initialState =
    formData?.lossHistory?.[`year${year + "" + coverageType}LossData`] || {};
  const [lossData, setLossData] = useState(initialState);
  const section = "lossHistory";
  const category = `year${year + "" + coverageType}LossData`;

  const { t } = useTranslations();

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(
          e,
          currentYear -
            numberOfYearsPrior -
            1 +
            " " +
            "to" +
            " " +
            (currentYear - numberOfYearsPrior),
          lossData,
          `year${year + "" + coverageType}LossData`
        );
      }}
    >
      <fieldset disabled={formData.documentType !== "Application"}>
        <TextInput
          label={t("plm.losses.apd-mtc.carrier") + ":"}
          required={true}
          saveForm={() => {}}
          property={"carrier"}
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("plm.losses.apd-mtc.number-of-units") + ":"}
          required={true}
          saveForm={() => {}}
          property="numberOfUnits"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("plm.losses.apd-mtc.total-insured-value") + ":"}
          required={true}
          saveForm={() => {}}
          property="tiv"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("plm.losses.apd-mtc.deductible") + ":"}
          required={true}
          saveForm={() => {}}
          property="deductible"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("plm.losses.apd-mtc.mileage") + ":"}
          required={true}
          saveForm={() => {}}
          property="mileage"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("plm.losses.apd-mtc.losses") + ":"}
          required={true}
          saveForm={() => {}}
          property="losses"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("plm.losses.apd-mtc.number-of-claims") + ":"}
          required={true}
          saveForm={() => {}}
          property="howManyClaims"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <button className="submit-button" type="submit">
          {t("plm.losses.apd-mtc.calculate-loss-ratio")}
        </button>
      </fieldset>
    </form>
  );
};

export default LossDataForm;
