import React from "react";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import TableSkeleton from "../../../generals/TableSkeleton";
import { formatDOB, formatMexicanDate, translateExamType, translateFit } from "../../../../utils/driversPayload";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function DriverMapList({
  driverMapData,
  driverMapSkeletonLoader,
  licenseCountry,
  crashesArray
}) {
  const profileDefinition = driverMapData?.payload?.profileDefinition;
  const basicInformation = driverMapData?.payload?.basicInformation;
  const score = driverMapData?.payload?.score;
  const riskFactor = driverMapData?.payload?.riskFactor;
  const crashes = driverMapData?.payload?.crashes;
  const sct = driverMapData?.payload?.sct;
  const training = driverMapData?.payload?.training;
  const policy = driverMapData?.payload?.policy;
  const inspections = driverMapData?.payload?.inspections;
  const crashesArrayData = crashesArray

  const { language, t, timezone } = useTranslations()

  return (
    <div>
      {driverMapSkeletonLoader ? (
        <div className="mb-3">
          <TableSkeleton rows={2} columns={6} />
        </div>
      ) : (
        <>
          <Table>
            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.profile-definition.title")}
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.profile-definition.name")}: {profileDefinition?.name}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.profile-definition.dob")}: {profileDefinition?.dob}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.profile-definition.license")}: {profileDefinition?.licenseMX}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.profile-definition.expires")}: {profileDefinition?.expiresOn}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.basic-information.title")}
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.basic-information.license-class")}: {basicInformation?.licenseClass}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.basic-information.experience")}: {basicInformation?.experience}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.basic-information.curp")}: {basicInformation?.curp}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.basic-information.score-status")}: {basicInformation?.scoreStatus}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.basic-information.nmp")}: {basicInformation?.noMedicinaPreventiva}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.score.title")}
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.score.score-points")}: {score?.scorePoints}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.score.violations")}: {score?.violations}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.score.claims")}: {score?.claims}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.score.crashes")}: {score?.crashes}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.score.trainings")}: {score?.training}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                {t("plm.drivers.driver-details.driver-map.risk-factor.title")}
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.risk-factor.frequency")}: {riskFactor?.frequency}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.risk-factor.severity")}: {riskFactor?.severity}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.risk-factor.combined")}: {riskFactor?.combined}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.crashes.title")}
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.crashes.crashes")}: {crashes?.crashes}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.crashes.fatalities")}: {crashes?.fatalities}</Td></Tr>
              <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.crashes.injuries")}: {crashes?.injuries}</Td></Tr>
            </Tbody>

            {licenseCountry === "MX" ?
              <>
                <Thead>
                  <Tr>
                    <Td className="bg-[#072a48] text-white" colSpan={6}>
                      {t("plm.drivers.driver-details.driver-map.sct.title")}
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.sct.effective-from")}: {formatMexicanDate(sct?.effectiveFrom, timezone)}</Td></Tr>
                  <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.sct.effective-until")}: {formatMexicanDate(sct?.effectiveUntil, timezone)}</Td></Tr>
                  <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.sct.effective-fit")}: {translateFit(sct?.fit, language)}</Td></Tr>
                  <Tr><Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.sct.effective-exam-type")}: {translateExamType(sct?.examType, language)}</Td></Tr>
                </Tbody>
              </> : null
            }

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.training.title")}
                </Td>
              </Tr>
            </Thead>
            {Array.isArray(training) && training?.length > 0 ?
              <Thead>
                <Tr>
                  <Td colSpan={4} className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.training.training-name")}</Td>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.training.completion-date")}</Td>
                </Tr>
              </Thead> : null
            }
            <Tbody>
              {Array.isArray(training) && training?.length > 0 ? (
                training?.map((item, index) => (
                  <Tr key={index}>
                    <Td colSpan={4}>{item?.training}</Td>
                    <Td colSpan={2}>{item?.completionDate}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>
                    {t("plm.drivers.driver-details.driver-map.training.no-trainings")}
                  </Td>
                </Tr>
              )}
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.policy.title")}
                </Td>
              </Tr>
            </Thead>
            {Array.isArray(policy) && policy?.length > 0 ?
              <Thead>
                <Tr>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.policy.policy-number")}</Td>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.policy.dot")}</Td>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.policy.insured-name")}</Td>
                </Tr>
              </Thead> : null
            }
            <Tbody>
              {Array.isArray(policy) && policy?.length > 0 ? (
                policy?.map((item, index) => (
                  <Tr key={index}>
                    <Td colSpan={2}>{item?.policyNbr}</Td>
                    <Td colSpan={2}>{item?.dot}</Td>
                    <Td colSpan={2}>{item?.insuredName}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.policy.no-policies")}</Td>
                </Tr>
              )}
            </Tbody>
            
            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.inspections.title")}
                </Td>
              </Tr>
            </Thead>
            {Array.isArray(inspections) && inspections?.length > 0 ?
              <Thead>
                <Tr>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.inspections.report-no")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.inspections.eventuality")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.inspections.expedition-date")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.inspections.eventuality-group")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.inspections.status")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.inspections.description")}</Td>
                </Tr>
              </Thead>
              : null
            }
            <Tbody>
              {Array.isArray(inspections) && inspections?.length > 0 ? (
                inspections?.map((inspection, index) => (
                  <Tr key={index}>
                    <Td>{inspection?.reportNo}</Td>
                    <Td>{inspection?.eventuality}</Td>
                    <Td>{inspection?.expeditionDate}</Td>
                    <Td>{inspection?.eventualityGroup}</Td>
                    <Td>{inspection?.status}</Td>
                    <Td>{inspection?.description}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.inspections.no-inspections")}</Td>
                </Tr>
              )}
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>
                  {t("plm.drivers.driver-details.driver-map.crashes-array.title")}
                </Td>
              </Tr>
            </Thead>
            {Array.isArray(crashesArrayData) && crashesArrayData?.length > 0 ?
              <Thead>
                <Tr>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.crashes-array.crash-date")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.crashes-array.citation-issued")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.crashes-array.fatalities")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.crashes-array.injuries")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.crashes-array.tow-away")}</Td>
                  <Td className="bg-[#79aedc] text-white">{t("plm.drivers.driver-details.driver-map.crashes-array.roadway-traffic")}</Td>
                </Tr>
              </Thead>
              : null
            }
            <Tbody>
              {Array.isArray(crashesArrayData) && crashesArrayData?.length > 0 ? (
                crashesArrayData?.map((crash, index) => (
                  <Tr key={index}>
                    <Td>{formatDOB(crash?.crashDate)}</Td>
                    <Td>{crash?.citationIssued}</Td>
                    <Td>{crash?.fatalities}</Td>
                    <Td>{crash?.injuries}</Td>
                    <Td>{crash?.towAway}</Td>
                    <Td>{crash?.roadwayTrafficway}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>{t("plm.drivers.driver-details.driver-map.crashes-array.no-crashes")}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </>
      )}
    </div>
  );
}