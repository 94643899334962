import React from "react";

export default function ButtonInstructions({
    isRequestException,
    subcollection,
}) {
  return (
    <article className="mt-3">
      <p
        className={
          isRequestException
            ? "text-red-700 text-center !max-w-[345px] mx-auto"
            : ""
        }
      >
        {isRequestException
          ? `This endorsement has already been declined. 
                You are accepting/decling an exception request`
          : `Approving this endorsement will remove
                the listed ${subcollection} from the policy
                and notify the insured of the change.`}
      </p>
    </article>
  );
}
