import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";
import useTranslations from "../../../../Context/actions/useTranslations";
import updateCommodityTotal from "../utils/updateCommodityTotal";

export default function StandardCommodityName({
  label,
  property,
  formData,
  setFormData,
  policyID,
  disabledSlider,
  defaultValue,
}) {
  const { t } = useTranslations();

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();
  const apiService = new ApiService();

  const handleChangeStandardCommodityPercentage = async (e) => {
    let newValue = e.target.value.replace(/^0+(?=\d)/, "");
    const updatedPercentages = { ...formData.standardCommodityPercentages };
    updatedPercentages[e.target.name] = newValue;

    const policyRef = doc(db, "policy-applications", policyID);

    if (disabledSlider && newValue === 0) {
      const updatedValues = {
        ...formData.standardCommodityValues,
        [property]: 0,
      };

      if (Boolean(moduleValue)) {
        setFormData({
          ...formData,
          standardCommodityValues: updatedPercentages,
        });
        apiService.renewalProcessUpdatePolicyField({
          policyID,
          payload: { standardCommodityValues: updatedValues },
        });
      } else {
        await updateDoc(policyRef, { standardCommodityValues: updatedValues });
      }
    }

    if (Boolean(moduleValue)) {
      setFormData({
        ...formData,
        standardCommodityPercentages: updatedPercentages,
      });
      const commodityTotal = updateCommodityTotal(null, {
        ...formData,
        standardCommodityPercentages: updatedPercentages,
      });
      await apiService.renewalProcessUpdatePolicyField({
        policyID,
        payload: {
          standardCommodityPercentages: updatedPercentages,
          commodityTotal: commodityTotal,
        },
      });
    } else {
      await updateDoc(policyRef, {
        standardCommodityPercentages: updatedPercentages,
      });
      await updateCommodityTotal(policyID, {
        ...formData,
        standardCommodityPercentages: updatedPercentages,
      });
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-sm font-semibold">
        {label} {t("plm.commodities.custom.percentage")}:
      </label>
      <input
        name={property}
        type="number"
        onChange={handleChangeStandardCommodityPercentage}
        className="p-2 border rounded focus:outline-none focus:border-blue-500"
        value={formData?.standardCommodityPercentages?.[property] || 0}
      />
    </div>
  );
}
