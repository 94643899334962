import React, { useState, useEffect } from "react";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import InputField from "./InputField"; // Adjust the path as necessary
import EditDropdownModal from "./EditDropdownModal"; // Adjust the path as necessary
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import numberWithCommas from "../../../utils/numberWithCommas";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function ProvideQuoteFigures({ formData, policyID, userInfo }) {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [apdFields, setApdFields] = useState([]);
  const [mtcFields, setMtcFields] = useState([]);
  const [alFields, setAlFields] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  const { t } = useTranslations();

  const userIsUnderwriter =
    userInfo.role === "Underwriter" || userInfo.role === "Admin";
  const disabledRule =
    formData.documentType !== "Quote Request" || !userIsUnderwriter;

  // Fetch data from Firestore in real-time
  useEffect(() => {
    const unsubscribeDropdown = onSnapshot(
      doc(db, "dropdownData", "dropdownOptions"),
      (doc) => {
        if (doc.exists()) {
          setDropdownOptions(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "dropdownOptions",
            }))
          );
        }
      }
    );

    const unsubscribeApd = onSnapshot(
      doc(db, "dropdownData", "apdFields"),
      (doc) => {
        if (doc.exists()) {
          setApdFields(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "apdFields",
            }))
          );
        }
      }
    );

    const unsubscribeMtc = onSnapshot(
      doc(db, "dropdownData", "mtcFields"),
      (doc) => {
        if (doc.exists()) {
          setMtcFields(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "mtcFields",
            }))
          );
        }
      }
    );

    const unsubscribeAl = onSnapshot(
      doc(db, "dropdownData", "alFields"),
      (doc) => {
        if (doc.exists()) {
          setAlFields(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "alFields",
            }))
          );
        }
      }
    );

    // Cleanup listeners on component unmount
    return () => {
      unsubscribeDropdown();
      unsubscribeApd();
      unsubscribeMtc();
      unsubscribeAl();
    };
  }, []);

  const handleEditClick = (field) => {
    setCurrentField(field);
    setEditModalOpen(true);
  };

  const handleUpdateOptions = (updatedOptions) => {
    if (currentField) {
      updateDoc(doc(db, "dropdownData", currentField?.parent), {
        fields: dropdownOptions.map((opt) =>
          opt.field === currentField?.field
            ? { ...currentField, options: updatedOptions }
            : opt
        ),
      }).catch((error) => {
        console.error("Error updating dropdown options:", error);
      });
    }
  };

  const renderField = (field, value, onChange, disabled) => {
    if (field?.isDropdown) {
      return (
        <Box py={2}>
          <FormControl fullWidth variant="outlined" disabled={disabled}>
            <InputLabel>{field?.label}</InputLabel>
            <Select
              required
              value={value}
              onChange={onChange}
              label={field?.label}
            >
              {field?.options.map((option) => (
                <MenuItem key={option.field} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            sx={{ fontSize: "10px" }}
            onClick={() => handleEditClick(field)}
          >
            {t("plm.quotes.underwriting-details.edit-options")}
          </Button>
        </Box>
      );
    } else {
      return (
        <InputField
          required
          label={field?.label}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      );
    }
  };

  const renderDisabledFieldWithTIVAsValue = (
    field,
    value,
    onChange,
    disabled
  ) => {
    return (
      <Box py={2}>
        <Tooltip title={t("plm.quotes.underwriting-details.this.amount")}>
          <TextField
            fullWidth
            label={field?.label}
            value={"$" + numberWithCommas(formData?.TIV || 0)}
            onChange={onChange}
            disabled={true}
            variant="outlined"
          />
        </Tooltip>
      </Box>
    );
  };
  return (
    <>
      <Card className="mb-4 mt-2">
        <CardContent>
          <Typography variant="h5" component="h2">
            {t("plm.quotes.underwriting-details.policy-details")}
          </Typography>
          <Typography className="my-2">
            {t("plm.quotes.underwriting-details.developing")}
          </Typography>

          <Grid container spacing={2}>
            {formData.hasALCoverage &&
              alFields.map((field) => (
                <Grid item xs={12} sm={6} key={field?.field}>
                  {renderField(
                    field,
                    formData?.alDetails?.[field?.field] || "",
                    (e) => {
                      console.log("clicked");
                      console.log("value: ", e.target.value);
                      updateDoc(doc(db, "policy-applications", policyID), {
                        alDetails: {
                          ...formData.alDetails,
                          [field?.field]: field?.isDropdown
                            ? e.target.value
                            : parseInt(e.target.value),
                        },
                      });
                    },
                    disabledRule
                  )}
                </Grid>
              ))}
          </Grid>
          {formData?.hasAPDCoverage && (
            <>
              <Box my={2}>
                {renderField(
                  dropdownOptions[1],
                  formData.apdCarrier || "",
                  (e) => {
                    updateDoc(doc(db, "policy-applications", policyID), {
                      apdCarrier: e.target.value,
                    });
                  },
                  disabledRule
                )}
              </Box>

              <Grid container spacing={2}>
                {formData.hasAPDCoverage &&
                  apdFields.map((field) => (
                    <>
                      <Grid item xs={12} sm={6} key={field?.field}>
                        {field?.field === "collisionLimit" ||
                        field?.field === "comprehensiveLimit" ? (
                          <>
                            {renderDisabledFieldWithTIVAsValue(
                              field,
                              formData?.apdDetails?.[field?.field] || "",
                              (e) => {
                                updateDoc(
                                  doc(db, "policy-applications", policyID),
                                  {
                                    apdDetails: {
                                      ...formData.apdDetails,
                                      [field?.field]: field?.isDropdown
                                        ? e.target.value
                                        : parseInt(e.target.value),
                                    },
                                  }
                                );
                              },
                              disabledRule
                            )}{" "}
                          </>
                        ) : (
                          <>
                            {renderField(
                              field,
                              formData?.apdDetails?.[field?.field] || "",
                              (e) => {
                                updateDoc(
                                  doc(db, "policy-applications", policyID),
                                  {
                                    apdDetails: {
                                      ...formData.apdDetails,
                                      [field?.field]: field?.isDropdown
                                        ? e.target.value
                                        : parseInt(e.target.value),
                                    },
                                  }
                                );
                              },
                              disabledRule
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  ))}
                {formData.hasAPDCoverage &&
                  formData?.apdDetails?.trailerInterchange && (
                    <Grid item xs={12} sm={6}>
                      <InputField
                        label={t("plm.quotes.underwriting-details.trailer-interchange-limit")}
                        value={
                          formData?.apdDetails?.trailerInterchangeLimit || ""
                        }
                        onChange={(e) => {
                          updateDoc(doc(db, "policy-applications", policyID), {
                            apdDetails: {
                              ...formData.apdDetails,
                              trailerInterchangeLimit: parseInt(e.target.value),
                            },
                          });
                        }}
                        disabled={disabledRule}
                      />
                    </Grid>
                  )}
              </Grid>
            </>
          )}
          {formData.hasMTCCoverage && (
            <>
              <Box my={2}>
                {renderField(
                  dropdownOptions[0],
                  formData.mtcCarrier || "",
                  (e) => {
                    updateDoc(doc(db, "policy-applications", policyID), {
                      mtcCarrier: e.target.value,
                    });
                  },
                  disabledRule
                )}
              </Box>

              <Grid container spacing={2}>
                {!formData.hasAPDCoverage &&
                  formData.hasMTCCoverage &&
                  formData?.mtcDetails?.trailerInterchange && (
                    <Grid item xs={12} sm={6}>
                      <InputField
                        label={t("plm.quotes.underwriting-details.trailer-interchange-limit")}
                        value={
                          formData?.mtcDetails?.trailerInterchangeLimit || ""
                        }
                        onChange={(e) => {
                          updateDoc(doc(db, "policy-applications", policyID), {
                            mtcDetails: {
                              ...formData.mtcDetails,
                              trailerInterchangeLimit: parseInt(e.target.value),
                            },
                          });
                        }}
                        disabled={disabledRule}
                      />
                    </Grid>
                  )}
                {mtcFields.map((field) => (
                  <Grid item xs={12} sm={6} key={field?.field}>
                    {renderField(
                      field,
                      formData?.mtcDetails?.[field?.field] || "",
                      (e) => {
                        updateDoc(doc(db, "policy-applications", policyID), {
                          mtcDetails: {
                            ...formData.mtcDetails,
                            [field?.field]: field?.isDropdown
                              ? e.target.value
                              : parseInt(e.target.value),
                          },
                        });
                      },
                      disabledRule
                    )}
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </CardContent>
      </Card>

      {/* Modal for Editing Dropdown Options */}
      {currentField && (
        <EditDropdownModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          field={currentField}
          onUpdateOptions={handleUpdateOptions}
        />
      )}
    </>
  );
}
