import readXlsxFile from "read-excel-file";
import { db } from "../../../../firebase";
import { doc, setDoc } from "firebase/firestore";

export const commodityImportData = async (file, policyID, controlToast, setLoading, formData) => {
  try {
    if (!file) return console.log("no file");
    setLoading(true)
    const rows = await readXlsxFile(file, { sheet: 1 });
    const policyRef = doc(db, "policy-applications", policyID);

    const findMatchCommodity = (type) => {
      const matchObj = rows.find(row => row?.[1] === type)
      return matchObj;
    }

    const totalPercentage = (commodity) => {
      const percentageData = parseFloat(commodity?.[4])
      const percentageDataDoesExist = Boolean(commodity?.[2])
      return percentageDataDoesExist ? percentageData : null
    }

    const electronics = findMatchCommodity('Electronics');
    const beerWine = findMatchCommodity('Beer/Wine');
    const alcohol = findMatchCommodity('ALCOHOL');
    const liquor = findMatchCommodity('Liquor');
    const frozenFood = findMatchCommodity('Frozen Food (other than meat and seafood)');
    const eggs = findMatchCommodity('Eggs');
    const seafood = findMatchCommodity('Seafood (other than canned seafood)');
    const meatsPoultry = findMatchCommodity('Meats/Poultry');
    const produce = findMatchCommodity('Produce');
    const motorVehicles = findMatchCommodity('Motor Vehicles');
    const containerizedFreight = findMatchCommodity('Containerized Freight');
    const mobileEquipment = findMatchCommodity('Mobile Equipment/Cell phones');
    const tobaccoCigars = findMatchCommodity('Tobacco/Cigars');
    const clothing = findMatchCommodity('Clothing');
    const tires = findMatchCommodity('Tires');
    const metalsCoils = findMatchCommodity('Metals/Coils');
    const copper = findMatchCommodity('Copper');
    const heavyMachinery = findMatchCommodity('Heavy Machinery');
    const nonFerrousMetal = findMatchCommodity('Non-Ferrous Metal');
    const inScrapOrIngo = findMatchCommodity('In Scrap &/Or Ingo');
    const inHook = findMatchCommodity('In-Hook');
    const mobileHomes = findMatchCommodity('Mobile Homes');
    const logsTimberPulpwood = findMatchCommodity('Logs, Timber & Pulpwood');
    const documentsMail = findMatchCommodity('Documents/Mail');
    const pharmaceuticals = findMatchCommodity('Pharmaceuticals');
    const preciousStonesJewelry = findMatchCommodity('Precious Stones/Jewelry');
    const marijuana = findMatchCommodity('Any Marijuana-based products');
    const liveAnimals = findMatchCommodity('Live Animals');
    const art = findMatchCommodity('Any type of Art');
    const leatherFur = findMatchCommodity('Leather /Fur');
    const autoParts = findMatchCommodity('Auto parts (not tires)');
    const cannedDryFood = findMatchCommodity('Canned/Dry Food');
    const furniture = findMatchCommodity('Furniture');
    const groceries = findMatchCommodity('Groceries /Supermarket merchandise');
    const electroDomestics = findMatchCommodity('Electro domestics');
    const woodLumber = findMatchCommodity('Wood/Lumber');
    const pipes = findMatchCommodity('Pipes');
    const cotton = findMatchCommodity('Cotton');
    const oilFieldEquipment = findMatchCommodity('Oil field equipment');
    const rawMaterial = findMatchCommodity('Raw Material');
    
    if (totalPercentage(electronics) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          electronics: totalPercentage(electronics),
        }
      }, { merge: true });
    }
    if (totalPercentage(beerWine) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          beerWine: totalPercentage(beerWine),
        }
      }, { merge: true });
    }
    if (totalPercentage(alcohol) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          alcohol: totalPercentage(alcohol),
        }
      }, { merge: true });
    }
    if (totalPercentage(liquor) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          liquor: totalPercentage(liquor),
        }
      }, { merge: true });
    }
    if (totalPercentage(frozenFood) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          frozenFood: totalPercentage(frozenFood),
        }
      }, { merge: true });
    }
    if (totalPercentage(eggs) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          eggs: totalPercentage(eggs),
        }
      }, { merge: true });
    }
    if (totalPercentage(seafood) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          seafood: totalPercentage(seafood),
        }
      }, { merge: true });
    }
    if (totalPercentage(meatsPoultry) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          meatsPoultry: totalPercentage(meatsPoultry),
        }
      }, { merge: true });
    }
    if (totalPercentage(produce) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          produce: totalPercentage(produce),
        }
      }, { merge: true });
    }
    if (totalPercentage(motorVehicles) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          motorVehicles: totalPercentage(motorVehicles),
        }
      }, { merge: true });
    }
    if (totalPercentage(containerizedFreight) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          containerizedFreight: totalPercentage(containerizedFreight),
        }
      }, { merge: true });
    }
    if (totalPercentage(mobileEquipment) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          mobileEquipment: totalPercentage(mobileEquipment),
        }
      }, { merge: true });
    }
    if (totalPercentage(tobaccoCigars) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          tobaccoCigars: totalPercentage(tobaccoCigars),
        }
      }, { merge: true });
    }
    if (totalPercentage(clothing) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          clothing: totalPercentage(clothing),
        }
      }, { merge: true });
    }
    if (totalPercentage(tires) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          tires: totalPercentage(tires),
        }
      }, { merge: true });
    }
    if (totalPercentage(metalsCoils) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          metalsCoils: totalPercentage(metalsCoils),
        }
      }, { merge: true });
    }
    if (totalPercentage(copper) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          copper: totalPercentage(copper),
        }
      }, { merge: true });
    }
    if (totalPercentage(heavyMachinery) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          heavyMachinery: totalPercentage(heavyMachinery),
        }
      }, { merge: true });
    }
    if (totalPercentage(nonFerrousMetal) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          nonFerrousMetal: totalPercentage(nonFerrousMetal),
        }
      }, { merge: true });
    }
    if (totalPercentage(inScrapOrIngo) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          inScrapOrIngo: totalPercentage(inScrapOrIngo),
        }
      }, { merge: true });
    }
    if (totalPercentage(inHook) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          inHook: totalPercentage(inHook),
        }
      }, { merge: true });
    }
    if (totalPercentage(mobileHomes) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          mobileHomes: totalPercentage(mobileHomes),
        }
      }, { merge: true });
    }
    if (totalPercentage(logsTimberPulpwood) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          logsTimberPulpwood: totalPercentage(logsTimberPulpwood),
        }
      }, { merge: true });
    }
    if (totalPercentage(documentsMail) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          documentsMail: totalPercentage(documentsMail),
        }
      }, { merge: true });
    }
    if (totalPercentage(pharmaceuticals) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          pharmaceuticals: totalPercentage(pharmaceuticals),
        }
      }, { merge: true });
    }
    if (totalPercentage(preciousStonesJewelry) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          preciousStonesJewelry: totalPercentage(preciousStonesJewelry),
        }
      }, { merge: true });
    }
    if (totalPercentage(marijuana) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          marijuana: totalPercentage(marijuana),
        }
      }, { merge: true });
    }
    if (totalPercentage(liveAnimals) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          liveAnimals: totalPercentage(liveAnimals),
        }
      }, { merge: true });
    }
    if (totalPercentage(art) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          art: totalPercentage(art),
        }
      }, { merge: true });
    }
    if (totalPercentage(leatherFur) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          leatherFur: totalPercentage(leatherFur),
        }
      }, { merge: true });
    }
    if (totalPercentage(autoParts) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          autoParts: totalPercentage(autoParts),
        }
      }, { merge: true });
    }
    if (totalPercentage(cannedDryFood) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          cannedDryFood: totalPercentage(cannedDryFood),
        }
      }, { merge: true });
    }
    if (totalPercentage(furniture) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          furniture: totalPercentage(furniture),
        }
      }, { merge: true });
    }
    if (totalPercentage(groceries) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          groceries: totalPercentage(groceries),
        }
      }, { merge: true });
    }
    if (totalPercentage(electroDomestics) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          electroDomestics: totalPercentage(electroDomestics),
        }
      }, { merge: true });
    }
    if (totalPercentage(woodLumber) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          woodLumber: totalPercentage(woodLumber),
        }
      }, { merge: true });
    }
    if (totalPercentage(pipes) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          pipes: totalPercentage(pipes),
        }
      }, { merge: true });
    }
    if (totalPercentage(cotton) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          cotton: totalPercentage(cotton),
        }
      }, { merge: true });
    }
    if (totalPercentage(oilFieldEquipment) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          oilFieldEquipment: totalPercentage(oilFieldEquipment),
        }
      }, { merge: true });
    }
    if (totalPercentage(rawMaterial) !== null) {
      await setDoc(policyRef, {
        standardCommodityPercentages: {
          rawMaterial: totalPercentage(rawMaterial),
        }
      }, { merge: true });
    }

    const totalPercentageData = rows.reduce((acc, cur) => {
      const accPercentage = acc || 0
      const curPercentage = totalPercentage(cur) || 0
      return accPercentage + curPercentage
    }, 0)

    const customCommodityTotal = formData.commodityFields.reduce(
      (total, element) => total + parseInt(element.commodityLoad, 10),
      0
    ) || 0

    const newCommodityTotal = totalPercentageData + customCommodityTotal;

    await setDoc(policyRef, {
      commodityTotal: newCommodityTotal
    }, { merge: true });

    controlToast(true, "Data was succesfully transfered from the xlsx to the portal", "success");
    setLoading(false)
  } catch (error) {
    console.error(error);
    setLoading(false)
  }
};

// TODO: Make this dynamic with a place to edit it in the admin panel
const commodityData = (t) => {
  return [
    {
      label: t("plm.commodities.items.electronics-percentage"),
      property: "electronics",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.alcohol-percentage"),
      property: "alcohol",
      hasDetails: true,
      defaultValue: 38000,
      min: 38000,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.beer-wine-percentage"),
      property: "beerWine",
      hasDetails: true,
      defaultValue: 38000,
      min: 38000,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.frozen-food-percentage"),
      property: "frozenFood",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.eggs-percentage"),
      property: "eggs",
      hasDetails: false,
      defaultValue: 26000,
      min: 0,
      value: 26000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.seafood-percentage"),
      property: "seafood",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.meats-percentage"),
      property: "meatsPoultry",
      hasDetails: true,
      defaultValue: 33000,
      min: 0,
      value: 33000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.produce-percentage"),
      property: "produce",
      hasDetails: true,
      defaultValue: 29000,
      min: 0,
      value: 29000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.motor-vehicles-percentage"),
      property: "motorVehicles",
      hasDetails: false,
      defaultValue: 95000,
      min: 0,
      value: 95000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.containerized-freight-percentage"),
      property: "containerizedFreight",
      hasDetails: true,
      defaultValue: 75000,
      min: 0,
      value: 75000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.mobile-equipment-percentage"),
      property: "mobileEquipment",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.tobacco-percentage"),
      property: "tobaccoCigars",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.clothing-percentage"),
      property: "clothing",
      hasDetails: true,
      defaultValue: 30000,
      min: 0,
      value: 30000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.tires-percentage"),
      property: "tires",
      hasDetails: false,
      defaultValue: 49000,
      min: 0,
      value: 49000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.scrap-percentage"),
      property: "scrap",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.coils-percentage"),
      property: "coils",
      hasDetails: true,
      defaultValue: 41000,
      min: 41000,
      value: 41000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.copper-percentage"),
      property: "copper",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.heavy-machinery-percentage"),
      property: "heavyMachinery",
      hasDetails: true,
      defaultValue: 85000,
      min: 0,
      value: 85000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.non-ferrous-metal-percentage"),
      property: "nonFerrousMetal",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.in-scrap-percentage"),
      property: "inScrapOrIngo",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.in-hook-percentage"),
      property: "inHook",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.mobile-homes-percentage"),
      property: "mobileHomes",
      hasDetails: false,
      defaultValue: 150000,
      min: 0,
      value: 150000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.logs-timber-pulwood-percentage"),
      property: "logsTimberPulpwood",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.documents-mail-percentage"),
      property: "documentsMail",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.pharmaceuticals-percentage"),
      property: "pharmaceuticals",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.precious-stones-percentage"),
      property: "preciousStonesJewelry",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.marijuana-percentage"),
      property: "marijuana",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.live-animals-percentage"),
      property: "liveAnimals",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.any-type-of-art-percentage"),
      property: "art",
      hasDetails: false,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.leather-fur-percentage"),
      property: "leatherFur",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.auto-parts-percentage"),
      property: "autoParts",
      hasDetails: false,
      defaultValue: 34000,
      min: 34000,
      value: 34000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.canned-dry-food-percentage"),
      property: "cannedDryFood",
      hasDetails: false,
      defaultValue: 33000,
      min: 0,
      value: 33000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.furniture-percentage"),
      property: "furniture",
      hasDetails: true,
      defaultValue: 41000,
      min: 0,
      value: 41000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.groceries-supermarket-percentage"),
      property: "groceries",
      hasDetails: true,
      defaultValue: 35000,
      min: 0,
      value: 35000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.electro-domestics-percentage"),
      property: "electroDomestics",
      hasDetails: true,
      defaultValue: 95000,
      min: 0,
      value: 95000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.wood-lumber-percentage"),
      property: "woodLumber",
      hasDetails: true,
      defaultValue: 45000,
      min: 0,
      value: 45000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.pipes-percentage"),
      property: "pipes",
      hasDetails: true,
      defaultValue: 48000,
      min: 0,
      value: 48000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.cotton-percentage"),
      property: "cotton",
      hasDetails: true,
      defaultValue: 30000,
      min: 0,
      value: 30000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.oil-field-equipment-percentage"),
      property: "oilFieldEquipment",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
    {
      label: t("plm.commodities.items.raw-material-percentage"),
      property: "rawMaterial",
      hasDetails: true,
      defaultValue: 40000,
      min: 0,
      value: 40000,
      disabledSlider: false,
    },
  ];
}

export default commodityData;